import loadImgSrc from '@/assets/images/load.gif'
import { Container, Loader } from './FullPageLoader.styles'

export function FullPageLoader() {
  return (
    <Container>
      <Loader src={loadImgSrc} alt="carregando..." />
    </Container>
  )
}
