import ServerErrorIMG from '@/assets/images/server_error.svg'

import { Container, LogoImg } from './ServerError.styles'

export function ServerError(): JSX.Element {
  return (
    <Container>
      <LogoImg src={ServerErrorIMG} alt="error..." />
      <h1>Ocorreu um erro desconhecido no servidor</h1>
    </Container>
  )
}
