import { css, styled } from 'styled-components'

const Variants = {
  primary: css`
    color: #ffff;
    background-color: #01c38d;

    &:hover {
      background-color: #01a779;
    }
  `,
  secondary: css`
    color: #292c3e;
    background-color: #f3f4f8;

    &:hover {
      background-color: #dee2ef90;
    }
  `,
  link: css`
    color: #292c3e;
    background-color: transparent;
    border: 1px solid #dee2ef90;

    &:hover {
      background-color: #eef0f5;
      text-decoration: underline #292c3e;
    }
  `,
}

const IconColors = {
  primary: css`
    & > span {
      color: #01a779;
    }
  `,
  secondary: css`
    & > span {
      color: #292c3e;
    }
  `,
}

const Spaced = css`
  padding: 0 22px;
`

export interface ButtonProps {
  $spaced?: boolean
  $variant?: 'primary' | 'secondary' | 'link'
  $iconColor?: 'primary' | 'secondary'
}

export const ButtonContainer = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  min-width: 44px;
  height: 44px;
  padding: 0 8px;
  border-radius: 10px;
  border: unset;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  color: #292c3e;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;

  &:hover {
    background-color: #eef0f580;
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #292c3e;
  }

  & > span > svg {
    width: 1rem;
    height: 1rem;
  }

  ${({ $variant: variant }) => variant && Variants[variant]}
  ${({ $iconColor: iconColor }) => iconColor && IconColors[iconColor]}
  ${({ $spaced }) => $spaced && Spaced}
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center; // Centralizar com espaço entre os botões
  gap: 10px; // Espaçamento entre os botões
  padding: 10px; // Padding ao redor dos botões
  border-radius: 10px; // Bordas arredondadas
`
