import { UploadOne } from '@icon-park/react'

import { Flex, Tooltip } from '@/components'

import { Tokens } from '@/assets/tokens'
import { UploadStatus } from '@shared/models'
import { Component, ProgressBar } from './Card.styles'

export interface UploadItem {
  id?: string
  originalFileName: string
  percentage: number
  status: UploadStatus
  metadata?: string
}

interface CardProps {
  item: UploadItem
}

export function Card({ item }: CardProps) {
  return (
    <Component>
      <Flex $type="row" $justify="flex-start" $align="center" $gap={12}>
        <UploadOne size={20} />
        <h4>{item.originalFileName}</h4>
      </Flex>
      <Flex $type="column" $justify="space-between" $align="stretch">
        <Flex $type="row" $justify="space-between" $align="center">
          <span>{item.status}</span>
          {item.status === UploadStatus.ERRORED && <Tooltip value={item.metadata ?? ''} length={25} $size={10} />}
          <span
            style={{
              fontSize: '20px',
              fontWeight: 600,
              color: Tokens.ColorTextPrimary,
            }}>
            {item.status !== UploadStatus.ERRORED ? `${item.percentage}%` : '100%'}
          </span>
        </Flex>
        <ProgressBar
          $percentage={item.percentage}
          $color={
            [UploadStatus.UPLOADING, UploadStatus.UPLOADED, UploadStatus.STARTED].includes(item.status) ?
              Tokens.ColorUIWarn
            : item.status === UploadStatus.FINISHED ?
              Tokens.ColorUIPrimary
            : Tokens.ColorUIDanger
          }
        />
      </Flex>
    </Component>
  )
}
