import { memo, useEffect } from 'react'

import { DeleteFive } from '@icon-park/react'

import { BrandResponse } from '@shared/models/brand'

import { Tokens } from '@/assets/tokens'

import { Alert, Api, Confirmation } from '@/modules'

import { Button, Divider, TableImage } from '@/components'
import { ColumnType, Table } from '@/components/derived'

import { useRouting } from '@/hooks'
import { getFromToElements } from '@/utils/pagination'
import { useCompanyContext } from '../CompanyDetails.context'

export const BrandsTable = memo(Component)

function Component() {
  const { currentPage } = useRouting()
  const { isLoadingBrands, brands, reloadBrands } = useCompanyContext()

  const { sendAlert } = Alert.Hook()
  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    text: 'Esta ação não tem como ser desfeita. Todos os valores associados à essa marca serão perdidos',
    confirmText: 'Sim! Apagar marca',
  })

  const { isRunning: isDeleting, run: deleteBrand } = Api.Command<void>(Api.Methods.DELETE, 'brands/:id')

  useEffect(() => {
    reloadBrands(currentPage)
  }, [currentPage])

  function onConfirmationAccepted(id: string): void {
    deleteBrand<void>({
      binds: [['id', id]],
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Exclusão da marca',
          message: 'Marca excluída com sucesso',
        })
        reloadBrands(currentPage)
      },
    })
  }

  const columns: ColumnType<BrandResponse>[] = [
    {
      key: 'logo',
      width: 100,
      render: (_, brand) => <TableImage id={brand.logo} />,
    },
    {
      key: 'name',
      title: 'Nome',
    },
    {
      key: 'options',
      width: 137,
      render: (_, brand) => (
        <Button.Root
          $variant="link"
          style={{ color: Tokens.ColorUIDanger }}
          onClick={() => openConfirmation(() => onConfirmationAccepted(brand.id))}
          $loading={isDeleting}>
          Excluir
        </Button.Root>
      ),
    },
  ]

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Marcas registradas" />
        <Divider.Subtitle
          value={`${getFromToElements(currentPage, brands?.elements.length ?? 0)}/${brands?.total ?? 0} Entrada(s)`}
        />
      </Divider.Root>
      <Table isLoading={isLoadingBrands} columns={columns} data={brands?.elements} totalPages={brands?.pages} />
    </>
  )
}
