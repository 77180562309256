import { ReactNode, createContext, useState } from 'react'

import { generateRandomID } from '@/utils/idGenerator'

import { CompanyResponse, CreateBrandRequest, CreateCompanyRequest, UploadFileRequest } from '@shared/models'

import { Routes } from '@/configs'

import { Api } from '@/modules/api'

import { useRouting } from '@/hooks/useRouting'

export type CreateCompanyType = Omit<CreateCompanyRequest, 'brands'>

export type CreateBrandType = Omit<CreateBrandRequest, 'companyID'> & {
  id: string
}

export type AddBrandInput = Omit<CreateBrandType, 'id'>

interface CreateCompanyContextOutput {
  addBrand: (input: AddBrandInput) => void
  deleteBrand: (brandID: string) => void
  save: (input: CreateCompanyType) => void

  isCreatingCompany: boolean
  brands: CreateBrandType[]
}

interface ContextProps {
  children: ReactNode
}

export const CreateCompanyContext = createContext<CreateCompanyContextOutput>({} as CreateCompanyContextOutput)

export function CreateCompanyContextProvider({ children }: ContextProps) {
  const [brands, setBrands] = useState<CreateBrandType[]>([])
  const { isRunning: isCreatingCompany, run: createCompany } = Api.Command<CompanyResponse>(
    Api.Methods.POST,
    'companies',
  )

  const { navigate } = useRouting()

  function addBrand(addBrandInput: AddBrandInput) {
    setBrands((state) => [
      ...state,
      {
        ...addBrandInput,
        id: generateRandomID(),
      },
    ])
  }

  function deleteBrand(id: string) {
    setBrands((state) => state.filter((brand) => brand.id !== id))
  }

  async function submit(submitInput: CreateCompanyType) {
    createCompany<CreateCompanyRequest>({
      input: {
        name: submitInput.name,
        systemLogo: { ...submitInput.systemLogo } as UploadFileRequest,
        reportLogo: { ...submitInput.reportLogo } as UploadFileRequest,
        keywords: submitInput.keywords,
        hasCompetitors: submitInput.hasCompetitors,
        isGovernmental: submitInput.isGovernmental,
        motherID: submitInput.motherID,
        brands: brands.map(
          (brandInput: CreateBrandType): Omit<CreateBrandRequest, 'companyID'> => ({
            name: brandInput.name,
            systemLogo: { ...brandInput.systemLogo } as UploadFileRequest,
            reportLogo: { ...brandInput.reportLogo } as UploadFileRequest,
          }),
        ),
      },
      onSuccess: (company: CompanyResponse) => {
        navigate(Routes.CompanyDetails, company.id)
      },
    })
  }

  return (
    <CreateCompanyContext.Provider
      value={{
        addBrand,
        deleteBrand,
        save: submit,

        isCreatingCompany,
        brands: brands,
      }}>
      {children}
    </CreateCompanyContext.Provider>
  )
}
