export function fillEndPoint(endPoint: string, parameters: Record<string, string>) {
  let url = endPoint + '?'
  let flag = false
  Object.keys(parameters).forEach((key) => {
    if (key !== 'idEmpresaOLD') {
      const value = parameters[key]
      if (value) {
        flag = true
        url = `${url}${key}=${value}&`
      }
    }
  })

  if (flag) {
    return url
  } else {
    return null
  }
}
