import { Tokens } from '@/assets/tokens'
import styled from 'styled-components'

const borderRadius = '20px'
const heightPadding = '28px'
const sidesPadding = '16px'

export const TableWrapper = styled.div`
  padding: ${heightPadding} ${sidesPadding};
  border-radius: ${borderRadius};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 8px;
  margin-bottom: 2rem;

  background-color: ${Tokens.ColorWhite};
`

export const TableComponent = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
  background-color: ${Tokens.ColorWhite};
`

export const Divider = styled.div`
  width: 97%;
  height: 1px;
  margin-bottom: 16px;

  align-self: center;

  background-color: ${Tokens.ColorBorder};
`

export const TableHeaderCell = styled.th`
  padding-bottom: 24px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: ${Tokens.ColorTextPrimary};

  &:first-child {
    padding-left: ${sidesPadding};
    border-top-left-radius: ${borderRadius};
  }

  &:last-child {
    border-top-right-radius: ${borderRadius};
  }
`

export const TableRowItem = styled.tr`
  height: 72px;

  &:nth-child(odd) {
    background-color: #f7f8fa;
  }
`

export const TableCell = styled.td`
  position: relative;
  font-size: 16px;
  color: ${Tokens.ColorTextPrimary};

  &:first-child {
    padding-left: ${sidesPadding};
    border-radius: 16px 0 0 16px;
    text-align: left;

    & > * {
      height: 40px;
      vertical-align: middle;
    }
  }

  &:last-child {
    border-radius: 0 16px 16px 0;
    text-align: left;
  }
`
