import { Tokens } from '@/assets/tokens'
import styled, { keyframes } from 'styled-components'

const SkeletonAnimation = keyframes`
0% {
  background-position: 200% 0;
}
100% {
  background-position: -200% 0;
}
`

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
`
export const LoaderItem = styled.div`
  width: 100%;
  height: 5rem;
  border-radius: ${Tokens.RadiusInput};

  background: linear-gradient(90deg, ${Tokens.ColorHover} 25%, ${Tokens.ColorBorder} 50%, ${Tokens.ColorHover} 75%);
  background-size: 200% 100%;

  animation: ${SkeletonAnimation} 2s linear infinite;
`
