/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from 'd3'
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { fillEndPoint } from '../utils/fillEndPoint'
import { getFromApi } from '../utils/getFromApi'
import { generateUniqueID } from '../utils/strings'
import { useValues } from '../ValuesContext'
import './styles.css'
import { downloadPNG, isAnyDashLoading } from './utils'

interface PieChartProps {
  subtitle: string
  colors?: string[]
  endPoint: string
}

const PieChart: React.FC<PieChartProps> = ({ subtitle, endPoint, colors }) => {
  const componentID = useMemo(() => generateUniqueID(), [])

  const printableRef = useRef<HTMLDivElement>({} as HTMLDivElement)

  const [isLoading, setIsLoading] = useState(true)
  const { values, updateValues }: any = useValues()
  const [valuesAux, updateValuesAux]: any = useState(useValues())

  const svgWidth = 333
  const svgHeight = 200
  const outerRadius = 80
  const innerRadius = 70

  const exportAsPng = () => {
    downloadPNG(printableRef, subtitle)
  }

  useEffect(() => {
    updateValuesAux(values)
  }, [values])

  useEffect(() => {
    if (Object.keys(values).length === 0 || !values.idEmpresaIN || values.k) {
      return
    }

    setIsLoading(true)

    const getUrl: any = fillEndPoint(endPoint, values)
    d3.select(printableRef.current).select(`.column-chart-container`).remove()

    getFromApi(getUrl)
      .then((elements: any) => {
        if (!printableRef.current) return
        if (elements.length === 0) {
          d3.select(printableRef.current)
            .append('div')
            .attr('class', 'column-chart-container')
            .style('width', '338px')
            .style('text-align', 'center')
            .style('display', 'flex')
            .style('justify-content', 'center')
            .style('align-items', 'center')
            .text('Não há dados para análise no período')
          setIsLoading(false)
          return
        }
        const printableElement = d3.select(printableRef.current)

        const chartContainer = printableElement
          .append('div')
          .attr('class', 'column-chart-container')
          .style('width', '338px')
          .style('position', 'relative')

        const headerContainer = chartContainer
          .insert('div')
          .attr('class', 'chart-header')
          .style('display', 'flex')
          .style('align-items', 'center')
          .style('justify-content', 'space-between')
          .style('margin-bottom', '10px')

        headerContainer.insert('h3').attr('class', 'column-chart-subtitle').text(subtitle)

        headerContainer
          .append('button')
          .attr('class', 'export-button')
          .html(
            `
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#C7C7C7">
            <path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z"/>
          </svg>
        `,
          )
          .on('click', exportAsPng)

        const chart = chartContainer.append('svg').attr('width', svgWidth).attr('height', svgHeight).append('g')

        elements.sort((a: any, b: any) => b.value - a.value)

        const pie = d3
          .pie()
          .sort(null)
          .value((d: any) => d.value)

        const arc: any = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius).cornerRadius(10)

        function formatNumberWithDot(number: number) {
          return d3.format(',')(number).replace(/,/g, '.')
        }

        const onOver = function (event: MouseEvent, d: any) {
          chart.selectAll('path').style('opacity', 0.2)
          d3.select(event.currentTarget).style('opacity', 1)

          type elementType = { __data__: { [key: string]: any } }

          const eventTarget = event.target as unknown as elementType

          const $elements = document.querySelectorAll(`#${componentID} .column-chart-title-container`)

          const $currentElement: any = document
            .querySelector(`#${componentID}`)
            ?.querySelectorAll(`div[data-value="${eventTarget.__data__.data.category}"`)[0]

          $elements.forEach(($element) => {
            $element.classList.add('hidden')
          })
          $currentElement.classList.remove('hidden')

          totalText
            .text(`${formatNumberWithDot(d.data.quantity)}`) // ou use d.data.value para o valor percentual
            .attr('class', 'totalPiePlotNumber')
            .attr('transform', `translate(${svgWidth / 2}, ${svgHeight / 1.8})`)
        }

        const onLeave = function () {
          chart.selectAll('path').style('opacity', 1)
          const $elements = document.querySelectorAll('.column-chart-title-container')
          $elements.forEach(($element) => {
            $element.classList.remove('hidden')
          })
          totalText.text(`${formatNumberWithDot(totalGeneral)}`)
        }

        const onClick = function (_event: MouseEvent, input: any) {
          if (isAnyDashLoading()) {
            return
          }
          if (endPoint.includes('Channel')) {
            if (input.data.category != 'Outros') {
              if (values.idCanalIN) {
                updateValues({ idCanalIN: '' })
              } else {
                updateValues({ idCanalIN: input.data.idcategory })
              }
            }
          } else if (endPoint.includes('Polarity')) {
            if (values.idPolaridadeIN) {
              updateValues({ idPolaridadeIN: '' })
            } else {
              updateValues({ idPolaridadeIN: input.data.idcategory })
            }
          } else if (endPoint.includes('Publisher')) {
            if (input.data.category != 'Outros') {
              if (values.idPublicadorIN) {
                updateValues({ idPublicadorIN: '' })
              } else {
                updateValues({ idPublicadorIN: input.data.idcategory })
              }
            }
          } else if (endPoint.includes('Role')) {
            if (values.idPapelIN) {
              updateValues({ idPapelIN: '' })
            } else {
              updateValues({ idPapelIN: input.data.idcategory })
            }
          } else if (endPoint.includes('DataSource')) {
            if (values.idDataSourceIN) {
              updateValues({ idDataSourceIN: '' })
            } else {
              updateValues({ idDataSourceIN: input.data.idcategory })
            }
          }
        }

        const xArrayValues: string[] = elements.map((item: any) => item.category)

        let colorMap: Record<string, string> = {}
        if (colors?.length) {
          xArrayValues.forEach((value, index) => {
            colorMap[value] = colors[index]
          })
        } else {
          colorMap = {
            Negativo: '#FF4D64',
            Desfavoravel: '#FFC765',
            Neutro: '#D3D3D3',
            Favoravel: '#61EAC4',
            Positivo: '#01C38D',
          }
        }
        chart
          .selectAll('path')
          .data(pie(elements))
          .enter()
          .append('path')
          .attr('d', arc)
          .attr('fill', (d: any) => colorMap[d.data.category]) // Map tema to color
          .attr('transform', `translate(${svgWidth / 2}, ${svgHeight / 2})`)
          .on('mouseover', onOver)
          .on('mouseleave', onLeave)
          .on('click', onClick)
          .transition() // Inicia a transição
          .duration(1000) // Duração da animação em milissegundos
          .attrTween('d', function (d) {
            const interpolate: any = d3.interpolate({ startAngle: 0, endAngle: 0 }, d)
            return function (t) {
              return arc(interpolate(t))
            }
          })
          .attr('class', 'pie')

        const totalGeneral = elements.reduce((acc: any, d: any) => acc + d.quantity, 0)

        const totalText = chart
          .append('text')
          .attr('transform', `translate(${svgWidth / 2}, ${svgHeight / 1.8})`)
          .attr('class', 'totalPiePlotNumber')
          .text(`${formatNumberWithDot(totalGeneral)}`)

        const titles = chartContainer
          .insert('div')
          .attr('class', 'column-chart-titles')
          .style('flex-direction', 'column')

        const categories: any = elements.map((item: any) => item.category)

        categories.forEach((xValue: any) => {
          const value: any = String(xValue)

          const titleContainer = titles
            .insert('div')
            .attr('class', 'column-chart-title-container')
            .attr('data-value', value)

          const iconColor = colorMap[value]

          titleContainer.insert('div').attr('class', 'column-chart-title-icon').style('background-color', iconColor)

          titleContainer.append('span').attr('class', 'column-chart-title').text(value)

          titleContainer
            .append('span')
            .text(elements.find((item: any) => item.category === value).value + '%')
            .attr('class', 'valuePiePercentage')

          setIsLoading(false)
        })
      })
      .catch((error) => {
        console.log('Error fetching data in pirChart:', error)
      })
  }, [valuesAux])
  return (
    <div id={componentID} ref={printableRef}>
      {isLoading ?
        <div className="column-chart-container-spinner" style={{ width: '338px', height: '424px' }}></div>
      : <div className="chart-container"></div>}
    </div>
  )
}

export default PieChart
