import { Tokens } from '@/assets/tokens'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
from {
  transform: translateY(100vh);
}
to {
  transform: translateY(0);
}
`

export const ContentContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;

  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;

  background-color: ${Tokens.ColorHover};
  animation: ${fadeIn} 0.25s ease-in-out forwards;
`

export const Content = styled.section`
  width: 730px;
  height: fit-content;
  padding: 4rem 0;

  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 40px;
`

export const ParentTitle = styled.h6`
  font-weight: 400;
  color: ${Tokens.ColorTextPrimary};
  padding-bottom: 3px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-decoration-color: inherit;
  }
`
