import { toPng } from 'html-to-image'

export const isAnyDashLoading = () => {
  const spinnerExists = document.querySelector('.column-chart-container-spinner') !== null
  const skeletonExists = document.querySelector('.SkeletonLoader') !== null

  if (!spinnerExists && !skeletonExists) {
    return false
  } else {
    return true
  }
}

export const downloadPNG = (printableRef: any, subtitle: any, CENA = false) => {
  if (printableRef.current) {
    const exportButton = printableRef.current.querySelector('.export-button')
    const closeButtons = Array.from(printableRef.current.querySelectorAll('.close-button'))

    if (exportButton) {
      exportButton.style.display = 'none'

      const pointTexts = Array.from(printableRef.current.querySelectorAll('.point-text'))

      const originalStyles = pointTexts.map((el: any) => el.style.display)

      if (CENA) {
        pointTexts.forEach((el: any) => (el.style.display = 'block'))
      }

      closeButtons.forEach((button: any) => (button.style.display = 'none'))

      toPng(printableRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = `${subtitle.replace(/\s+/g, '-').toLowerCase()}.png`
          link.href = dataUrl
          link.click()
        })
        .catch((error) => {
          console.error('Error exporting chart as PNG:', error)
        })
        .finally(() => {
          if (exportButton) {
            exportButton.style.display = 'block'
          }

          pointTexts.forEach((el: any, index) => {
            el.style.display = originalStyles[index]
          })
        })
    }
  }
}
