export enum ReportType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

export enum ReportMonitoringType {
  PRESS = 'PRESS',
  NETWORK = 'NETWORK',
}

export enum ReportStatus {
  STARTED = 'STARTED',
  UPLOADED = 'UPLOADED',
  FINISHED = 'FINISHED',
  ERRORED = 'ERRORED',
}

export enum ReportStatusMessage {
  UPLOADED = 'Processamento iniciado.',
  STARTED = 'Início do relatório (IA).',
  FINISHED = 'Relatório disponível.',
  ERRORED = 'Erro. Contate suporte.',
}

export type ReportResponse = {
  id: string
  status: ReportStatus
}

export type ReportDownloadResponse = {
  id: string
  status: ReportStatus
  url: string
}

export type CreateReport = {
  name: string
  type: ReportType
  monitoringType: ReportMonitoringType
  startDate: string
}

export type Report = {
  id: string
  companyID: string
  name: string
  type: ReportType
  monitoringType: ReportMonitoringType
  status: ReportStatus
  startDate: string
  fileLink?: string
  fileName?: string
}
