import { ApiFuncParam } from '@/modules'

export function buildUrlRequest(url: string, urlParams?: ApiFuncParam | string): string {
  if (!url || !urlParams) return url

  const urlRequest = [url]
  const builtUrlParams = buildUrlParams(urlParams)

  if (builtUrlParams) {
    if (!url.endsWith('/')) {
      urlRequest.push('/')
    }

    urlRequest.push(builtUrlParams)
  }

  return urlRequest.join('')
}

function buildUrlParams(urlParams?: ApiFuncParam | string): string | undefined {
  if (!urlParams) return undefined

  if (typeof urlParams === 'string') return urlParams

  if (typeof urlParams === 'object')
    return Object.entries(urlParams)
      ?.map(([key, value]) => `${key}/${value ?? ''}`)
      ?.join('/')

  if (!urlParams) return undefined
}
