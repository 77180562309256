import { ComponentType } from 'react'

import { UserResponseRole } from '@shared/models'

import {
  CompanyDetails,
  CreateCompany,
  CreateStaff,
  Dashboard,
  Home,
  Login as LoginPage,
  Mentions,
  NotFoundPage,
  Reports,
  ReportSettings,
  ServerError,
  Staff,
  Uploads,
  Welcome,
} from '@/pages'

export interface Route {
  component: ComponentType
  path: string

  key: string
  acceptVariable?: boolean
  isPrivate: boolean
  requiredRoles?: UserResponseRole[]
  showMenu?: boolean
}

export type RouteParams = {
  companyID: string
  channelID: string
}

export const Routes = {
  Welcome: {
    isPrivate: false,
    key: 'welcome',
    path: '/boas-vindas',
    component: Welcome,
  } as Route,
  Login: {
    isPrivate: false,
    key: 'login',
    path: '/login',
    component: LoginPage,
  } as Route,
  ServerError: {
    isPrivate: false,
    key: 'server-error',
    path: '/erro-servidor',
    component: ServerError,
  } as Route,
  NotFound: {
    isPrivate: false,
    key: 'not-found',
    path: '*',
    component: NotFoundPage,
  } as Route,

  Home: {
    isPrivate: true,
    key: 'home',
    path: '/',
    component: Home,
  } as Route,

  Mentions: {
    isPrivate: true,
    key: 'mentions',
    path: '/empresas/:companyID/mencoes',
    component: Mentions,
    showMenu: true,
  } as Route,
  Dashboard: {
    isPrivate: true,
    key: 'dashboards',
    path: '/empresas/:companyID/dashboards',
    component: Dashboard,
    showMenu: true,
  } as Route,
  Reports: {
    isPrivate: true,
    key: 'reports',
    path: '/empresas/:companyID/relatorios',
    component: Reports,
    showMenu: true,
  } as Route,
  Uploads: {
    isPrivate: true,
    key: 'company-details',
    path: '/empresas/:companyID/uploads',
    component: Uploads,
    showMenu: true,
  } as Route,
  Companies: {
    isPrivate: true,
    key: 'companies',
    path: '/empresas',
    component: CreateCompany,
  } as Route,
  CompanyDetails: {
    isPrivate: true,
    key: 'company-details',
    path: '/empresas/:companyID',
    component: CompanyDetails,
    acceptVariable: true,
    showMenu: true,
  } as Route,
  ReportSettings: {
    isPrivate: true,
    key: 'customize-report',
    path: '/empresas/:companyID/personalizar-relatorio',
    component: ReportSettings,
    acceptVariable: true,
    showMenu: true,
  } as Route,
  Assessors: {
    isPrivate: true,
    showMenu: true,
    key: 'staff',
    path: '/assessores',
    component: Staff,
    requiredRoles: [UserResponseRole.Admin, UserResponseRole.Leadership],
  } as Route,
  CreateStaff: {
    isPrivate: true,
    key: 'create-staff',
    path: '/assessores/cadastro',
    component: CreateStaff,
    requiredRoles: [UserResponseRole.Admin, UserResponseRole.Leadership],
  } as Route,
}
