import Accordion from './Accordion/Accordion.component'
import { DashboardLine } from './Dashboards.styles'
import BarChart from './dashes/BarChart.component'
import BarSideBySideChart from './dashes/BarSideBySideChart.component'
import PieChart from './dashes/PieChart.component'
import RelevanceDisplay from './dashes/Relevance.component'
import { useValues } from './ValuesContext'

export function CreateDashboard() {
  const apiHost = process.env.REACT_APP_DASH_API_HOST
  const { values, updateValues }: any = useValues()
  if (values.k) {
    updateValues({ k: '' })
  }
  return (
    <>
      <DashboardLine>
        <PieChart subtitle="Polaridade no Período" endPoint={apiHost + 'piePolarity'} />
        <PieChart
          colors={['#3DADE2', '#2C6AFF', '#FB9638', '#F5785D', '#d3d3d3']}
          subtitle="Canal no Período "
          endPoint={apiHost + 'pieChannel'}
        />
        <PieChart
          colors={['#01C38D', '#FFC765', '#B7B9EE', '#D9D9D9', '#7F7F7F']}
          subtitle="Publicador no Período"
          endPoint={apiHost + 'piePublisher'}
        />
      </DashboardLine>

      <DashboardLine>
        <PieChart
          colors={['#F45B5B', '#EEAAEE', '#AAEEEE', '#7798BF', '#90EE7E']}
          subtitle="Abrangência no Período"
          endPoint={apiHost + 'pieRole'}
        />

        <PieChart
          colors={['#00FFBB', '#08C0C5', '#36A2A5', '#05B878', '#FFB775']}
          subtitle="Fonte de Dados no Período"
          endPoint={apiHost + 'pieDataSource'}
        />

        <RelevanceDisplay endPoint={apiHost + ''} />
      </DashboardLine>

      <DashboardLine>
        <BarSideBySideChart
          subtitle="Polaridade"
          xValue="mes"
          yValue="mencoes"
          target="polaridade"
          targetKey="idpolaridade"
          cena={true}
          endPoint={apiHost + 'sideBySidePolarity'}
        />
      </DashboardLine>

      <DashboardLine>
        <BarSideBySideChart
          subtitle="Canal"
          xValue="mes"
          yValue="quantidade"
          target="canal"
          targetKey="idcanal"
          colors={['#3DADE2', '#2C6AFF', '#FB9638', '#F5785D', '#d3d3d3']}
          cena={false}
          endPoint={apiHost + 'sideBySideChannel'}
        />
      </DashboardLine>

      <DashboardLine>
        <BarSideBySideChart
          subtitle="Publicador"
          xValue="mes"
          yValue="quantidade"
          target="publicador"
          colors={['#01C38D', '#FFC765', '#B7B9EE', '#D9D9D9', '#7F7F7F']}
          targetKey="idpublicador"
          cena={false}
          endPoint={apiHost + 'sideBySidePublisher'}
        />
      </DashboardLine>

      <DashboardLine>
        <BarSideBySideChart
          subtitle="Abrangência" //papel
          xValue="mes"
          yValue="quantidade"
          target="papel"
          colors={['#F45B5B', '#EEAAEE', '#AAEEEE', '#7798BF', '#90EE7E']}
          targetKey="idpapel"
          cena={false}
          endPoint={apiHost + 'sideBySideRole'}
        />
      </DashboardLine>

      <Accordion
        items={[
          {
            title: 'Mencões por Marca',
            content: (
              <>
                <DashboardLine style={{ backgroundColor: 'white' }}>
                  <BarChart
                    title="Menções"
                    subtitle="Marca no Período"
                    xValue="marca"
                    yValue="mencoes"
                    endPoint={apiHost + 'barSubBrand'}
                  />
                </DashboardLine>
              </>
            ),
          },
        ]}
      />

      <Accordion
        items={[
          {
            title: 'Mencões por Porta-Voz',
            content: (
              <>
                <DashboardLine style={{ backgroundColor: 'white' }}>
                  <BarChart
                    title="Menções"
                    subtitle="Porta-Voz no Período"
                    xValue="predicado"
                    yValue="mencoes"
                    endPoint={apiHost + 'barConstraint'}
                  />
                </DashboardLine>
              </>
            ),
          },
        ]}
      />

      {/* <Accordion
        items={[
          {
            title: 'Menções por Grupo Temático',
            content: (
              <>
                <DashboardLine style={{ backgroundColor: 'white' }}>
                  <BarChart
                    title="Menções"
                    subtitle="Grupo Temático no Período"
                    xValue="grupotema"
                    yValue="mencoes"
                    endPoint={apiHost + 'barGroupTheme'}
                  />
                </DashboardLine>
              </>
            ),
          },
        ]}
      /> */}

      <Accordion
        items={[
          {
            title: 'Mencões por Tema',
            content: (
              <>
                <DashboardLine style={{ backgroundColor: 'white' }}>
                  <BarChart
                    title="Menções"
                    subtitle="Tema no Período"
                    xValue="tema"
                    yValue="mencoes"
                    endPoint={apiHost + 'barTheme'}
                  />
                </DashboardLine>
              </>
            ),
          },
        ]}
      />

      <Accordion
        items={[
          {
            title: 'Menções por Concorrentes',
            content: (
              <>
                <DashboardLine style={{ backgroundColor: 'white' }}>
                  <BarChart
                    title="Menções"
                    subtitle="Concorrentes no Período"
                    xValue="nomeempresa"
                    yValue="mencoes"
                    endPoint={apiHost + 'barCompetitors'}
                  />
                </DashboardLine>
              </>
            ),
          },
        ]}
      />
    </>
  )
}
