/* eslint-disable react-refresh/only-export-components */
import { CSSProperties, ElementType } from 'react'

import loadImg from '@/assets/images/load.gif'

import {
  ButtonChildProps,
  ButtonIconContainer,
  ButtonLoadingIcon,
  RootContainer,
  RootContainerProps,
  ButtonSpan,
} from './Button.styles'

export const Button = {
  Root: RootComponent,
  Icon: ButtonIcon,
  Text: ButtonText,
}

function RootComponent({ $loading: loading = false, children, ...rest }: RootContainerProps) {
  return (
    <RootContainer $loading={loading} {...rest}>
      {!loading ? children : <ButtonLoadingIcon src={loadImg} alt="Carregando" />}
    </RootContainer>
  )
}

interface ButtonIconProps extends ButtonChildProps {
  value: ElementType
  style?: CSSProperties
}

function ButtonIcon({ value: Icon, style, ...rest }: ButtonIconProps) {
  return (
    <ButtonIconContainer {...rest}>
      <Icon size={16} style={style} />
    </ButtonIconContainer>
  )
}

interface ButtonTextProps {
  value: string
}

function ButtonText({ value }: ButtonTextProps) {
  return <ButtonSpan>{value}</ButtonSpan>
}
