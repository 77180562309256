import { Api } from '@/modules'

import LoadingImg from '@/assets/images/load.gif'

import { Img } from './TableImage.styles'
import { Flex } from '..'

interface TableImageProps {
  id?: string
  hyperlink?: string
}

export function TableImage({ id, hyperlink }: TableImageProps) {
  if (!id) return null
  if (hyperlink) {
    return (
      <a href={hyperlink} target="_blank" rel="noreferrer">
        <Image id={id} />
      </a>
    )
  }
  return <Image id={id} />
}

function Image({ id }: { id: string }) {
  const [, , logo] = Api.Query<string>(`files/${id}`)
  return <Img alt="logo" src={logo ?? LoadingImg} />
}

interface CanalProps {
  hyperlink: string
  channelImage?: string
  brandImage?: string
}

export function Canal({ hyperlink, channelImage, brandImage }: CanalProps) {
  return (
    <Flex $type="column" $justify="center" $align="flex-start" $gap={10}>
      <a href={hyperlink} target="_blank" rel="noreferrer">
        <Img src={channelImage} />
      </a>

      <Img src={brandImage} />
    </Flex>
  )
}
