/* eslint-disable react-refresh/only-export-components */
import { createRoot } from 'react-dom/client'
import { HashRouter as Router } from 'react-router-dom'

import { Alert, Auth, Confirmation } from '@/modules'
import { ObserverContextProvider } from '@/modules/observer'

import App from '@/App'

import { GlobalStyle } from './assets/global-style'
import { MenuContextProvider } from './components/layout/menu.context'
import { Envs } from './configs/env'
import { BuildProvidersTree } from './utils/provider-tree'

Envs.forEach((expectedEnv: string): void => {
  if (!process.env[expectedEnv]) throw new Error(`Variável ${expectedEnv} é obrigatória`)
})

const ProvidersTree = BuildProvidersTree([
  /* [StrictMode, {}], */
  [Router, {}],
  [ObserverContextProvider, {}],
  [MenuContextProvider, {}],
  [Confirmation.Provider, {}],
  [Alert.Provider, {}],
  [Auth.Provider, {}],
])

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <ProvidersTree>
    <GlobalStyle />
    <App />
    <Alert.Component />
    <Confirmation.Component />
  </ProvidersTree>,
)
