import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { useCallback, useState } from 'react'

import { Alert, ApiFuncParam, ApiRunPromiseFunc, ApiPromiseFuncOutput } from '@/modules'

import { Routes } from '@/configs'

import { useRouting } from '@/hooks'

import { useBaseAPI } from './baseApi.hook'
import { buildUrlRequest } from '@/utils'

export type PromiseQueryOutput<T> = [ApiRunPromiseFunc<T>, boolean, () => void]

export function usePromiseQuery<T>(url: string): PromiseQueryOutput<T> {
  const { instance, canceler } = useBaseAPI()
  const { navigate } = useRouting()
  const [isPending, setPending] = useState<boolean>(false)
  const { sendAlert } = Alert.Hook()

  const run = useCallback(
    async (urlParams?: ApiFuncParam | string, queryParams?: ApiFuncParam): Promise<ApiPromiseFuncOutput<T>> => {
      const config: AxiosRequestConfig = {}

      if (queryParams) {
        config.params = queryParams
      }

      const urlRequest = buildUrlRequest(url, urlParams)

      setPending(true)
      try {
        const response: AxiosResponse<T> = await instance.get(urlRequest, config)
        setPending(false)
        return [response?.data, undefined, canceler.abort]
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const response = error.response!

          if (response.status === 400) {
            sendAlert({
              type: 'error',
              title: 'Erro de requisição',
              message: 'A requisição enviada é inválida, verifique-a e tente novamente!',
            })
          }

          if (response.status === 401) {
            sendAlert({
              type: 'error',
              title: 'Erro de autenticação',
              message: 'Vocês não está autenticado, efetue o login e tente novamente!',
            })
            navigate(Routes.Login.path)
          }

          if (response.status === 403) {
            sendAlert({
              type: 'error',
              title: 'Erro de autorização',
              message: 'Você não possui permissões para acessar esse recurso!',
            })
            navigate(Routes.Welcome.path)
          }

          if (response.status === 404) {
            sendAlert({
              type: 'error',
              title: 'Recurso não encontrado',
              message: 'Ocorreu um erro ao buscar o recurso solicitado, verifique a requisição e tente novamente',
            })
            navigate(Routes.Home.path)
          }

          sendAlert({
            type: 'error',
            title: 'Erro inesperado',
            message:
              'Ocorreu um erro inesperado ao efetuar a requisição, tente novamente mais tarde ou contate o suporte!',
          })
        }

        setPending(false)
        return [undefined, error, canceler.abort]
      }
    },
    [],
  )

  return [run, isPending, canceler.abort]
}
