import { Tokens } from '@/assets/tokens'
import styled from 'styled-components'

export const ContentFilterContainer = styled.div`
  border-radius: ${Tokens.RadiusInput};
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-direction: column;

  h2 {
    margin: 0;
    font-size: 1em;
    font-weight: normal;
  }
`

export const ContentFilterHeader = styled.div`
  width: 99.5%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`

export const ContentFilterResetButton = styled.button`
  color: #c7c7c7;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  transition: color 0.3s ease;

  &:not(:disabled):hover {
    color: #7a7a7a;
  }

  &:not(:disabled):active {
    color: #505050;
  }

  &:disabled {
    opacity: 0.9;
  }
`

export const ContentFilterItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`
