import { styled } from 'styled-components'

import bg from '@/assets/images/home-bg.png'

import { Tokens } from '@/assets/tokens'

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const Container = styled.div`
  width: 1240px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`

export const Header = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.h1`
  width: 885px;
  height: 121px;
  font-style: normal;
  font-weight: 900;
  font-size: 110px;
  line-height: 110%;
  font-weight: 700;
  text-align: center;
  background: conic-gradient(
    from 93.01deg at 50.54% 47.4%,
    #172d3e 0deg,
    #173848 75.5deg,
    #24eac4 164.3deg,
    #11d0da 209.7deg,
    #21ecca 272.4deg,
    #2ae4bc 308.23deg,
    #182e3e 360deg
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  flex: none;
  order: 0;
  flex-grow: 0;
`

export const Subtitle = styled.h2`
  text-align: center;
  font-size: 30px;
  line-height: 140%;
  font-weight: 700;
  font-style: normal;
  color: #173343;
  text-align: center;
  width: 750px;
  height: 42px;
  flex: none;
  order: 0;

  &:nth-last-child(1) {
    font-weight: 400;
  }
`

export const Footer = styled.footer`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 28px;
  color: ${Tokens.ColorWhite};
  row-gap: 8px;
`

export const FooterText = styled.span`
  color: ${Tokens.ColorWhite};
`

export const FooterLink = styled.a`
  color: ${Tokens.ColorWhite};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
