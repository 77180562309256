import { memo } from 'react'

import { Star } from '@icon-park/react'

import { CreateBrandRequest } from '@shared/models'

import { Alert, Api } from '@/modules'

import { useFileUploader, useInput, useRouting } from '@/hooks'

import { Button, Flex, Form, Input, Uploader } from '@/components'
import { ManualCard, Modal, UploaderViewer } from '@/components/derived'

import { useCompanyContext } from '../CompanyDetails.context'
import { LogoContainer } from '../CompanyDetails.styles'
import { getDescription } from '../utils'
import { BrandsTable } from './Brands.table'

export const Brands = memo(Component)

function Component(): JSX.Element | null {
  const { companyID, changeQueryParam, currentPage } = useRouting()
  const { company, reloadCompany, reloadBrands } = useCompanyContext()

  const { sendAlert } = Alert.Hook()

  const { value: name, binder: nameBinder, reset: resetName } = useInput()
  const { value: systemLogo, bind: systemLogoBinder, reset: resetSystemLogo } = useFileUploader()
  const { value: reportLogo, bind: reportLogoBinder, reset: resetReportLogo } = useFileUploader()

  const { isRunning: isCreatingBrand, run: createBrand } = Api.Command<void>(Api.Methods.POST, `/brands`)

  function onSubmit(): void {
    const input = {
      companyID,
      name,
      systemLogo,
      reportLogo,
    } as CreateBrandRequest
    createBrand<CreateBrandRequest>({ input, onSuccess })
  }

  function onSuccess() {
    sendAlert({
      type: 'success',
      title: 'Sucesso',
      message: 'Marca cadastrada com sucesso!',
    })
    resetName()
    resetSystemLogo()
    resetReportLogo()
    reloadBrands(currentPage)
  }

  if (!companyID || !company) return null

  return (
    <>
      <ManualCard
        icon={Star}
        healthCheck={company.healthCheck?.isBrandsChecked}
        title="Marcas da Empresa"
        description={getDescription(company.counts?.brands)}
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'marcas',
            replaceAll: true,
          })
        }
      />
      <Modal
        controlKey="marcas"
        backToName={company.name}
        title="Marcas da Empresa"
        subtitle={
          <span>
            Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro da marca.
          </span>
        }
        icon={Star}
        beforeClose={reloadCompany}>
        <Form onSubmit={onSubmit} controlParam={isCreatingBrand}>
          <Flex $type="column" $align="stretch" $gap={24} $fit>
            <Input label="Nome da Marca" placeholder="Marca" {...nameBinder} required />
            <Flex $type="row" $justify="center" $align="center" $gap={24} $fit>
              <LogoContainer>
                <Uploader
                  label="Logo em cores (.svg 417px/128px)"
                  {...systemLogoBinder}
                  requiredExtensions={['svg']}
                  required
                />
                <UploaderViewer value={systemLogo} />
              </LogoContainer>
              <LogoContainer>
                <Uploader
                  label="Logo em cinza (.svg 417px/128px)"
                  {...reportLogoBinder}
                  requiredExtensions={['svg']}
                  required
                />
                <UploaderViewer value={reportLogo} />
              </LogoContainer>
            </Flex>
            <Flex $type="row" $justify="flex-end" $align="stretch" $gap={20} $fit>
              <Button.Root type="submit" $variant="primary" $loading={isCreatingBrand}>
                <Button.Text value="Adicionar" />
              </Button.Root>
            </Flex>
          </Flex>
        </Form>
        <BrandsTable />
      </Modal>
    </>
  )
}
