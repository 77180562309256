import { Routes as AppRoutes, Route } from 'react-router-dom'

import { Route as CfgRoute, Routes } from '@/configs'

import { AuthGuard } from './modules/auth/auth.guard'

export default function App(): JSX.Element {
  const routes = Object.values(Routes)

  return (
    <AppRoutes>
      {routes
        .filter((route: CfgRoute): boolean => !route.isPrivate)
        .map((route: CfgRoute) => (
          <Route key={route.key} path={route.path} element={<route.component />} />
        ))}
      {routes
        .filter((route: CfgRoute): boolean => route.isPrivate)
        .map((route: CfgRoute) => (
          <Route
            key={route.key}
            path={route.path}
            element={
              <AuthGuard>
                <route.component />
              </AuthGuard>
            }
          />
        ))}
    </AppRoutes>
  )
}
