import { createPortal } from 'react-dom'

import { Check, Close } from '@icon-park/react'

import { Tokens } from '@/assets/tokens'

import { Button, Flex } from '@/components'

import { useAlert } from './alert.context'
import { ComponentContainer, IconContainer, MainContainer } from './alert.styles'

const $domElement = document.getElementById('alert') as HTMLDivElement

export function Component(): React.ReactPortal | null {
  const { alerts, closeAlert: close } = useAlert()

  if (!alerts?.length) return null

  return createPortal(
    <MainContainer>
      {alerts.map(([key, alert]) => (
        <ComponentContainer key={key}>
          <IconContainer type={alert.type}>
            {alert.type === 'success' ?
              <Check size={26} style={{ color: Tokens.ColorUIPrimary }} />
            : <Close size={26} style={{ color: Tokens.ColorUIDanger }} />}
          </IconContainer>
          <Flex $type="column" $justify="center" $align="flex-start" $gap={5} $fit>
            <h4>{alert.title}</h4>
            <span>{alert.message}</span>
          </Flex>
          <Button.Root onClick={() => close(key)} $variant="link">
            <Button.Icon value={Close} />
          </Button.Root>
        </ComponentContainer>
      ))}
    </MainContainer>,
    $domElement,
  )
}
