import { ElementType } from 'react'

type ComponentWithProps = [ElementType, Record<string, unknown>]

interface Component {
  children: Array<JSX.Element>
}

export function BuildProvidersTree(componentsWithProps: Array<ComponentWithProps>) {
  const initialComponent = ({ children }: Component) => <>{children}</>

  return componentsWithProps.reduce(
    (AccumulatedComponents: ElementType, [Provider, props = {}]: ComponentWithProps) => {
      /* eslint-disable react/display-name */
      return ({ children }: Component) => {
        return (
          <AccumulatedComponents>
            <Provider {...props}>{children}</Provider>
          </AccumulatedComponents>
        )
      }
    },
    initialComponent,
  )
}
