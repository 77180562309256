import { Flex } from '@/components'
import { FormEvent, ReactNode } from 'react'
import { Component } from './Form.styles'

interface FormProps {
  controlParam?: boolean
  title?: string
  onSubmit: () => void
  children: ReactNode
}

export function Form({ controlParam = false, title, onSubmit, children }: FormProps) {
  function handleSubmit(event: FormEvent): void {
    event.preventDefault()
    if (!controlParam) {
      onSubmit()
    }
  }

  return (
    <Component onSubmit={handleSubmit}>
      {title && <h1>{title}</h1>}
      <Flex $type="column" $justify="flex-start" $align="stretch" $gap={24}>
        {children}
      </Flex>
    </Component>
  )
}
