import { Tokens } from '@/assets/tokens'
import { Input } from '@/components'
import styled, { keyframes } from 'styled-components'

const expand = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: 1036px; /* Valor suficientemente grande para acomodar o conteúdo */
  }
`

const collapse = keyframes`
  from {
    max-height: 1036px; /* Valor suficientemente grande para acomodar o conteúdo */
  }
  to {
    max-height: 0;
  }
`

export const AccordionContainer = styled.div`
  background-color: white;
  align-items: center;
  /* border: 0.1rem solid #00000050; */
  border-radius: ${Tokens.RadiusInput};
  width: 1036px;
`

export const AccordionItemContainer = styled.div`
  border-bottom: 1px solid #ddd;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  width: 1036px;
`

export const AccordionHeader = styled.div`
  /* border-radius: 5px 5px 5px 5px; */
  border-radius: ${Tokens.RadiusInput};
  width: 1034px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;

  h2 {
    margin: 0;
    font-size: 1em;
    font-weight: normal;
  }

  /* &:hover {
    background-color: #f0f0f0;
  } */
`

export const AccordionIcon = styled.span`
  font-size: 1.5em;
`

export const AccordionContent = styled.div<{ $isOpen: boolean }>`
  /* border-top: 1px solid #ddd; */
  border-radius: ${Tokens.RadiusInput};
  /* border-radius: 0px 0px 5px 5px; */
  overflow: hidden;
  display: block;
  width: 1034px;
  max-height: ${({ $isOpen: isOpen }) => (isOpen ? '1034px' : '0')};
  animation: ${({ $isOpen: isOpen }) => (isOpen ? expand : collapse)} 0.3s ease-in-out;
  // padding: ${({ $isOpen: isOpen }) => (isOpen ? '15px' : '0')}; /* Adiciona padding quando está aberto */
`

export const CustomInputWrapper = styled.div`
  width: 100%;
  border-radius: ${Tokens.RadiusInput};
  border: 1px solid ${Tokens.ColorLight};
  background-color: ${Tokens.ColorWhite};

  &:hover {
    background-color: ${Tokens.ColorHover};
    outline: none;
  }
`

export const CustomInput = styled(Input).attrs({
  innerBackground: Tokens.ColorWhite,
})`
  color: ${Tokens.ColorTextPrimary};

  &::placeholder {
    color: ${Tokens.ColorTextPrimary};
  }
`
