import { DocDetail } from '@icon-park/react'

import { CreateReport, Report, ReportMonitoringType, ReportType } from '@shared/models'

import { Alert, Api } from '@/modules'
import { CommandMethods } from '@/modules/api/useCommand'

import { useInput, useRouting } from '@/hooks'

import { Routes } from '@/configs'

import { exportDate } from '@/utils/date'

import { Button, Flex, Form, Input, Selector } from '@/components'
import { Modal } from '@/components/derived'
import { Str } from '@/utils/strings'
import { useEffect } from 'react'
import { useReportsContext } from '../Reports.context'

export const NewReportModalName = 'novo-relatorio'

export function NewReportFormModal(): JSX.Element {
  const { companyID, navigate } = useRouting()
  const { sendAlert } = Alert.Hook()
  const { binder: nameBind, value: name, reset: resetName } = useInput()
  const { binder: reportTypeBind, selectedOption: reportType, reset: resetReportType } = Selector.Binder()
  const {
    binder: reportMonitoringTypeBind,
    selectedOption: reportMonitoringType,
    reset: resetReportMonitoringType,
  } = Selector.Binder()
  const { binder: startDateBind, value: startDate, reset: resetStartDate } = useInput(exportDate(new Date()))

  const { types, monitoringTypes, updateReportResponse } = useReportsContext()
  const {
    isRunning: isCreatingReport,
    run: requestReportCreation,
    data: reportResponse,
  } = Api.Command<Report>(CommandMethods.POST, '/companies/:companyID/reports')
  function onSubmit(): void {
    const createReportRequest: CreateReport = {
      name,
      type: reportType!.value as ReportType,
      monitoringType: reportMonitoringType!.value as ReportMonitoringType,
      startDate: startDate,
    }
    requestReportCreation({
      input: createReportRequest,
      binds: [['companyID', companyID!]],
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Sucesso',
          message:
            'Seu relatório está em preparação. Por favor, aguarde a finalização do processo de criação do relatório.',
        })
        resetName()
        resetReportType()
        resetReportMonitoringType()
        resetStartDate()
        navigate(Routes.Reports, companyID!)
      },
    })
  }

  useEffect(() => {
    if (reportResponse?.id) {
      updateReportResponse(reportResponse)
    }
  }, [reportResponse, updateReportResponse])

  return (
    <Modal
      controlKey={NewReportModalName}
      title="Novo Relatório"
      subtitle={
        <span>
          Preencha todos os campos <strong>obrigatórios (*)</strong> para solicitar um novo relatório.
        </span>
      }
      icon={DocDetail}>
      <Form onSubmit={onSubmit} controlParam={isCreatingReport}>
        <Input label="Nome do relatório *" placeholder="Relatório mensal/diário Jan24" {...nameBind} />
        <Flex $type="row" $justify="space-between" $align="center" $gap={20}>
          <Selector.Default
            label="Tipo de relatório *"
            placeholder="Tipo de relatório"
            options={
              monitoringTypes?.map((item) => ({
                text: Str.from(item.text).initCap,
                value: item.value,
              })) ?? []
            }
            {...reportTypeBind}
          />
          <Selector.Default
            label="Tipo de monitoramento *"
            placeholder="Tipo de monitoramento"
            options={
              types?.map((item) => ({
                text: Str.from(item.text).initCap,
                value: item.value,
              })) ?? []
            }
            {...reportMonitoringTypeBind}
          />
        </Flex>
        <Flex $type="row" $justify="flex-start" $align="flex-end" $gap={20}>
          <Input label="Data Inicial" placeholder="DD/MM/YYYY" date {...startDateBind} required />
          <Button.Root $variant="primary" $size={275} type="submit" $loading={isCreatingReport}>
            <Button.Text value="Gerar novo relatório" />
          </Button.Root>
        </Flex>
      </Form>
    </Modal>
  )
}
