import { useContext } from 'react'
import { createPortal } from 'react-dom'

import { Button } from '@/components'

import { ConfirmationContext } from './Confirmation.context'
import { ButtonGroup, IconContainer, Modal, Overlay, Text, Title } from './Confirmation.styles'

const $domElement = document.getElementById('confirm') as HTMLDivElement

export function Component(): React.ReactPortal | null {
  const { isOpen, data, close } = useContext(ConfirmationContext)

  function handleConfirm() {
    if (data?.onConfirm) {
      data.onConfirm()
      close()
    }
  }

  if (!isOpen || !data) return null

  return createPortal(
    <Overlay>
      <Modal>
        <IconContainer>
          <data.icon size={36} />
        </IconContainer>
        <Title>{data.title}</Title>
        <Text>{data.text}</Text>
        <ButtonGroup>
          <Button.Root $variant="danger" onClick={handleConfirm}>
            <Button.Text value={data.confirmText} />
          </Button.Root>
          <Button.Root onClick={close}>
            <Button.Text value={data.cancelText} />
          </Button.Root>
        </ButtonGroup>
      </Modal>
    </Overlay>,
    $domElement,
  )
}
