/* eslint-disable react-refresh/only-export-components */
import { ElementType, ReactNode, createContext, memo, useContext, useState } from 'react'

import { Caution } from '@icon-park/react'

interface ContextData {
  icon: ElementType
  title: string
  text: string
  confirmText: string
  cancelText: string
  onConfirm?: () => void
}

interface Output {
  isOpen: boolean
  open: (onConfirm?: () => void) => void
  close: () => void
  data?: ContextData
  setData: (data: ContextData) => void
}

export const ConfirmationContext = createContext<Output>({} as Output)

interface HookOutput {
  open: (onConfirm?: () => void) => void
}

interface HookInput {
  icon?: ElementType
  title?: string
  text: string
  confirmText?: string
  cancelText?: string
}

export function useConfirmation({
  icon = Caution,
  title = 'Você tem certeza?',
  text,
  confirmText = 'Sim! Confirmar',
  cancelText = 'Não! Cancelar',
}: HookInput): HookOutput {
  const context = useContext(ConfirmationContext)
  if (!context) throw new Error('useConfirmation deve ser chamado dentro do contexto correto!')
  const { open: contextOpen, setData } = context

  function open(onConfirm?: () => void) {
    setData({ icon, title, text, confirmText, cancelText, onConfirm })
    contextOpen()
  }

  return { open }
}

export const ContextProvider = memo(ContextProviderComponent)

function ContextProviderComponent({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [data, setData] = useState<ContextData | undefined>(undefined)

  return (
    <ConfirmationContext.Provider
      value={{
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        data,
        setData,
      }}>
      {children}
    </ConfirmationContext.Provider>
  )
}
