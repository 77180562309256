import { Tokens } from '@/assets/tokens'
import styled, { css } from 'styled-components'

type NavigatorProps = {
  $active?: boolean
  $disabled?: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
`

export const Navigator = styled.button<NavigatorProps>`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.5rem;
  color: #5e6287;
  font-size: 14px;
  line-height: 11.2px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: ${Tokens.ColorHover};
    color: ${Tokens.ColorTextPrimary};
    font-weight: 400;
  }

  ${({ $active }) =>
    $active &&
    css`
      font-weight: 700;
      background-color: ${Tokens.ColorUIPrimary};
      color: ${Tokens.ColorWhite};
      pointer-events: none;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      display: none;
    `}
`
