import { memo, useEffect } from 'react'

import { Certificate, HammerAndAnvil, Peoples } from '@icon-park/react'

import { CreateMakerRequest, DataSourceResponse, MakerResponse, MakerStatus, Paged } from '@shared/models'

import { Api } from '@/modules/api'

import { useRouting } from '@/hooks'

import { Button, Flex, Form, HorizontalSelectedList, SelectionOption, Selector } from '@/components'
import { useSelectedList } from '@/components/core/HorizontalSelectedList/HorizontalSelectedList.hooks'
import { ManualCard, Modal } from '@/components/derived'

import { useObserver } from '@/modules/observer'
import { useCompanyContext } from '../CompanyDetails.context'
import { getDescription } from '../utils'
import { MakerTable } from './Maker.table'
import { genderOptions, getCurrentStatus, periodicityOptions, tiers } from './Maker.utils'

export const Maker = memo(Component)

function Component(): JSX.Element | null {
  const { companyID, changeQueryParam } = useRouting()
  const { observe, removeObserver } = useObserver()
  const { company, reloadCompany } = useCompanyContext()

  const { binder: tierBinder, selectedOption: tier } = Selector.Binder()
  const { binder: genderBinder, selectedOption: selectedGender } = Selector.Binder()
  const { binder: dataSourcesFilterBinder, selectedOptions: selectedDataSourcesForFilter } = useSelectedList(() => {})
  const { binder: periodicityFilterBinder, selectedOptions: selectedPeriodicityForFilter } = useSelectedList(() => {})

  const [loadingDataSources, , dataSources] = Api.Query<Paged<DataSourceResponse>>('common/datasources')
  const [, reloadMakerTable, makerData] = Api.Query<MakerResponse[]>(`/companies/${companyID}/makers/list`)

  const [isLoadingMakerStatus, reloadMakerStatus, fetchedMakerStatus] = Api.Query<MakerStatus>(
    `/companies/${companyID}/makers/`,
  )

  const { run: saveMaker, isRunning: isRunningMaker } = Api.Command<MakerStatus>(
    Api.Methods.POST,
    `/companies/${companyID}/makers`,
  )

  const makerStatus = getCurrentStatus(isRunningMaker, isLoadingMakerStatus, fetchedMakerStatus)

  useEffect(() => {
    const id = observe('teste', (message: string) => {
      alert(`Fui triggado pelo observer ${message}`)
    })
    return () => removeObserver('teste', id)
  }, [])

  useEffect(() => {
    if (!makerStatus) {
      reloadMakerStatus()
    }
  }, [makerStatus])

  useEffect(() => {
    if (makerStatus === 'ok') {
      reloadMakerTable()
    }
  }, [makerStatus])

  const getButtonVariant = () => {
    if (makerStatus === 'progress') return 'warn'
    if (makerStatus === 'failed') return 'danger'
    if (makerStatus === 'ok') return 'primary'
    return 'primary'
  }

  const getButtonText = () => {
    if (makerStatus === 'progress') return 'Em Progresso'
    if (makerStatus === 'failed') return 'Falhou'
    if (makerStatus === 'ok') return 'Gerar'
    return 'Gerar'
  }

  function onSubmit(): void {
    if (!companyID) throw Error('Unexpected error. Contact the system administrator.')

    const input: CreateMakerRequest = {
      companyID,
      periodicity: selectedPeriodicityForFilter.map((item: SelectionOption) => item.value as string),
      dataSourceID: selectedDataSourcesForFilter.map((item: SelectionOption) => Number(item.value)),
      tierType: (tier?.value as string) || '',
      gender: (selectedGender?.value as string) || '',
    }

    saveMaker<CreateMakerRequest>({
      input,
      onSuccess: () => {
        reloadCompany()
        changeQueryParam({ param: 'modal', replaceAll: true })
      },
    })

    return
  }

  if (!companyID || !company) return null

  return (
    <>
      <ManualCard
        icon={HammerAndAnvil}
        healthCheck={company.healthCheck?.isMakerChecked}
        description={getDescription(company.counts?.makers)}
        title="Configurar Relatório"
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'maker',
            replaceAll: true,
          })
        }
      />
      <Modal
        controlKey="maker"
        backToName={company.name}
        title="Configurar Relatório"
        subtitle={
          <span>
            Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro do maker.
          </span>
        }
        icon={HammerAndAnvil}>
        <Form onSubmit={onSubmit} controlParam={isRunningMaker}>
          <Flex $type="row" $justify="space-between" $align="center" $gap={12} $fit>
            <Selector.Default label="Tier" placeholder="Selecione um tier" options={tiers} {...tierBinder} required>
              <Selector.Icon value={Certificate} />
            </Selector.Default>
            <Selector.Default
              label="Gênero"
              placeholder="Selecione um gênero"
              options={genderOptions}
              {...genderBinder}
              required
            />
          </Flex>
          <HorizontalSelectedList
            icon={Peoples}
            label="Fonte de dados"
            placeholder="Fonte"
            isLoading={loadingDataSources}
            options={
              dataSources?.elements.map((dataSource) => ({
                text: dataSource.name.charAt(0) + dataSource.name.slice(1).toLowerCase(),
                value: `${dataSource.id}`,
              })) ?? []
            }
            {...dataSourcesFilterBinder}
          />
          <HorizontalSelectedList
            label="Periodicidade"
            placeholder="Periodicidade"
            isLoading={false}
            options={periodicityOptions}
            {...periodicityFilterBinder}
          />
          <Flex $type="row" $justify="flex-end" $align="center" $fit>
            <Button.Root type="submit" $variant={getButtonVariant()} $size={315} $loading={isRunningMaker}>
              <Button.Text value={getButtonText()} />
            </Button.Root>
          </Flex>
        </Form>
        <MakerTable elements={makerData ?? []} />
      </Modal>
    </>
  )
}
