import { keyframes, styled } from 'styled-components'

const LoaderAnimation = keyframes`
to {
  transform: rotate(360deg);
}
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
`

export const Loader = styled.img`
  width: 10%;
  animation: ${LoaderAnimation} 2s linear infinite;
`
