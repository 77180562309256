/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from 'd3'
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { fillEndPoint } from '../utils/fillEndPoint'
import { getFromApi } from '../utils/getFromApi'
import { generateUniqueID } from '../utils/strings'
import { useValues } from '../ValuesContext'
import './styles.css'
import { downloadPNG, isAnyDashLoading } from './utils'

interface BarSideBySideProps {
  subtitle: string
  xValue: string
  yValue: string
  target: string
  targetKey: string
  colors?: string[]
  cena?: any
  endPoint: string
}

const BarSideBySide: React.FC<BarSideBySideProps> = ({
  subtitle,
  xValue,
  yValue,
  target,
  targetKey,
  colors,
  cena,
  endPoint,
}) => {
  const componentID = useMemo(() => generateUniqueID(), [])
  const customLocale: d3.FormatLocaleDefinition = {
    decimal: ',',
    thousands: '.',
    grouping: [3],
    currency: ['', ''],
  }

  d3.formatDefaultLocale(customLocale)

  const [isLoading, setIsLoading] = useState(true)
  const { values, updateValues }: any = useValues()

  const printableRef = useRef<HTMLDivElement>({} as HTMLDivElement)

  const svgWidth = 1000
  const svgHeight = 330

  const margin = { top: 20, right: 11.8, bottom: 30, left: 45 }
  const width = svgWidth - margin.left - margin.right
  const height = svgHeight * 0.8 - margin.top - margin.bottom

  function isEmptyOrUndefined(value: any) {
    return value === '' || value === undefined || value === null
  }

  const exportAsPng = () => {
    downloadPNG(printableRef, subtitle, true)
  }

  useEffect(() => {
    if (Object.keys(values).length === 0 || !values.idEmpresaIN || values.k) {
      return
    }

    setIsLoading(true)

    d3.select(printableRef.current).select(`.column-chart-container`).remove()

    let getUrl: any
    if (isEmptyOrUndefined(values.mesInfIN) && isEmptyOrUndefined(values.mesSupIN)) {
      getUrl = fillEndPoint(endPoint, values)
      subtitle = subtitle + ' por Mês'
    } else {
      getUrl = fillEndPoint(endPoint + 'Dia', values)
      subtitle = subtitle + ' por Dia'
    }

    getFromApi(getUrl)
      .then((elements) => {
        if (Object.keys(elements).length === 0) {
          d3.select(printableRef.current)
            .append('div')
            .attr('class', 'column-chart-container')
            .style('width', '1036px')
            .style('text-align', 'center')
            .style('display', 'flex')
            .style('justify-content', 'center')
            .style('align-items', 'center')
            .text('Não há dados para análise no período')
          setIsLoading(false)
          return
        }

        // Função para calcular a soma das menções de um determinado tipo de polaridade em um mês
        const sumBySubGroup = (data: any, subGroup: any) => {
          return data.reduce((sum: any, item: any) => {
            if (item[target] === subGroup) {
              return sum + item[yValue]
            } else {
              return sum
            }
          }, 0)
        }

        // Função para transformar os dados de um mês em um objeto com contagens separadas por polaridade
        const summariseSubGroup = (group: any, data: any, subGroups: any) => {
          // Construir o objeto resultante dinamicamente
          const result = subGroups.reduce(
            (acc: any, subGroup: any) => {
              const sum = sumBySubGroup(data, subGroup)
              acc[subGroup] = sum !== 0 ? sum : 0
              return acc
            },
            { group: group },
          )

          return result
        }
        // Função para iterar sobre os dados e transformá-los em um novo formato
        function getSubGroupNames(data: any) {
          const values = Object.values(data)
          const subGroupsList: any = []

          values.forEach((element: any) => {
            element.forEach((item: any) => {
              if (item[yValue] > 0 && !subGroupsList.includes(item[target])) {
                subGroupsList.push(item[target])
              }
            })
          })

          return subGroupsList
        }

        const transformData = (dados: any, subGroups: any) => {
          const novoFormato = []
          for (const [group, data] of Object.entries(dados)) {
            novoFormato.push(summariseSubGroup(group, data, subGroups))
          }
          return novoFormato
        }

        const subGroups = getSubGroupNames(elements)
        if (target === 'polaridade') {
          //Acerta a hierarquia
          const hierarquiaPolaridades: Record<string, number> = {
            Negativo: 1,
            Desfavoravel: 2,
            Neutro: 3,
            Favoravel: 4,
            Positivo: 5,
          }

          subGroups.sort((a: any, b: any) => {
            const hierarquiaA = hierarquiaPolaridades[a]
            const hierarquiaB = hierarquiaPolaridades[b]
            return hierarquiaA - hierarquiaB
          })
        }

        const formatData = transformData(elements, subGroups)
        const groups = d3.map(formatData, function (d) {
          return d.group
        })

        const stackedData = d3.stack().keys(subGroups)(formatData)

        setIsLoading(false)
        if (!printableRef.current) return

        const printableElement = d3.select(printableRef.current)
        const tooltip = printableElement.append('div').attr('class', 'tooltip')

        function calculateCornerRadius(d: any) {
          if (d >= 0.1 * yLimit2) return 10
          if (d <= 0.01 * yLimit2) return 4
          return 7 // Gradual reduction from 10 to 1
        }

        const chartContainer = printableElement.append('div').attr('class', 'column-chart-container')

        const headerContainer = chartContainer
          .insert('div')
          .attr('class', 'chart-header')
          .style('position', 'relative')
          .style('display', 'flex')
          .style('align-items', 'center')
          .style('justify-content', 'space-between')
          .style('margin-bottom', '10px')

        headerContainer
          .insert('h3')
          .attr('class', 'column-chart-subtitle')
          .style('display', 'flex')
          .style('align-items', 'center')
          .style('margin', '0')
          .text(subtitle)

        headerContainer
          .append('button')
          .attr('class', 'export-button')
          .style('border', 'none')
          .style('justify-content', 'space-between')
          .style('position', 'relative')
          .style('background', 'transparent')
          .style('margin-left', '10px')
          .style('margin-top', '-33px')
          .html(
            `
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#C7C7C7">
          <path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z"/>
        </svg>
      `,
          )
          .on('click', function () {
            exportAsPng()
          })

        if (
          !isEmptyOrUndefined(values.mesInfIN) &&
          !isEmptyOrUndefined(values.mesSupIN) &&
          !isEmptyOrUndefined(values.diaInfIN) &&
          !isEmptyOrUndefined(values.diaSupIN)
        ) {
          chartContainer
            .append('button')
            .attr('class', 'close-button')
            .text('Ir para Mensal')
            .on('click', () => {
              if (isAnyDashLoading()) {
                return
              }
              updateValues({
                diaInfIN: '',
                diaSupIN: '',
              })
            })
        } else if (
          !isEmptyOrUndefined(values.mesInfIN) &&
          !isEmptyOrUndefined(values.mesSupIN) &&
          isEmptyOrUndefined(values.diaInfIN) &&
          isEmptyOrUndefined(values.diaSupIN)
        ) {
          chartContainer
            .append('button')
            .attr('class', 'close-button')
            .text('Ir para Anual')
            .on('click', () => {
              if (isAnyDashLoading()) {
                return
              }
              updateValues({
                mesInfIN: '',
                mesSupIN: '',
              })
            })
        }

        function extract(elements: any[]) {
          const allMencoes = Object.values(elements).map((element) => element.map((item: any) => item[yValue]))
          return allMencoes
        }

        const xData = Object.keys(elements)

        let dataArray = Object.entries(elements).flatMap(([element, values]: any) =>
          values.map((item: any) => ({ ...item, [xValue]: element })),
        )

        const sumByMonthAndIdPolaridade = dataArray.reduce((acc, item) => {
          const key = `${item[xValue]}-${item[targetKey]}`

          if (!acc[key]) {
            acc[key] = {
              [xValue]: item[xValue],
              [targetKey]: item[targetKey],
              [target]: item[target],
              [yValue]: 0,
            }
          }

          acc[key][yValue] += item[yValue]

          return acc
        }, {})

        dataArray = Object.values(sumByMonthAndIdPolaridade)

        const data_sum = extract(elements).map((innerArray) =>
          innerArray.reduce((sum: number, current: number) => sum + current, 0),
        )
        const yLimit1 = 0

        function findMaxValueBar(stackedData: any[]) {
          let maxMencoes = 0

          stackedData.forEach((group) => {
            group.forEach((bar: any) => {
              if (bar[1] > maxMencoes) {
                maxMencoes = bar[1]
              }
            })
          })

          return maxMencoes
        }

        function roundUpMaxValue(maxValue: number) {
          if (maxValue === 0) return 0
          const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)))
          return Math.ceil(maxValue / magnitude) * magnitude
        }
        const maxValueBar = findMaxValueBar(stackedData)
        const yLimit2: number = roundUpMaxValue(maxValueBar)

        const chart = chartContainer
          .append('svg')
          .attr('width', svgWidth)
          .attr('height', svgHeight)
          .append('g')
          .attr('transform', `translate(${margin.left},${margin.top})`)

        let padding
        if (groups.length <= 20) {
          padding = 0.63
        } else {
          padding = 0.1
        }

        const xScale: any = d3.scaleBand().domain(groups).range([0, width]).padding(padding)

        const yScale = d3.scaleLinear().domain([yLimit1, yLimit2]).range([height, 0])

        function formatTick(d: any) {
          if (d === 0) return '0'
          const format = d3.format('.3s')
          let formatted = format(d).replace(/G$/, 'B')

          formatted = formatted.replace(/,/g, '.')

          if (formatted.endsWith('k') || formatted.endsWith('M') || formatted.endsWith('B')) {
            const match = formatted.match(/^(\d+\.?\d*)([kMB])$/)
            if (match) {
              let [, number] = match
              const unit = match[2]
              number = parseFloat(number).toFixed(number.indexOf('.') !== -1 ? 1 : 0)
              formatted = `${number}${unit}`
            }
          }

          return formatted
        }

        const onClick = function (_event: MouseEvent, input: any) {
          if (isAnyDashLoading()) {
            return
          }
          tooltip.style('display', 'none')
          const map: Record<string, number> = {
            Jan: 1,
            Fev: 2,
            Mar: 3,
            Abr: 4,
            Mai: 5,
            Jun: 6,
            Jul: 7,
            Ago: 8,
            Set: 9,
            Out: 10,
            Nov: 11,
            Dez: 12,
          }
          if (isEmptyOrUndefined(values.mesInfIN) && isEmptyOrUndefined(values.mesSupIN)) {
            updateValues({
              mesInfIN: map[input],
              mesSupIN: map[input],
            })
          } else {
            updateValues({
              diaInfIN: input,
              diaSupIN: input,
            })
          }

          tooltip.style('display', 'none')
        }

        chart
          .append('g')
          .attr('transform', `translate(0, 0)`)
          .call(d3.axisLeft(yScale).ticks(5).tickFormat(formatTick))
          .call((g) => g.select('.domain').remove())
          .call((g) => g.selectAll('.tick line').remove())
          .call((g) => g.selectAll('.tick text').style('font-size', '14px'))

        //Meses eixo x
        chart
          .append('g')
          .attr('transform', `translate(0,${height})`)
          .call(d3.axisBottom(xScale).tickSize(0))
          .call((g) => g.select('.domain').remove())
          .selectAll('.tick text')
          .style('text-anchor', 'middle')
          .attr('dy', '1.35em')
          .style('font-size', '16px')
          .style('cursor', 'pointer')

        chart.selectAll('.tick text').on('click', onClick)

        const yAxisTicks = yScale.ticks(4)

        yAxisTicks.forEach((tick) => {
          chart
            .append('line')
            .attr('x1', 0)
            .attr('x2', width - margin.right)
            .attr('y1', yScale(tick))
            .attr('y2', yScale(tick))
            .attr('stroke', '#F2F3F6')
            .attr('stroke-width', 1)
        })

        const onOver = function (this: any, _event: MouseEvent, d: any) {
          const subgroupName = (d3.select(this.parentNode).datum() as { key: string }).key
          const value = d.data[subgroupName]

          chart.selectAll('rect').style('opacity', 0.2)
          d3.select(this).style('opacity', 1)

          const $elements = document.querySelectorAll(`#${componentID} .column-chart-title-container`)

          const $currentElement = document.querySelectorAll(`#${componentID} div[data-value="${subgroupName}"]`)[0]

          $elements.forEach(($element) => {
            $element.classList.add('hidden')
          })

          if ($currentElement) {
            $currentElement.classList.remove('hidden')
          }

          tooltip.style('display', 'block').text(`${subgroupName}: ${value}`)
        }

        const onMove = function (event: MouseEvent) {
          tooltip.style('left', `${event.pageX + 15}px`).style('top', `${event.pageY - 15}px`)
        }

        const onLeave = function () {
          chart.selectAll('rect:not(.diamond)').style('opacity', 1)

          const $elements = document.querySelectorAll(`#${componentID} .column-chart-title-container`)
          $elements.forEach(($element) => {
            $element.classList.remove('hidden')
          })

          tooltip.style('display', 'none')
        }

        const mappedTargets = dataArray.map((d) => d[target])
        const uniqueXTargets = new Set(mappedTargets)
        const uniqueXArrayValues = Array.from(uniqueXTargets)
        const uniqueXValues = uniqueXArrayValues.map((element) => {
          const item = dataArray.find((d) => d[target] === element)
          return {
            [target]: item[target],
            [targetKey]: item[targetKey],
          }
        })
        let colorMap: Record<string, string> = {}
        if (colors?.length) {
          uniqueXValues.forEach((value, index) => {
            colorMap[value[target]] = colors[index]
          })
        } else {
          colorMap = {
            Negativo: '#FF4D64',
            Desfavoravel: '#FFC765',
            Neutro: '#D3D3D3',
            Favoravel: '#61EAC4',
            Positivo: '#01C38D',
          }
        }

        stackedData.forEach((element) => {
          element.forEach((x) => {
            if (x[0] != 0) {
              x[0] = 0
            }
          })
        })

        stackedData.reverse()

        const firstElement = Object.keys(elements)[0]
        const mapID: any = {}
        elements[firstElement].forEach((element: { [x: string]: any }) => {
          mapID[element[target]] = element[targetKey]
        })

        const onClickID = function (this: any) {
          if (isAnyDashLoading()) {
            return
          }
          const subgroupName = (d3.select(this.parentNode).datum() as { key: string }).key
          tooltip.style('display', 'none')
          if (endPoint.includes('Channel')) {
            if (mapID[subgroupName] != 'NA') {
              if (values.idCanalIN) {
                updateValues({ idCanalIN: '' })
              } else {
                updateValues({ idCanalIN: mapID[subgroupName] })
              }
            }
          } else if (endPoint.includes('Polarity')) {
            if (values.idPolaridadeIN) {
              updateValues({ idPolaridadeIN: '' })
            } else {
              updateValues({ idPolaridadeIN: mapID[subgroupName] })
            }
          } else if (endPoint.includes('Publisher')) {
            if (mapID[subgroupName] != 'NA') {
              if (values.idPublicadorIN) {
                updateValues({ idPublicadorIN: '' })
              } else {
                updateValues({ idPublicadorIN: mapID[subgroupName] })
              }
            }
          } else if (endPoint.includes('Role')) {
            if (values.idPapelIN) {
              updateValues({ idPapelIN: '' })
            } else {
              updateValues({ idPapelIN: mapID[subgroupName] })
            }
          } else if (endPoint.includes('DataSource')) {
            if (values.idDataSourceIN) {
              updateValues({ idDataSourceIN: '' })
            } else {
              updateValues({ idDataSourceIN: mapID[subgroupName] })
            }
          }
        }

        chart
          .selectAll('.bar-group')
          .data(stackedData)
          .enter()
          .append('g')
          .attr('fill', (d) => colorMap[d.key])
          .selectAll('rect')
          .data(function (d) {
            return d
          })
          .enter()
          .append('rect')
          .attr('class', 'bars')
          .attr('x', function (d) {
            return xScale(d.data.group)
          })
          .attr('y', function (d) {
            return yScale(d[0])
          })
          .attr('width', xScale.bandwidth())
          .attr('height', 0)
          .attr('rx', (d) => calculateCornerRadius(d[1]))
          .attr('ry', (d) => calculateCornerRadius(d[1]))
          .on('mouseover', onOver)
          .on('mousemove', onMove)
          .on('mouseleave', onLeave)
          .on('click', onClickID)
          .style('cursor', 'pointer')
          .transition()
          .duration(1000)
          .attr('y', function (d) {
            return yScale(d[1])
          })
          .attr('height', function (d) {
            return yScale(d[0]) - yScale(d[1])
          })

        function formatNumberWithDot(number: number) {
          return d3.format(',')(number).replace(/,/g, '.')
        }

        if (cena) {
          const lastSlashIndex = endPoint.lastIndexOf('/')
          const baseUrl = endPoint.substring(0, lastSlashIndex)
          let CENAEndPoint: any
          if (isEmptyOrUndefined(values.mesInfIN) && isEmptyOrUndefined(values.mesSupIN)) {
            CENAEndPoint = fillEndPoint(`${baseUrl}/lineCENA`, values)
          } else {
            CENAEndPoint = fillEndPoint(`${baseUrl}/lineCENADia`, values)
          }

          getFromApi(CENAEndPoint).then((cena) => {
            const dataArrayReacts = Object.entries(cena).map(([month, value]) => {
              const numericValue = isNaN(Number(value)) || value === 'NaN' ? 0 : Number(value)
              return { month, value: numericValue }
            })

            const CENAValues: any[] = dataArrayReacts.map((item) => item.value)

            const yScaleCENA = d3
              .scaleLinear()
              .domain([yLimit1, roundUpMaxValue(Math.max(...CENAValues))])
              .range([height, 0])

            const lineGenerator: any = d3
              .line()
              .x((d: any) => xScale(d.month) + xScale.bandwidth() / 2)
              .y((d: any) => (d.value === 'NaN' ? 0 : yScaleCENA(d.value)))

            const linePath: any = chart
              .append('path')
              .datum(dataArrayReacts)
              .attr('fill', 'none')
              .attr('stroke', '#08C0C5')
              .attr('stroke-width', 2)
              .attr('d', lineGenerator)

            const totalLength = linePath.node().getTotalLength()

            linePath
              .attr('stroke-dasharray', totalLength + ' ' + totalLength)
              .attr('stroke-dashoffset', totalLength)
              .transition()
              .duration(2000)
              .attr('stroke-dashoffset', 0)

            //legenda y direita
            chart
              .append('g')
              .attr('class', 'axis axis--y-right')
              .attr('transform', `translate(${width - margin.right}, 0)`)
              .call(d3.axisRight(yScaleCENA).ticks(5).tickFormat(d3.format('d')))
              .call((g) => g.select('.domain').remove())
              .call((g) => g.selectAll('.tick line').remove())
              .call((g) => g.selectAll('.tick text').style('font-size', '14px'))

            const points = chart.selectAll('.point').data(dataArrayReacts).enter().append('g')

            points
              .append('circle')
              .attr('cx', (d) => xScale(d.month) + xScale.bandwidth() / 2)
              .attr('cy', (d: any) => (d.value === 'NaN' ? 0 : yScaleCENA(d.value)))
              .attr('r', 0)
              .attr('fill', '#08C0C5')
              .on('mouseover', () => {
                const $elements = document.querySelectorAll(`#${componentID} .column-chart-title-container`)

                const $currentElement = document.querySelectorAll(`#${componentID} div[data-value="CENA"`)[0]

                $elements.forEach(($element) => {
                  $element.classList.add('hidden')
                })
                $currentElement.classList.remove('hidden')
              })
              .on('mouseleave', () => {
                const $elements = document.querySelectorAll(`#${componentID} .column-chart-title-container`)

                $elements.forEach(($element) => {
                  $element.classList.remove('hidden')
                })
              })
              .transition()
              .duration(2000)
              .delay((_d, i: any) => i * 200)
              .attr('r', 4)

            points
              .append('text')
              .attr('class', 'point-text')
              .attr('x', (d: any) => xScale(d.month) + xScale.bandwidth() / 2)
              .attr('y', (d: any) => (d.value === 'NaN' ? -10 : yScaleCENA(d.value) - 10))
              .attr('text-anchor', 'middle')
              .text((d: any) => (d.value === 'NaN' ? 'N/A' : formatNumberWithDot(d.value)))
              .attr('font-size', '12px')
              .attr('fill', 'black')
              .style('display', 'none')

            points
              .on('mouseover', function () {
                d3.select(this).select('.point-text').style('display', null)
              })
              .on('mouseout', function () {
                d3.select(this).select('.point-text').style('display', 'none')
              })
          })
        }

        //Numeros
        chart
          .selectAll('.title')
          .data(xData)
          .enter()
          .append('text')
          .attr('class', 'mention-text')
          .attr('x', (d) => xScale(d) + xScale.bandwidth() / 2)
          .attr('y', height + margin.bottom * 1.5)
          .attr('text-anchor', 'middle')
          .style('font-family', 'TTCommons/TT-Commons-Regular.ttf')
          .style('font-size', '10px')
          .style('fill', '#434343')
          .text((_d, i) => formatNumberWithDot(data_sum[i]))

        const titles = chartContainer
          .insert('div')
          .attr('class', 'column-chart-titles')
          .style('flex-direction', 'row')
          .style('margin-top', '-20px')

        if (cena) {
          subGroups.push('CENA')
        }

        subGroups.forEach((xValue: any) => {
          const value = String(xValue)
          const titleContainer = titles
            .insert('div')
            .attr('class', 'column-chart-title-container')
            .attr('data-value', value)

          let iconColor

          if (value != 'CENA') {
            iconColor = colorMap[value]
          } else {
            iconColor = '#08C0C5'
          }

          titleContainer.insert('div').attr('class', 'column-chart-title-icon').style('background-color', iconColor)

          titleContainer.append('span').attr('class', 'column-chart-title').text(value)
        })
      })
      .catch((error) => {
        console.log('Error fetching data in sideByside:', error)
      })
  }, [values])

  return (
    <div id={componentID} ref={printableRef}>
      {isLoading ?
        <div className="column-chart-container-spinner" style={{ width: '1036px', height: '404px' }}></div>
      : <div className="chart-container"></div>}
    </div>
  )
}

export default BarSideBySide
