import styled, { css, keyframes } from 'styled-components'

import { Tokens } from '@/assets/tokens'

export type AlertType = 'success' | 'error'

export interface ComponentProps {
  type: AlertType
}

const fadeIn = keyframes`
from {
  transform: translateY(-100vw);
}
to {
  transform: translateY(0);
}
`

export const MainContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 0;

  width: 100vw;
  min-height: 6rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  animation-name: ${fadeIn};
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`

export const ComponentContainer = styled.div`
  width: 730px;
  height: fit-content;

  padding: 1rem 1.5rem;
  border-radius: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;

  background-color: ${Tokens.ColorWhite};
  //background: rgba(255, 255, 255, 0.75);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  //backdrop-filter: blur(2px);
  //-webkit-backdrop-filter: blur(2px);
  //border-radius: 10px;
`

export const IconContainer = styled.div<ComponentProps>`
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ type }) => IconContainerType[type]}
`

export const IconContainerType = {
  success: css`
    background-color: ${Tokens.ColorUIPrimary}40;
  `,
  error: css`
    background-color: ${Tokens.ColorUIDanger}40;
  `,
}
