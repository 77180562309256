import { Close } from '@icon-park/react'

import { Icon } from '../Icon/Icon.component'
import { CleanButtonContainer } from './Selection.styles'

type Props = {
  onClick: () => void
}

export const CleanButton: React.FC<Props> = (props): JSX.Element => {
  return (
    <CleanButtonContainer {...props}>
      <Icon value={Close} size={10} />
    </CleanButtonContainer>
  )
}
