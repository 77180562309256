import { ChangeEvent, RefObject } from 'react'

import { generateRandomID } from '@/utils/idGenerator'

import { IconImage, InnerContainer, InputContainer, InputIn, Label } from './Input.styles'

interface Props {
  id?: string
  value?: string
  type?: string
  min?: number
  max?: number
  innerRef?: RefObject<HTMLInputElement>
  label?: string
  iconPassword?: string
  placeholder?: string
  required?: boolean
  maxLength?: number
  date?: boolean
  disabled?: boolean
  readOnly?: boolean
  onChange: (value: string) => void
  onIconClick?: () => void
}

export function InputPassword({
  id,
  value,
  innerRef,
  label,
  iconPassword,
  placeholder,
  required,
  disabled,
  readOnly,
  onChange,
  onIconClick,
  ...rest
}: Props) {
  const inputID = id ? id : generateRandomID()

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    if (!onChange) return
    const value = event.target.value
    onChange(value)
  }

  return (
    <InputContainer>
      {label && <Label htmlFor={inputID}>{`${label}${required ? '*' : ''}`}</Label>}
      <InnerContainer>
        <InputIn
          ref={innerRef}
          id={inputID}
          value={value}
          placeholder={placeholder}
          required={required}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
        {iconPassword && <IconImage src={iconPassword} alt="input-icon" onClick={onIconClick} />}
      </InnerContainer>
    </InputContainer>
  )
}
