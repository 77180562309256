import { Logout } from '@icon-park/react'

import { Tokens } from '@/assets/tokens'

import { Auth } from '@/modules'

import { Button } from '@/components'

import { Container, ProfilePic, ImageContainer, Info } from './UserInfo.styles'

export const UserInfo = () => {
  const { isLoading, session, logout } = Auth.Hook()

  if (!session) return null

  return (
    <Container>
      <Info>
        <span>{session.userName}</span>
        <span>{session.userRole}</span>
      </Info>
      <ImageContainer>
        <ProfilePic alt={session.userName} src={session.userPic} />
      </ImageContainer>
      <Button.Root onClick={logout} $loading={isLoading}>
        <Button.Icon value={Logout} color={Tokens.ColorTextPrimary} />
      </Button.Root>
    </Container>
  )
}
