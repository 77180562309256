import _ from 'lodash'
import { ColumnType, TableProps } from './Table.component'
import { TableCell, TableRowItem } from './Table.styles'

type CellProps<T> = {
  idx: number
  item: T
  column: ColumnType<T>
}

export function TableRowCell<T>({ idx, item, column }: CellProps<T>) {
  const value = _.get(item, column.key)

  return <TableCell>{column.render ? column.render(column, item, idx) : value}</TableCell>
}

export function TableRows<T>({ data, columns }: TableProps<T>) {
  return (
    <>
      {data?.map((item, itemIndex) => (
        <TableRowItem key={`table-body-${itemIndex}`}>
          {columns.map((column, columnIndex) => (
            <TableRowCell key={`table-row-cell-${columnIndex}`} item={item} column={column} idx={itemIndex} />
          ))}
        </TableRowItem>
      ))}
    </>
  )
}
