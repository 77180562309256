import { Tokens } from '@/assets/tokens'
import styled from 'styled-components'

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000050;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10rem;
  z-index: 10;
`

export const Container = styled.div`
  width: 50rem;
  height: fit-content;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  background-color: ${Tokens.ColorWhite};
  border-radius: ${Tokens.RadiusInput};
`
