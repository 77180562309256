import { nanoid } from 'nanoid'

export function generateRandomID(size?: number): string {
  return nanoid(size ?? 10)
}

export function generateDeterministicID(...fields: any[]): string {
  if (!fields || !fields.length) {
    return nanoid(10)
  }
  const hash = createSimpleHash(fields.join('|'))
  return hash + '-' + nanoid(10)
}

function createSimpleHash(input: string): any {
  let hash = 0
  for (let i = 0; i < input.length; i++) {
    hash = (hash << 5) - hash - input.charCodeAt(i)
    hash = hash | 0
  }
  return Math.abs(hash).toString(36)
}
