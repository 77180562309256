import { useEffect, useState } from 'react'

import { SettingConfig } from '@icon-park/react'

import { ReportSettingResponse, UpdateReportSettingRequest } from '@shared/models'

import { Alert, Api } from '@/modules'
import { CommandMethods } from '@/modules/api/useCommand'

import { Routes } from '@/configs'

import { useRouting } from '@/hooks'

import { Button, Flex, Icon, Selector } from '@/components'
import { Paginator, SkeletonLoader } from '@/components/derived'
import { Layout } from '@/components/layout'
import {
  ButtonsGroup,
  Container,
  RemovableThemesContainer,
  RemovableThemesContainerFooter,
  SlideImg,
  SpokespeopleSelectorContainer,
  ThemeItem,
} from './ReportSettings.styles'

export function ReportSettings(): JSX.Element | null {
  const { companyID, queryParams, changeQueryParam, navigate } = useRouting()

  const currentSlide = Number(queryParams.slide ?? '1')
  const collectionID = queryParams.relatorio
  const dataSourceType = queryParams.dataSource
  const monitoringType = queryParams.periodicidade

  const [selectedThemes, setSelectedThemes] = useState<number[]>([])
  const { binder: spokespersonBinder, selectedOption: spokesperson, reset: resetSpokesperson } = Selector.Binder()

  const { sendAlert } = Alert.Hook()

  const [loadReportSettings, isLoadingReportSettings, reportSettings] = Api.LazyQuery<ReportSettingResponse>(
    `companies/${companyID}/report-settings/${collectionID}`,
  )
  const { isRunning: updatingReportSettings, run: updateReportSettings } = Api.Command<UpdateReportSettingRequest>(
    CommandMethods.PATCH,
    `companies/:companyID/report-settings/:settingID`,
  )

  function selectItem(item: number): void {
    setSelectedThemes((prev) => {
      if (!prev.includes(item)) {
        return [...prev, item]
      }
      return prev.filter((prevItem) => prevItem !== item)
    })
  }

  function save(): void {
    const { id, slideID, screenID, monitoringType, dataSourceType } = reportSettings!
    updateReportSettings({
      binds: [
        ['companyID', companyID!],
        ['settingID', id],
      ],
      input: {
        slideID,
        screenID,
        dataSourceType,
        monitoringType,
        removedThemes: selectedThemes,
        spokeperson: spokesperson && Number(spokesperson.value),
      },
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Sucesso',
          message: 'Relatório atualizado com sucesso',
        })
        if (currentSlide === reportSettings!.slidesCount) {
          return navigate(Routes.CompanyDetails.path, companyID!)
        } else if (currentSlide < reportSettings!.slidesCount) {
          changeQueryParam({
            param: 'slide',
            value: String(currentSlide + 1),
          })
        }
      },
    })
  }

  function cleanCustomizations(): void {
    setSelectedThemes([])
    resetSpokesperson()
  }

  useEffect((): void => {
    if (reportSettings) {
      if (reportSettings.removedThemes) {
        setSelectedThemes(reportSettings.removedThemes)
      }
      if (reportSettings.spokepersonID) {
        const spokeperson = reportSettings.spokepeople?.filter(
          (item) => Number(item.value) === reportSettings.spokepersonID,
        )[0]
        if (spokeperson) resetSpokesperson(spokeperson)
      }
    }
  }, [reportSettings])

  useEffect((): void => {
    cleanCustomizations()
    loadReportSettings({
      dataSourceType: dataSourceType!,
      monitoringType: monitoringType!,
      slide: currentSlide.toString(),
    })
  }, [currentSlide])

  if (!collectionID || !dataSourceType || !monitoringType) return null

  let dataSourceTypeText = 'Imprensa'
  if (dataSourceType === 'socialNetwork') {
    dataSourceTypeText = 'Redes'
  }

  let monitoringTypeText = 'Diário'
  if (reportSettings?.monitoringType === 'monthly') {
    monitoringTypeText = 'Mensal'
  }

  return (
    <Layout.Root>
      <Layout.Padder>
        {isLoadingReportSettings ?
          <SkeletonLoader amount={10} />
        : <Flex $type="column" $justify="flex-start" $align="stretch" $gap={16} $fit>
            <Flex $type="row" $justify="flex-start" $align="center" $gap={16} $fit>
              <Icon value={SettingConfig} size={50} />
              <Flex $type="column" $justify="flex-start" $align="flex-start">
                <h2>Personalizar Relatório - {[dataSourceTypeText, monitoringTypeText].join('/')}</h2>
                <span>Selecione abaixo as configurações desejadas para cada slide do relatório</span>
              </Flex>
            </Flex>
            <Paginator totalPages={reportSettings?.slidesCount} paramName="slide" />
            <SlideImg src={reportSettings?.slideImage} alt="Slide" />
            {isLoadingReportSettings && <SkeletonLoader />}
            {reportSettings?.removableThemes && (
              <Container>
                <div>
                  <h3>Temas ignorados</h3>
                  <span>Marque os temas que não devem ser considerados nesse slide.</span>
                </div>
                <RemovableThemesContainer>
                  {reportSettings.removableThemes.map((item) => (
                    <ThemeItem
                      key={item.value}
                      onClick={() => selectItem(Number(item.value))}
                      $selected={selectedThemes.includes(Number(item.value))}>
                      {item.text}
                    </ThemeItem>
                  ))}
                </RemovableThemesContainer>
                {selectedThemes.length > 0 && (
                  <RemovableThemesContainerFooter>
                    <span>{selectedThemes.length} Temas selecionados</span>
                    <Button.Root $variant="outlined" $size={160} onClick={cleanCustomizations}>
                      <Button.Text value="Limpar" />
                    </Button.Root>
                  </RemovableThemesContainerFooter>
                )}
              </Container>
            )}

            {reportSettings?.spokepeople && (
              <SpokespeopleSelectorContainer>
                <div>
                  <h3>Porta-voz</h3>
                  <span>Selecione um porta-voz.</span>
                </div>
                <Selector.Default
                  options={reportSettings.spokepeople}
                  placeholder="Porta-voz"
                  {...spokespersonBinder}
                  $fit
                />
              </SpokespeopleSelectorContainer>
            )}

            <ButtonsGroup>
              <Button.Root
                $variant="outlined"
                style={{
                  alignSelf: 'flex-start',
                }}
                onClick={() => navigate(Routes.CompanyDetails, companyID!)}>
                <Button.Text value="Voltar" />
              </Button.Root>
              <div>
                {currentSlide > 1 && (
                  <Button.Root
                    $variant="outlined"
                    onClick={() =>
                      changeQueryParam({
                        param: 'slide',
                        value: String(currentSlide - 1),
                      })
                    }>
                    <Button.Text value="Slide anterior" />
                  </Button.Root>
                )}
                {currentSlide < (reportSettings?.slidesCount ?? 0) && (
                  <Button.Root
                    $variant="outlined"
                    onClick={() =>
                      changeQueryParam({
                        param: 'slide',
                        value: String(currentSlide + 1),
                      })
                    }>
                    <Button.Text value="Próximo slide" />
                  </Button.Root>
                )}
                <Button.Root $variant="primary" $loading={updatingReportSettings} onClick={save}>
                  <Button.Text value="Salvar" />
                </Button.Root>
              </div>
            </ButtonsGroup>
          </Flex>
        }
      </Layout.Padder>
    </Layout.Root>
  )
}
