import styled from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const ThemeTableContainer = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: ${Tokens.RadiusInput};
  background-color: ${Tokens.ColorWhite};
`
