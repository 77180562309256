import { memo } from 'react'

import { MakerResponse } from '@shared/models'

import { Button, Divider } from '@/components'
import { ColumnType, Table } from '@/components/derived'
import { SettingConfig } from '@icon-park/react'
import { useRouting } from '@/hooks'
import { Routes } from '@/configs'

export const MakerTable = memo(Component)

type Props = {
  elements: MakerResponse[]
}

function Component({ elements }: Props): JSX.Element | null {
  const { companyID, navigate } = useRouting()

  function handleClick(item: MakerResponse): void {
    navigate(
      [
        `${Routes.ReportSettings.path}`,
        `?relatorio=${item.collectionID}`,
        `&periodicidade=${item.monitoringType}`,
        `&dataSource=${item.dataSourceType}`,
      ].join(''),
      companyID!,
    )
  }

  const makerColumns: ColumnType<MakerResponse>[] = [
    {
      key: 'tier',
      title: 'Tier',
      render: (_, maker) => {
        return maker.tier.replace(/TIER-(\d+|S)/, (_, p1) => {
          return `Tier ${p1}`
        })
      },
    },
    {
      key: 'monitoring_type_monthly',
      title: 'Periodicidade',
      render: (_, maker) => {
        return maker.monitoringType === 'daily' ? 'Diário' : 'Mensal'
      },
    },
    {
      key: 'source_type_press',
      title: 'Fonte de dados',
      render: (_, maker) => {
        return maker.dataSourceType === 'socialNetwork' ? 'Redes' : 'Imprensa'
      },
    },
    {
      key: 'magicLink',
      render: (_, maker) => (
        <Button.Root $variant="link" onClick={() => handleClick(maker)}>
          <Button.Icon value={SettingConfig} />
          <Button.Text value="Editar" />
        </Button.Root>
      ),
    },
  ]

  if (!companyID || !elements || !elements.length) return null

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Template" />
      </Divider.Root>
      <Table data={elements} columns={makerColumns} />
    </>
  )
}
