import { useEffect, useState } from 'react'

export type SelectionOption = {
  text: string
  value: string | number
}

export type SelectionOutput = {
  binder: SelectionBinder
  selectedOption?: SelectionOption
  reset: (option?: SelectionOption) => void
}

export interface SelectionBinder {
  selectedOption?: SelectionOption
  onSelect: (item?: SelectionOption) => void
}

export function useSelectionBinder(defaultOption?: SelectionOption): SelectionOutput {
  const [selectedOption, setSelectedOption] = useState<SelectionOption | undefined>(defaultOption)

  useEffect(() => {
    if (defaultOption) {
      setSelectedOption(defaultOption)
    }
  }, [defaultOption])

  function onSelect(option?: SelectionOption): void {
    setSelectedOption(option)
  }

  function reset(option?: SelectionOption): void {
    setSelectedOption(option ?? defaultOption)
  }

  const bind: SelectionBinder = { selectedOption: selectedOption, onSelect }

  return {
    binder: bind,
    selectedOption,
    reset,
  }
}
