import { useState } from 'react'

export type Item = {
  text: string
  value: string | number
}

export type SearchFunc = (searchParam: string) => void

export interface Binder {
  onSearch: SearchFunc
  onChange: (changed: Item[]) => void
}

export type BindOutput = {
  binder: Binder
  selectedOptions: Item[]
}

export function useSelectedList(onSearch: SearchFunc): BindOutput {
  const [selectedOptions, setSelectedOptions] = useState<Item[]>([])

  function onChange(changes: Item[]): void {
    setSelectedOptions(changes)
  }

  const binder: Binder = { onSearch, onChange }

  return {
    binder,
    selectedOptions,
  }
}
