import { memo, useEffect } from 'react'

import { DeleteFive } from '@icon-park/react'

import { Tokens } from '@/assets/tokens'

import { Alert, Api, Confirmation } from '@/modules'

import { Button, Divider, Flex } from '@/components'
import { ColumnType, Table } from '@/components/derived'

import { useRouting } from '@/hooks'
import { getFromToElements } from '@/utils/pagination'
import { useCompanyContext } from '../CompanyDetails.context'

interface TableContent {
  id: string
  name: string
  pj: string
}

export const SpokespersonsTable = memo(Component)

function Component() {
  const { currentPage } = useRouting()
  const { isLoadingSpokespeople, spokespeople, reloadSpokesPeople } = useCompanyContext()

  const { sendAlert } = Alert.Hook()
  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    title: 'Você tem certeza?',
    text: 'Esta ação não tem como ser desfeita. Todos os valores associados à esse porta-voz serão perdidos',
    confirmText: 'Sim, Apagar Porta-voz!',
  })

  const { isRunning: isDeleting, run: deleteSpokesperson } = Api.Command<void>(Api.Methods.DELETE, 'spokespersons/:id')

  useEffect(() => {
    reloadSpokesPeople(currentPage)
  }, [currentPage])

  function onConfirmationAccepted(id: string) {
    deleteSpokesperson<void>({
      binds: [['id', id]],
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Exclusão do porta-voz',
          message: 'Porta-voz excluído com sucesso',
        })
        reloadSpokesPeople(currentPage)
      },
    })
  }

  const columns: ColumnType<TableContent>[] = [
    {
      key: 'name',
      title: 'Nome',
    },
    {
      key: 'pj',
      title: 'PJ',
      width: 50,
    },
    {
      key: 'options',
      title: 'Opções',
      width: 50,
      render: (_, item) => (
        <Flex $type="row" $justify="flex-start" $align="stretch" $gap={24}>
          <Button.Root
            $variant="link"
            style={{ color: Tokens.ColorUIDanger }}
            onClick={() => openConfirmation(() => onConfirmationAccepted(item.id))}
            $loading={isDeleting}>
            Excluir
          </Button.Root>
        </Flex>
      ),
    },
  ]

  const sanitizedSpokespersons = spokespeople?.elements.map(
    (item): TableContent => ({
      id: item.id,
      name: item.name,
      pj: item.pj ? 'Sim' : 'Não',
    }),
  )

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Porta-vozes registrados" />
        <Divider.Spacer />
        <Divider.Subtitle
          value={`${getFromToElements(currentPage, spokespeople?.elements.length ?? 0)}/${spokespeople?.total ?? 0} Entrada(s)`}
        />
      </Divider.Root>
      <Table
        isLoading={isLoadingSpokespeople}
        columns={columns}
        data={sanitizedSpokespersons}
        totalPages={spokespeople?.pages}
      />
    </>
  )
}
