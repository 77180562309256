import { Tokens } from '@/assets/tokens'
import { Button, ComboBox, Flex } from '@/components'
import {
  BookmarkOne,
  Calendar,
  Comments,
  Down,
  Earth,
  People,
  SmilingFace,
  SpeakerOne,
  Star,
  Tag,
  Time,
  Up,
} from '@icon-park/react'
import { useEffect, useState } from 'react'
import { Icon } from '../../../../../src/components/core/Icon/Icon.component'
import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
  AccordionIcon,
  AccordionItemContainer,
  CustomInput,
  CustomInputWrapper,
} from '../Accordion/Accordion.styles'
import { fillEndPoint } from '../utils/fillEndPoint'
import { getFromApi } from '../utils/getFromApi'
import { useValues } from '../ValuesContext'
import { FilterGroup } from './Filters.styles'
import { ComboBoxItem } from './useComboBox.ts'
import { isAnyDashLoading } from '../dashes/utils.tsx'
import { SkeletonLoader } from '@/components/derived/index.ts'
import { useTimeInput } from '@/hooks/useInput.ts'

export function Filters() {
  const apiHost = process.env.REACT_APP_DASH_API_HOST

  // const [openComboBox, setOpenComboBox] = useState<string | null>(null)

  // function handleComboBoxOpen(key: string) {
  //   setOpenComboBox((prevKey) => (prevKey === key ? null : key))
  // }

  const initialOptions: ComboBoxItem[] = [
    {
      value: '',
      text: 'Carregando...',
      id: undefined,
    },
  ]

  const { values, updateValues }: any = useValues()

  const [isAnyDashLoadingAux, setIsAnyDashLoadingAux]: any = useState(true)

  const [kValuePlaceHolder, setKValuePlaceHolder] = useState('10')

  const [polarityOptions, setPolarityOptions] = useState<ComboBoxItem[]>(initialOptions)
  const [polarityPlaceHolder, setPolarityPlaceHolder] = useState('Polaridade')

  const [themeOptions, setThemeOptions] = useState<ComboBoxItem[]>(initialOptions)
  const [themePlaceHolder, setThemePlaceHolder] = useState('Tema')

  const [publisherOptions, setPublisherOptions] = useState<ComboBoxItem[]>(initialOptions)
  const [publisherPlaceHolder, setPublisherPlaceHolder] = useState('Publicador')

  const [channelOptions, setChannelOptions] = useState<ComboBoxItem[]>(initialOptions)
  const [channelPlaceHolder, setChannelPlaceHolder] = useState('Canal')

  const [roleOptions, setRoleOptions] = useState<ComboBoxItem[]>(initialOptions)
  const [rolePlaceHolder, setRolePlaceHolder] = useState('Papel')

  const [dataSourceOptions, setDataSourceOptions] = useState<ComboBoxItem[]>(initialOptions)
  const [dataSourcePlaceHolder, setDataSourcePlaceHolder] = useState('Fonte')

  const [groupThemeOptions, setGroupThemeOptions] = useState<ComboBoxItem[]>(initialOptions)
  const [groupThemePlaceHolder, setGroupThemePlaceHolder] = useState('Grupo')

  const [brandOptions, setBrandOptions] = useState<ComboBoxItem[]>(initialOptions)
  const [brandPlaceHolder, setBrandPlaceHolder] = useState('Marca')

  const [constraintOptions, setConstraintOptions] = useState<ComboBoxItem[]>(initialOptions)
  const [constraintPlaceHolder, setConstraintPlaceHolder] = useState('Porta Voz')

  const [infDatePlaceHolder, setInfDatePlaceHolder] = useState('2023')
  const [supDatePlaceHolder, setSupDatePlaceHolder] = useState('2023')

  const { binder: startTimeBinder, value: startTime, reset: setInfTimePlaceHolder } = useTimeInput()
  const { binder: endTimeBinder, value: endTime, reset: setSupTimePlaceHolder } = useTimeInput()

  const [isOpen, setIsOpen] = useState(false)
  const [filterExpandText, setFilterExpandText] = useState('Mais')

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
    if (filterExpandText === 'Mais') {
      setFilterExpandText('Menos')
    } else {
      setFilterExpandText('Mais')
    }
  }

  function capitalizeDescription(text: string): string {
    return text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
  }

  //Função para verificar se existe componente spinner no dashboard, se existir, desabilita filtro
  useEffect(() => {
    const checkForSpinner = () => {
      setIsAnyDashLoadingAux(isAnyDashLoading())
    }

    checkForSpinner()

    const intervalId = setInterval(checkForSpinner, 1000)

    return () => clearInterval(intervalId)
  }, [])

  //Extrair parametros da url para values
  useEffect(() => {
    const regexTtuid = /empresas\/([^/]+)\/dashboards/
    const matchTtuid = window.location.hash.match(regexTtuid)
    const ttuid = matchTtuid ? matchTtuid[1] : null

    getFromApi(`${apiHost}recoveryIDCompany?tuuid=${ttuid}`).then((elements) => {
      updateValues({ idEmpresaIN: elements[0].idempresain })
      // updateValues({ idEmpresaIN: '400' })
    })

    const regex = /\?(.*)/
    const href = window.location.hash
    const match: any = href.match(regex)
    // Tenta ver se existe parametros na url para atualizar values
    try {
      const queryString = match[1]
      const pairs = queryString.split('&')

      const result = pairs.reduce((acc: any, pair: any) => {
        const [key, value] = pair.split('=')
        acc[key] = value
        return acc
      }, {})
      if (Object.keys(result).length > 0) {
        updateValues(result)
      }
    } catch (e) {
      // Caso não tiver nada na url, quer dizer que values está vazio, preenche com infos padrões
      const today = new Date()
      updateValues({
        diaInfIN: '',
        mesInfIN: today.getMonth() + 1,
        anoInfIN: today.getFullYear(),
        anoSupIN: today.getFullYear(),
        mesSupIN: today.getMonth() + 1,
        idTemaIN: '',
        idPublicadorIN: '',
        idCanalIN: '',
        idPapelIN: '',
        idDataSourceIN: '',
        grupoTemaIN: '',
        idMarcaIN: '',
        idConstraintIN: '',
        k: '',
        horahinfin: undefined,
        horahsupin: undefined,
      })
    }
  }, [])

  //Atualiza os placeholders conforme values/url
  useEffect(() => {
    const placeholderMap: Record<string, { options: ComboBoxItem[]; setPlaceholder: any; defaultText: string }> = {
      idPolaridadeIN: {
        options: polarityOptions,
        setPlaceholder: setPolarityPlaceHolder,
        defaultText: 'Polaridade',
      },
      idTemaIN: {
        options: themeOptions,
        setPlaceholder: setThemePlaceHolder,
        defaultText: 'Tema',
      },
      idPublicadorIN: {
        options: publisherOptions,
        setPlaceholder: setPublisherPlaceHolder,
        defaultText: 'Publicador',
      },
      idCanalIN: {
        options: channelOptions,
        setPlaceholder: setChannelPlaceHolder,
        defaultText: 'Canal',
      },
      idPapelIN: {
        options: roleOptions,
        setPlaceholder: setRolePlaceHolder,
        defaultText: 'Papel',
      },
      idDataSourceIN: {
        options: dataSourceOptions,
        setPlaceholder: setDataSourcePlaceHolder,
        defaultText: 'Fonte',
      },
      grupoTemaIN: {
        options: groupThemeOptions,
        setPlaceholder: setGroupThemePlaceHolder,
        defaultText: 'Grupo',
      },
      idMarcaIN: {
        options: brandOptions,
        setPlaceholder: setBrandPlaceHolder,
        defaultText: 'Marca',
      },
      idConstraintIN: {
        options: constraintOptions,
        setPlaceholder: setConstraintPlaceHolder,
        defaultText: 'Porta-Voz',
      },
    }

    Object.keys(placeholderMap).forEach((key) => {
      const { options, setPlaceholder, defaultText } = placeholderMap[key]
      if (values[key]) {
        const selectedOption = options.find((option: any) => Number(option.value) === Number(values[key]))
        setPlaceholder(selectedOption ? selectedOption.text : defaultText)
      } else {
        setPlaceholder(defaultText)
      }
    })

    const dataInfFormated = formatDate(values.diaInfIN, values.mesInfIN, values.anoInfIN)
    setInfDatePlaceHolder(dataInfFormated)

    const dataSupFormated = formatDate(values.diaSupIN, values.mesSupIN, values.anoSupIN)
    setSupDatePlaceHolder(dataSupFormated)
  }, [
    values,
    polarityOptions,
    themeOptions,
    publisherOptions,
    channelOptions,
    roleOptions,
    dataSourceOptions,
    groupThemeOptions,
    constraintOptions,
    brandOptions,
  ])

  function formatDate(day: string, month: string, year: string): string {
    if (day && month && year) {
      return `${day}/${month}/${year}`
    } else if (month && year) {
      return `${month}/${year}`
    } else if (year) {
      return `${year}`
    }
    return ''
  }

  //Atualizar a url para os valores de values
  useEffect(() => {
    if (Object.keys(values).length > 0) {
      const urlParams = new URLSearchParams()

      Object.keys(values).forEach((key) => {
        if (values[key]) {
          if (key != 'idEmpresaIN') {
            const decodedValue = decodeURIComponent(values[key])
            urlParams.set(key, decodedValue)
          }
        }
      })

      const currentUrl = window.location.href.split('?')[0]
      const newUrl = `${currentUrl}?${urlParams.toString()}`

      // Atualiza a URL sem recarregar a página
      if (newUrl !== window.location.href) {
        window.history.replaceState(null, '', newUrl)
      }
    }
  }, [values])

  //Preenche opções da caixa

  const getUrlPolarity: any = fillEndPoint(`${apiHost}recoveryPolarity`, values)
  const getUrlTheme: any = fillEndPoint(`${apiHost}recoveryTheme`, values)
  const getUrlPublisher: any = fillEndPoint(`${apiHost}recoveryPublisher`, values)
  const getUrlChannel: any = fillEndPoint(`${apiHost}recoveryChannel`, values)
  const getUrlRole: any = fillEndPoint(`${apiHost}recoveryRole`, values)
  const getUrlDataSource: any = fillEndPoint(`${apiHost}recoveryDataSource`, values)
  const getUrlGroupTheme: any = fillEndPoint(`${apiHost}recoveryGroupTheme`, values)
  const getUrlBrand: any = fillEndPoint(`${apiHost}recoveryBrand`, values)
  const getUrlConstraint: any = fillEndPoint(`${apiHost}recoveryConstraint`, values)

  useEffect(() => {
    if (Object.keys(values).length === 0 || !values.idEmpresaIN || values.k) {
      return
    }

    populateOptions(
      getUrlPolarity,
      setPolarityOptions,
      'idpolaridade',
      'polaridade',
      'idPolaridadeIN',
      capitalizeDescription,
    )
    populateOptions(getUrlTheme, setThemeOptions, 'idtema', 'tema', 'idTemaIN', capitalizeDescription)
    populateOptions(
      getUrlPublisher,
      setPublisherOptions,
      'idpublicador',
      'publicador',
      'idPublicadorIN',
      capitalizeDescription,
    )
    populateOptions(getUrlChannel, setChannelOptions, 'idcanal', 'canal', 'idCanalIN', capitalizeDescription)
    populateOptions(getUrlRole, setRoleOptions, 'idpapel', 'papel', 'idPapelIN', capitalizeDescription)
    populateOptions(
      getUrlDataSource,
      setDataSourceOptions,
      'iddatasource',
      'datasource',
      'idDataSourceIN',
      capitalizeDescription,
    )
    populateOptions(
      getUrlGroupTheme,
      setGroupThemeOptions,
      'idgrupotema',
      'grupotema',
      'grupoTemaIN',
      capitalizeDescription,
    )
    populateOptions(getUrlBrand, setBrandOptions, 'idmarca', 'marca', 'idMarcaIN', capitalizeDescription)
    populateOptions(
      getUrlConstraint,
      setConstraintOptions,
      'idconstraint',
      'portavoz',
      'idConstraintIN',
      capitalizeDescription,
    )
  }, [values])

  function populateOptions(
    url: string,
    setOptions: any,
    valueKey: string,
    descriptionKey: string,
    idKey: string,
    formatFunction: any,
  ) {
    getFromApi(url).then((elements: any) => {
      const formattedOptions = elements.map((element: any) => ({
        value: element[valueKey],
        text: formatFunction(element[descriptionKey]),
        id: idKey,
      }))
      setOptions(formattedOptions)
    })
  }

  function handleCompanySelection(element: ComboBoxItem): void {
    const placeholderMap: Record<string, any> = {
      idPolaridadeIN: setPolarityPlaceHolder,
      idTemaIN: setThemePlaceHolder,
      idPublicadorIN: setPublisherPlaceHolder,
      idCanalIN: setChannelPlaceHolder,
      idPapelIN: setRolePlaceHolder,
      idDataSourceIN: setDataSourcePlaceHolder,
      grupoTemaIN: setGroupThemePlaceHolder,
      idMarcaIN: setBrandPlaceHolder,
      idConstraintIN: setConstraintPlaceHolder,
    }

    const id: any = element.id

    const updatePlaceholder = placeholderMap[id]
    if (updatePlaceholder) {
      updatePlaceholder(element.text)
      if (id === 'grupoTemaIN') {
        const auxValue = values
        auxValue.grupoTemaIN = element.value
        const getUrlTheme: any = fillEndPoint(`${apiHost}recoveryTheme`, auxValue)
        populateOptions(getUrlTheme, setThemeOptions, 'idtema', 'tema', 'idTemaIN', capitalizeDescription)
      }
      if (id === 'idMarcaIN') {
        const auxValue = values
        auxValue.idMarcaIN = element.value
        const getUrlTheme: any = fillEndPoint(`${apiHost}recoveryTheme`, auxValue)
        populateOptions(getUrlTheme, setThemeOptions, 'idtema', 'tema', 'idTemaIN', capitalizeDescription)
      }
    }
  }

  function parseDate(dateString: any) {
    const parts = dateString.split('/').map(Number)

    let day, month, year

    if (parts.length === 3) {
      // Se a string tem dois '/', então é dia/mes/ano
      ;[day, month, year] = parts
    } else if (parts.length === 2) {
      // Se a string tem um '/', então é mes/ano
      day = ''
      ;[month, year] = parts
    } else if (parts.length === 1) {
      // Se a string não tem '/', então é apenas ano
      day = ''
      month = ''
      year = parts[0]
    }

    return [day, month, year]
  }

  function handleFilterClick() {
    const placeholderMap: Record<string, { placeholder: string; options: ComboBoxItem[]; id: string }> = {
      Polaridade: {
        placeholder: polarityPlaceHolder,
        options: polarityOptions,
        id: 'idPolaridadeIN',
      },
      Tema: {
        placeholder: themePlaceHolder,
        options: themeOptions,
        id: 'idTemaIN',
      },
      Publicador: {
        placeholder: publisherPlaceHolder,
        options: publisherOptions,
        id: 'idPublicadorIN',
      },
      Canal: {
        placeholder: channelPlaceHolder,
        options: channelOptions,
        id: 'idCanalIN',
      },
      Papel: {
        placeholder: rolePlaceHolder,
        options: roleOptions,
        id: 'idPapelIN',
      },
      Fonte: {
        placeholder: dataSourcePlaceHolder,
        options: dataSourceOptions,
        id: 'idDataSourceIN',
      },
      Grupo: {
        placeholder: groupThemePlaceHolder,
        options: groupThemeOptions,
        id: 'grupoTemaIN',
      },
      Marca: {
        placeholder: brandPlaceHolder,
        options: brandOptions,
        id: 'idMarcaIN',
      },
      'Porta Voz': {
        placeholder: constraintPlaceHolder,
        options: constraintOptions,
        id: 'idConstraintIN',
      },
      horahinfin: {
        placeholder: startTimeBinder.value,
        options: [],
        id: 'horahinfin',
      },
      horahsupin: {
        placeholder: endTimeBinder.value,
        options: [],
        id: 'horahsupin',
      },
    }

    Object.keys(placeholderMap).forEach((key) => {
      const { placeholder, options, id } = placeholderMap[key]
      if (placeholder !== key) {
        const selectedOption = options.find((option: any) => option.text === placeholder)
        if (selectedOption) {
          updateValues({ [id]: selectedOption.value })
        }
      }
    })

    const datePattern = /^(?:\d{1,2}\/\d{1,2}\/\d{4}|\d{1,2}\/\d{4}|\d{4}|\d{1,2}\/\d{1,2}|\d{1,2})$/

    let infDateFormated: string = ''

    if (values.diaInfIN && values.mesInfIN && values.anoInfIN) {
      infDateFormated = `${values.diaInfIN}/${values.mesInfIN}/${values.anoInfIN}`
    } else if (values.mesInfIN && values.anoInfIN) {
      infDateFormated = `${values.mesInfIN}/${values.anoInfIN}`
    } else if (values.anoInfIN) {
      infDateFormated = `${values.anoInfIN}`
    }

    if (infDateFormated !== infDatePlaceHolder) {
      if (datePattern.test(infDatePlaceHolder)) {
        const sepDate = parseDate(infDatePlaceHolder)
        updateValues({
          diaInfIN: sepDate[0],
          mesInfIN: sepDate[1],
          anoInfIN: sepDate[2],
        })
      }
    }

    let supDateFormated: string = ''

    if (values.diaSupIN && values.mesSupIN && values.anoSupIN) {
      supDateFormated = `${values.diaSupIN}/${values.mesSupIN}/${values.anoSupIN}`
    } else if (values.mesSupIN && values.anoSupIN) {
      supDateFormated = `${values.mesSupIN}/${values.anoSupIN}`
    } else if (values.anoSupIN) {
      supDateFormated = `${values.anoSupIN}`
    }

    if (supDateFormated !== supDatePlaceHolder) {
      if (datePattern.test(supDatePlaceHolder)) {
        const sepDate = parseDate(supDatePlaceHolder)
        updateValues({
          diaSupIN: sepDate[0],
          mesSupIN: sepDate[1],
          anoSupIN: sepDate[2],
        })
      }
    }

    if (values.k !== kValuePlaceHolder) {
      updateValues({ k: kValuePlaceHolder })
    }

    if (values.k !== kValuePlaceHolder) {
      updateValues({ k: kValuePlaceHolder })
    }

    if (values.horahinfin !== startTime) {
      updateValues({ horahinfin: startTime })
    }

    if (values.horahsupin !== endTime) {
      updateValues({ horahsupin: endTime })
    }
  }

  function handleFilterClickClean() {
    if (isAnyDashLoading()) {
      return
    }

    setInfTimePlaceHolder(undefined)
    setSupTimePlaceHolder(undefined)

    const today = new Date()
    updateValues({
      diaInfIN: '',
      mesInfIN: today.getMonth() + 1,
      anoInfIN: today.getFullYear(),
      diaSupIN: '',
      mesSupIN: today.getMonth() + 1,
      anoSupIN: today.getFullYear(),
      idTemaIN: '',
      idPublicadorIN: '',
      idCanalIN: '',
      idPapelIN: '',
      idDataSourceIN: '',
      grupoTemaIN: '',
      idMarcaIN: '',
      idConstraintIN: '',
      idPolaridadeIN: '',
      k: '',
      horahinfin: undefined,
      horahsupin: undefined,
    })
  }

  useEffect(() => {
    const dateElement = document.querySelector('.dateInf')

    if (dateElement) {
      const inputElement = dateElement.querySelector('input')
      if (inputElement) {
        inputElement.value = infDatePlaceHolder
      }
    }
  }, [infDatePlaceHolder, isAnyDashLoadingAux])

  useEffect(() => {
    const dateElement = document.querySelector('.dateSup')

    if (dateElement) {
      const inputElement = dateElement.querySelector('input')
      if (inputElement) {
        inputElement.value = supDatePlaceHolder
      }
    }
  }, [supDatePlaceHolder, isAnyDashLoadingAux])

  useEffect(() => {
    const dateElement = document.querySelector('.kValue')

    if (dateElement) {
      const inputElement = dateElement.querySelector('input')
      if (inputElement) {
        inputElement.value = kValuePlaceHolder
      }
    }
  }, [kValuePlaceHolder])

  useEffect(() => {
    const dateElement = document.querySelector('.kValue')

    if (dateElement) {
      const inputElement = dateElement.querySelector('input')
      if (inputElement) {
        inputElement.value = kValuePlaceHolder
      }
    }
  }, [kValuePlaceHolder])

  return (
    <div>
      {isAnyDashLoadingAux ?
        <SkeletonLoader amount={1} />
      : <FilterGroup>
          <AccordionContainer>
            <AccordionItemContainer>
              {/* Cabeçalho do Acordeão com Filtros principais */}
              <AccordionHeader role="button" style={{ flexDirection: 'column', cursor: 'default' }}>
                <div
                  style={{
                    width: '99.5%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  Filtros
                  <div
                    style={{
                      marginLeft: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}>
                    <button
                      onClick={handleFilterClickClean}
                      style={{
                        color: '#C7C7C7',
                        cursor: 'pointer',
                        background: 'none',
                        border: 'none',
                        outline: 'none',
                      }}>
                      Limpar Filtros
                    </button>
                    <button
                      onClick={toggleAccordion}
                      style={{
                        display: 'flex',
                        gap: '2px',
                        cursor: 'pointer',
                        color: '#C7C7C7',
                        background: 'none',
                        border: 'none',
                        outline: 'none',
                      }}>
                      <span style={{ color: '#01c38d' }}>{filterExpandText}</span>
                      <AccordionIcon style={{ fill: '#01c38d', marginTop: '5px' }}>
                        {isOpen ?
                          <Icon value={Up} color="#01c38d"></Icon>
                        : <Icon value={Down} color="#01c38d"></Icon>}
                      </AccordionIcon>
                    </button>
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    width: '100%',
                  }}>
                  <ComboBox.Root
                    className="dateInf"
                    placeholder={''}
                    title="Início"
                    options={[]}
                    onSelect={handleCompanySelection}
                    onSearch={(value) => setInfDatePlaceHolder(value)}
                    $variant>
                    <ComboBox.Icon value={Calendar} color={Tokens.ColorUIPrimary} />
                  </ComboBox.Root>

                  <ComboBox.Root
                    className="dateSup"
                    placeholder={''}
                    title="Fim"
                    options={[]}
                    onSearch={(value) => setSupDatePlaceHolder(value)}
                    onSelect={handleCompanySelection}
                    $variant>
                    <ComboBox.Icon value={Calendar} color={Tokens.ColorUIPrimary} />
                  </ComboBox.Root>

                  <ComboBox.Root
                    loading={polarityOptions.length > 0 && polarityOptions[0].text === 'Carregando...'}
                    key={polarityPlaceHolder}
                    placeholder={polarityPlaceHolder}
                    title="Polaridade"
                    options={polarityOptions}
                    onSelect={(value: any) => handleCompanySelection(value)}
                    $variant>
                    <ComboBox.Icon value={SmilingFace} color={Tokens.ColorUIPrimary} />
                  </ComboBox.Root>

                  <ComboBox.Root
                    loading={brandOptions.length > 0 && brandOptions[0].text === 'Carregando...'}
                    key={brandPlaceHolder}
                    placeholder={brandPlaceHolder}
                    title="Marca"
                    options={brandOptions}
                    onSelect={(value: any) => handleCompanySelection(value)}
                    $variant>
                    <ComboBox.Icon value={Star} color={Tokens.ColorUIPrimary} />
                  </ComboBox.Root>

                  <ComboBox.Root
                    loading={channelOptions.length > 0 && channelOptions[0].text === 'Carregando...'}
                    key={channelPlaceHolder}
                    placeholder={channelPlaceHolder}
                    title="Canal"
                    options={channelOptions}
                    onSelect={(value: any) => handleCompanySelection(value)}
                    $variant>
                    <ComboBox.Icon value={Earth} color={Tokens.ColorUIPrimary} />
                  </ComboBox.Root>

                  <Button.Root
                    $variant="primary"
                    onClick={handleFilterClick}
                    style={{ width: '120px' }}
                    $loading={isAnyDashLoadingAux}>
                    <Button.Text value="Filtrar" />
                  </Button.Root>
                </div>
              </AccordionHeader>

              {/* Conteúdo do Acordeão com Filtros adicionais em linha */}
              {isOpen && (
                <AccordionContent
                  $isOpen={isOpen}
                  style={{
                    padding: '4px 0px',
                    border: 'none',
                    overflow: 'visible',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      margin: 'auto',
                      width: '97%',
                    }}>
                    <ComboBox.Root
                      loading={roleOptions.length > 0 && roleOptions[0].text === 'Carregando...'}
                      key={rolePlaceHolder}
                      placeholder={rolePlaceHolder}
                      title="Papel"
                      options={roleOptions}
                      onSelect={(value: any) => handleCompanySelection(value)}
                      $variant>
                      <ComboBox.Icon value={Star} color={Tokens.ColorUIPrimary} />
                    </ComboBox.Root>
                    <ComboBox.Root
                      loading={groupThemeOptions.length > 0 && groupThemeOptions[0].text === 'Carregando...'}
                      key={groupThemePlaceHolder}
                      placeholder={groupThemePlaceHolder}
                      title="Grupo"
                      options={groupThemeOptions}
                      onSelect={(value: any) => handleCompanySelection(value)}
                      $variant>
                      <ComboBox.Icon value={BookmarkOne} color={Tokens.ColorUIPrimary} />
                    </ComboBox.Root>

                    <ComboBox.Root
                      loading={themeOptions.length > 0 && themeOptions[0].text === 'Carregando...'}
                      key={themePlaceHolder}
                      placeholder={themePlaceHolder}
                      title="Tema"
                      options={themeOptions}
                      onSelect={(value: any) => handleCompanySelection(value)}
                      $variant>
                      <ComboBox.Icon value={Tag} color={Tokens.ColorUIPrimary} />
                    </ComboBox.Root>

                    <ComboBox.Root
                      loading={constraintOptions.length > 0 && constraintOptions[0].text === 'Carregando...'}
                      key={constraintPlaceHolder}
                      placeholder={constraintPlaceHolder}
                      title="Porta-Voz"
                      options={constraintOptions}
                      onSelect={(value: any) => handleCompanySelection(value)}
                      $variant>
                      <ComboBox.Icon value={People} color={Tokens.ColorUIPrimary} />
                    </ComboBox.Root>

                    {values.k && (
                      <ComboBox.Root
                        className="kValue"
                        placeholder={'Qtd Menções'}
                        title="Qtd Menções"
                        options={[]}
                        onSelect={() => {}}
                        onSearch={(value) => {
                          setKValuePlaceHolder(value)
                        }}
                        $variant>
                        <ComboBox.Icon value={Comments} color={Tokens.ColorUIPrimary} />
                      </ComboBox.Root>
                    )}
                  </div>
                </AccordionContent>
              )}
              {isOpen && (
                <AccordionContent
                  $isOpen={isOpen}
                  style={{
                    padding: '10px 0px',
                    border: 'none',
                    overflow: 'visible',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      margin: 'auto',
                      width: '97%',
                    }}>
                    <ComboBox.Root
                      loading={publisherOptions.length > 0 && publisherOptions[0].text === 'Carregando...'}
                      key={publisherPlaceHolder}
                      placeholder={publisherPlaceHolder}
                      title="Publicador"
                      options={publisherOptions}
                      onSelect={(value: any) => handleCompanySelection(value)}
                      $variant>
                      <ComboBox.Icon value={People} color={Tokens.ColorUIPrimary} />
                    </ComboBox.Root>

                    <ComboBox.Root
                      loading={dataSourceOptions.length > 0 && dataSourceOptions[0].text === 'Carregando...'}
                      key={dataSourcePlaceHolder}
                      placeholder={dataSourcePlaceHolder}
                      title="Fonte"
                      options={dataSourceOptions}
                      onSelect={(value: any) => handleCompanySelection(value)}
                      $variant>
                      <ComboBox.Icon value={SpeakerOne} color={Tokens.ColorUIPrimary} />
                    </ComboBox.Root>
                    <Flex $type="row" $justify="space-between" $align="center" $gap={16} $fit>
                      <CustomInputWrapper>
                        <CustomInput icon={Time} placeholder="Início" {...startTimeBinder} />
                      </CustomInputWrapper>
                      <CustomInputWrapper>
                        <CustomInput icon={Time} placeholder="Fim" {...endTimeBinder} />
                      </CustomInputWrapper>
                    </Flex>
                  </div>
                </AccordionContent>
              )}
            </AccordionItemContainer>
          </AccordionContainer>
        </FilterGroup>
      }
    </div>
  )
}
