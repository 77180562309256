import styled from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const DetailsContainer = styled.section`
  height: fit-content;
  border-radius: 1.25rem;
  padding: 2.5rem 2.5rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  background-color: ${Tokens.ColorWhite};
`

export const GovernmentalFlag = styled.span`
  &::before {
    content: 'Governamental';
  }

  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1rem;
  border-radius: ${Tokens.RadiusInput};
  background-color: ${Tokens.ColorUIWarn};
  color: ${Tokens.ColorWhite};
  font-weight: bold;
`

export const CompetitorFlag = styled.span`
  &::before {
    content: 'Retornar para empresa monitorada';
  }

  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1rem;
  border-radius: ${Tokens.RadiusInput};
  background-color: ${Tokens.ColorUIWarnDim};
  color: ${Tokens.ColorWhite};
  font-weight: bold;

  cursor: pointer;
`

export const KeywordsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`

export const Keyword = styled.span`
  padding: 0.5rem;
  border-radius: 0.5rem;

  background-color: ${Tokens.ColorUIPrimary}40;
  color: ${Tokens.ColorUIPrimary};
  font-weight: 600;
`

export const LogoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 1.5rem;
  overflow: hidden;
`

export const LogoImage = styled.img`
  height: 7rem;
`

export const NoLogo = styled.div`
  width: 240px;
  height: 240px;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${Tokens.ColorBackground};

  & > span {
    color: ${Tokens.ColorUIPrimary};
  }
`

export const Logo = styled.img`
  width: 240px;
  height: 240px;
  border-radius: 20px;
`

export const MakerSection = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 1rem;

  background-color: ${Tokens.ColorWhite};
  border-radius: 1.2rem;
  padding: 1.4rem 2rem;
`
