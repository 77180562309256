import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { useCallback } from 'react'

import { Alert } from '@/modules'

import { Env, Routes } from '@/configs'

import { PromisedResult, toAsyncResult } from '@/utils/result'
import { useRouting } from '@/hooks'

type AuthAPICaller = <T>(config: AxiosRequestConfig) => PromisedResult<T>

export function useAuthAPI(): AuthAPICaller {
  const baseURL = process.env[Env.REACT_APP_API_URL] ?? 'https://localhost:8080'
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })
  const { route } = useRouting()
  const { sendAlert } = Alert.Hook()

  const caller = useCallback(async <T>(config: AxiosRequestConfig): PromisedResult<T> => {
    const [error, response] = await toAsyncResult(instance(config))
    if (error) {
      if (Routes.Welcome.key === route.key) return [error]
      if (axios.isAxiosError(error)) {
        const response = error.response!
        switch (response.status) {
          case 400:
            sendAlert({
              type: 'error',
              title: 'Requisição inválida',
              message: 'A requisição enviada é inválida, verifique-a e tente novamente!',
            })
            break
          case 401:
            if (response.data?.message === 'cookie error') {
              if (route.key === Routes.Login.key) return [error]
              sendAlert({
                type: 'error',
                title: 'Erro de autenticação',
                message: 'Credenciais inválidas, efetue um novo login e tente novamente!',
              })
            } else if (response.data.message === 'missing token error') {
              return [error]
            } else {
              sendAlert({
                type: 'error',
                title: 'Erro de autenticação',
                message: 'Usuário não cadastrado, verifique o e-mail e tente novamente!',
              })
            }
            break
          default:
            sendAlert({
              type: 'error',
              title: 'Erro inesperado',
              message:
                'Ocorreu um erro inesperado ao efetuar a requisição, tente novamente mais tarde ou contate o suporte!',
            })
            break
        }
      }
      return [error]
    }

    const { data } = response as AxiosResponse<T>
    return [undefined, data]
  }, [])

  return caller
}
