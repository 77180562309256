export class Nbr {
  private constructor(public readonly value?: number) {}

  static from(input?: string | number): Nbr {
    if (!input) return new Nbr()
    if (typeof input === 'number') return new Nbr(input)
    if (!input.length || input.trim() === '') return new Nbr()

    const parsedValue = Number(input)
    return new Nbr(isNaN(parsedValue) ? undefined : parsedValue)
  }

  get isBlank(): boolean {
    if (!this.value) return true

    const parsedValue = Number(this.value)
    if (isNaN(parsedValue)) return true
    return false
  }

  /** @description returns 0 if value isNaN or undefined */
  get val(): number {
    if (!this.value) return 0
    if (isNaN(this.value)) return 0
    return this.value
  }
}

export function formatNumber(num: number): string {
  const suffixes = ['', 'K', 'M', 'B', 'T']
  let suffixIndex = 0
  while (num >= 1000 && suffixIndex < suffixes.length - 1) {
    num /= 1000
    suffixIndex++
  }
  const formattedNumber = num.toFixed(0)
  return `${formattedNumber}${suffixes[suffixIndex]}`
}
