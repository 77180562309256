import { Tokens } from '@/assets/tokens'
import { styled } from 'styled-components'

export const InputContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`

export const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${Tokens.ColorTextPrimary};
`

export const IconImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`

export const InnerContainer = styled.div`
  width: 100%;
  height: ${Tokens.HeightInput};
  padding: 0 16px;
  border-radius: ${Tokens.RadiusInput};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;

  background-color: ${Tokens.ColorBackground};

  &:focus-within {
    background-color: ${Tokens.ColorHover};
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Tokens.ColorUIPrimaryDim};
  }

  & > span > svg {
    width: 1rem;
    height: 1rem;
  }
`

export const InputIn = styled.input`
  all: unset;

  min-width: 100px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 400;

  &::placeholder {
    color: ${Tokens.ColorTextTertiary};
    opacity: 1; /* Firefox */
  }
`
