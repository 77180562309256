import styled from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${Tokens.ColorTextPrimary}70;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0.2rem);
  z-index: 100;
`
export const Modal = styled.div`
  width: 20rem;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1.5rem;

  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
`

export const IconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  background-color: ${Tokens.ColorUIDanger}25;

  & > * {
    color: ${Tokens.ColorUIDanger};
  }
`

export const Title = styled.h2`
  text-align: center;
`

export const Text = styled.span`
  font-size: 14px;
  text-align: center;
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  gap: 0.5rem;
`
