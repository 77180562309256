import { Tokens } from '@/assets/tokens'
import styled from 'styled-components'

export const LogoContainer = styled.div`
  height: 10rem;
  aspect-ratio: 1 / 1;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${Tokens.ColorBackground};

  & > span {
    color: ${Tokens.ColorUIPrimary};
  }
`

export const Logo = styled.img`
  width: 100%;
  aspect-ratio: 1 / 0.5;
  border-radius: 20px;
`
