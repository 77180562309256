import LogoSrc from '@/assets/images/logo.svg'
import versionInfo from '@/version.json'
import { Button } from '@/components'

import { Routes } from '@/configs'
import { useRouting } from '@/hooks'
import { useEffect } from 'react'
import {
  Background,
  Container,
  Content,
  Footer,
  FooterLink,
  FooterText,
  Header,
  Subtitle,
  Title,
} from './Welcome.styles'

export const Welcome = () => {
  const { navigate } = useRouting()

  function handleClickLogin(): void {
    navigate(Routes.Login.path)
  }

  useEffect(() => {
    const $element = document.querySelector('.help-desk-container')
    const $parent = $element?.parentElement
    $element?.remove()
    return () => {
      $parent?.appendChild($element!)
    }
  }, [])

  return (
    <Background>
      <Container>
        <Header>
          <img src={LogoSrc} alt="logo" />
          <Button.Root onClick={handleClickLogin} $variant="primary">
            <Button.Text value="Entrar" />
          </Button.Root>
        </Header>
        <Content>
          <Title>reput•AI•tion</Title>
          <Subtitle>Sistema de Análise de Dados e Inteligência Artificial</Subtitle>
          <Subtitle style={{ paddingTop: '80px' }}>Seja bem-vindo(a)!</Subtitle>
        </Content>
        <Footer>
          <div style={{ display: 'flex', gap: '28px', alignItems: 'center' }}>
            <FooterText>&copy; 2024 FSB Holding</FooterText>
            <FooterLink href="https://wikkidata.com.br/" target="_blank" rel="noopener noreferrer">
              Sobre Nós
            </FooterLink>
          </div>
          <FooterText style={{ fontSize: '12px', marginTop: '5px' }}>Versão {versionInfo.version}</FooterText>
        </Footer>
      </Container>
    </Background>
  )
}
