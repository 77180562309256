import React, { ReactNode } from 'react'

import { UserResponseRole } from '@shared/models'

import { useAuth } from './auth.context'

type Props = {
  requiredRoles: UserResponseRole[]
  children: ReactNode
}

export const ProtectedComponent: React.FC<Props> = ({ children, requiredRoles }): JSX.Element | null => {
  const { hasAnyRole } = useAuth()

  if (!hasAnyRole(...requiredRoles)) return null
  return <>{children}</>
}
