/* eslint-disable react-refresh/only-export-components */
import React, { ReactNode, RefObject, useEffect, useState } from 'react'

import { Down, Up } from '@icon-park/react'

import loadingImgSrc from '@/assets/images/load.gif'
import { Tokens } from '@/assets/tokens'

import { Icon } from '@/components/core'

import { SelectionOption } from '../Selection.hooks'
import {
  ComponentContainer,
  Label,
  SelectedItem,
  SelectionContainer,
  Selector,
  SelectorOptions,
} from './Default.styles'
import { CleanButton } from '../Selection.subcomponents'

type Props = {
  label?: string
  title?: string
  placeholder?: string
  options: SelectionOption[]
  onSelect: (value?: SelectionOption) => void
  selectedOption?: SelectionOption
  loading?: boolean
  required?: boolean
  children?: ReactNode
  $inverted?: boolean
  $fit?: boolean
}

export function DefaultSelector({
  label,
  title,
  placeholder,
  options,
  onSelect,
  selectedOption,
  loading = false,
  required = false,
  children,
  $inverted = false,
  $fit = false,
}: Props): JSX.Element {
  const ref: RefObject<HTMLDivElement> = React.createRef()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  function handleSelection(selectedOption?: SelectionOption): void {
    onSelect(selectedOption)
    setTimeout(() => setIsOpen(false), 100)
  }

  const textToShow: string = selectedOption ? selectedOption.text : (placeholder ?? '')

  const $placeholderColor =
    $inverted ? Tokens.ColorWhite
    : textToShow === placeholder ? Tokens.ColorTextTertiary
    : Tokens.ColorTextPrimary

  return (
    <ComponentContainer ref={ref} $fit={$fit}>
      {label && (
        <Label>
          {label}
          {required && '*'}
        </Label>
      )}
      <SelectionContainer onClick={() => setIsOpen((value) => !value)} $inverted={$inverted} title={title}>
        {children}
        <SelectedItem $placeholderColor={$placeholderColor}>{textToShow}</SelectedItem>
        {selectedOption && <CleanButton onClick={(): void => handleSelection()} />}
        {loading ?
          <img alt="loading" src={loadingImgSrc} />
        : <Icon value={isOpen ? Up : Down} size={16} color={$inverted ? Tokens.ColorWhite : Tokens.ColorUIPrimary} />}
        {options.length > 0 && (
          <Selector $show={isOpen}>
            {options.map((option) => (
              <SelectorOptions key={option.value} onClick={() => handleSelection(option)}>
                {option.text}
              </SelectorOptions>
            ))}
          </Selector>
        )}
      </SelectionContainer>
    </ComponentContainer>
  )
}
