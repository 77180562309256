import { useContext } from 'react'

import { Routes } from '@/configs'

import { useFileUploader, useInput, useRouting } from '@/hooks'

import { Button, Flex, Form, Input, Switch, Uploader } from '@/components'
import { UploaderViewer } from '@/components/derived'
import { Layout } from '@/components/layout'

import { CreateCompanyContext, CreateCompanyContextProvider, CreateCompanyType } from './CreateCompany.context'
import { FormContainer, HeaderContainer, LogoContainer, TitleContainer } from './CreateCompany.styles'
import { BrandsModal } from './components/BrandsModal'

export function CreateCompany() {
  return (
    <CreateCompanyContextProvider>
      <Component />
    </CreateCompanyContextProvider>
  )
}

function Component() {
  const { navigate, queryParams } = useRouting()
  const { isCreatingCompany, save } = useContext(CreateCompanyContext)

  const hasCompetitorsBinder = Switch.Hook()
  const governmentalBinder = Switch.Hook()
  const { value: name, binder: bindName } = useInput()
  const { value: keywords, binder: bindKeywords } = useInput()
  const { value: systemLogo, bind: bindSystemLogo } = useFileUploader()
  const { value: reportLogo, bind: bindReportLogo } = useFileUploader()

  const { mae: mother } = queryParams

  const onSubmit = () => {
    const input = {
      name,
      keywords,
      hasCompetitors: hasCompetitorsBinder.isChecked,
      isGovernmental: governmentalBinder.isChecked,
      systemLogo,
      reportLogo,
    } as CreateCompanyType
    if (mother) {
      delete input.hasCompetitors
      input.motherID = mother
    }
    save(input)
  }

  return (
    <Layout.Root noMenu>
      <Layout.Padder>
        <FormContainer>
          <HeaderContainer>
            <TitleContainer>
              <h1>Registrar Nova Empresa</h1>
              <span>
                Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro inicial da empresa
              </span>
            </TitleContainer>
            <Button.Root onClick={() => navigate(Routes.Home)} $variant="outlined">
              Cancelar
            </Button.Root>
          </HeaderContainer>
          <BrandsModal />
          <Form title="Nova empresa" onSubmit={onSubmit}>
            <Flex $type="column" $align="stretch" $gap={24} $fit>
              <Input label="Nome da empresa" placeholder="Nome da empresa" {...bindName} required />
              <Flex $type="row" $justify="center" $align="center" $gap={24} $fit>
                <LogoContainer>
                  <Uploader
                    label="Logo em cores (.svg 417px/128px)"
                    {...bindSystemLogo}
                    requiredExtensions={['svg']}
                    required
                  />
                  <UploaderViewer value={systemLogo} />
                </LogoContainer>
                <LogoContainer>
                  <Uploader
                    label="Logo em cinza (.svg 417px/128px)"
                    {...bindReportLogo}
                    requiredExtensions={['svg']}
                    required
                  />
                  <UploaderViewer value={reportLogo} />
                </LogoContainer>
              </Flex>
            </Flex>
            <Input
              label="Palavras-chaves (separadas por vírgula)"
              placeholder="Palavras-chave"
              {...bindKeywords}
              required
            />
            <Flex $type="row" $justify="space-between" $align="stretch" $gap={20} $fit>
              <Flex $type="row" $justify="flex-start" $align="stretch" $gap={20} $fit>
                {!mother && <Switch.Root label="Cadastrar concorrentes" {...hasCompetitorsBinder} />}
                <Switch.Root label="É instituição governamental" {...governmentalBinder} />
              </Flex>
              {systemLogo && (
                <Button.Root type="submit" $variant="primary" $loading={isCreatingCompany}>
                  <Button.Text value="Salvar" />
                </Button.Root>
              )}
            </Flex>
          </Form>
        </FormContainer>
      </Layout.Padder>
    </Layout.Root>
  )
}
