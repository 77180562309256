export type Result<T> = [Error] | [undefined, T]
export type PromisedResult<T> = Promise<Result<T>>

export function toResult<T>(executable: () => T): Result<T> {
  try {
    const result: T = executable()
    return [undefined, result as T]
  } catch (error) {
    return [error as Error]
  }
}

export async function toAsyncResult<T>(promise: Promise<T>): PromisedResult<T> {
  try {
    const result: Awaited<T> = await promise
    return [undefined, result as T]
  } catch (error) {
    if (error instanceof Error) {
      return [error]
    }
    return [new Error('Alguma coisa deu errado!')]
  }
}
