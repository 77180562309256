import { useAuth } from './auth.context'
import { AuthGuard } from './auth.guard'
import { AuthProvider } from './auth.provider'
import { ProtectedComponent } from './protected.component'

export const Auth = {
  Guard: AuthGuard,
  Hook: useAuth,
  Provider: AuthProvider,
  ProtectedComponent,
}
