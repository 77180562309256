import { ChangeEvent, useEffect, useRef, useState } from 'react'

import UploadImg from '@/assets/images/upload.svg'
import { Tokens } from '@/assets/tokens'

import { Api } from '@/modules'

import { generateRandomID } from '@/utils'

import { Flex, Icon, SelectionOption, Selector } from '@/components'

import { LinksContainer } from '@/components/derived/ManualCard/ManualCard.styles'
import { useRouting } from '@/hooks'
import { Download } from '@icon-park/react'
import { CompanyClipperResponse, Paged } from '@shared/models'
import { Component, Input } from './Uploader.styles'

interface UploaderProps {
  requiredExtensions?: string[]
  onUpload: (file: File, companyClipperID: string) => void
}

export function Uploader({ onUpload, requiredExtensions }: UploaderProps) {
  const id = generateRandomID()

  const { companyID } = useRouting()
  const inputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | undefined>()

  const { binder: bindClipper, selectedOption: selectedClipper } = Selector.Binder()

  const [getTemplateLink] = Api.PromiseQuery<{ fileLink: string }>(`/clippers/template`)
  const [isSearchingClippers, , clippers] = Api.Query<Paged<CompanyClipperResponse>>(`companies/${companyID}/clippers`)

  useEffect(() => {
    if (selectedClipper?.value && selectedFile) {
      onUpload(selectedFile, String(selectedClipper.value))
    }
  }, [selectedFile])

  useEffect(() => {
    if (selectedClipper?.value) {
      inputRef.current?.click()
    }
  }, [selectedClipper])

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event?.target?.files

    if (files?.length) {
      setSelectedFile(files?.length ? files[0] : undefined)
    }

    if (inputRef?.current) {
      inputRef.current.value = ''
    }
  }

  const extensions: string | undefined =
    requiredExtensions ? requiredExtensions.map((extension) => `.${extension}`).join(', ') : undefined

  return (
    <>
      <Component>
        <img src={UploadImg} alt="Upload" />
        <h2 style={{ color: Tokens.ColorTextTertiary }}>Selecione a clipadora no botão abaixo</h2>
        <Selector.Default
          {...bindClipper}
          placeholder="Selecione a clipadora"
          options={
            clippers?.elements.map(
              (element): SelectionOption => ({
                text: `${element.clipperName} [${element.dataSourceName}]`,
                value: element.id,
              }),
            ) ?? []
          }
          loading={isSearchingClippers}
          $inverted
        />
        <LinksContainer>
          <Flex $type="row" $justify="center" $align="center" $gap={32} $fit>
            {
              <Flex
                $type="row"
                $align="center"
                $gap={8}
                onClick={async () => {
                  const [template] = await getTemplateLink()
                  if (template?.fileLink) window.location.href = template.fileLink
                  return false
                }}>
                <h4 className="link-text">Baixar template</h4>
                <Icon value={Download} color={Tokens.ColorUIPrimary} size={16} />
              </Flex>
            }
          </Flex>
        </LinksContainer>
      </Component>
      <Input id={id} ref={inputRef} type="file" onChange={handleChange} accept={extensions} />
    </>
  )
}
