import { ColumnType } from './Table.component'
import { TableHeaderCell } from './Table.styles'

type Props<T> = {
  columns: ColumnType<T>[]
}

export function TableHeader<T>({ columns }: Props<T>) {
  return (
    <tr>
      {columns.map((column, columnIndex) => (
        <TableHeaderCell
          key={`table-head-cell-${columnIndex}`}
          style={{ width: column.width, textAlign: column.align ?? 'left' }}>
          {column.title ?? ''}
        </TableHeaderCell>
      ))}
    </tr>
  )
}
