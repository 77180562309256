import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { fillEndPoint } from '../utils/fillEndPoint'
import { getFromApi } from '../utils/getFromApi'
import { useValues } from '../ValuesContext'
import { downloadPNG } from './utils'

const SquareContainer = styled.div`
  width: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin: 0;
  position: relative;
`

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const InnerContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TitleWrapper = styled.div``

const Title = styled.h3`
  font-size: 36px;
  margin: 0;
  color: #171717;
  font-weight: 600;
  font-family: TT-Commons-Demibold !important;
`

const Subtitle = styled.div`
  margin-bottom: 20px;
`

const Value = styled.span`
  font-size: 18px;
  color: #434343;
  margin-top: 10px;
  strong {
    font-family: TT-Commons-Demibold !important;
    font-size: 32px;
  }
`

const mapRelevanceColor: Record<string, string> = {
  Crítico: '#FF954D',
  'Muito Crítico': '#FF4F33',
  Observação: '#FFF168',
  Reação: '#FFEF54',
  Ideal: '#40FFDD',
  Oportunidade: '#53FF5A',
}

function classifyPoint(x: number, y: number) {
  if (x <= 2.5) {
    if (y >= -5 && y < 0) {
      return 'Crítico'
    } else if (y >= 0 && y <= 2.5) {
      return 'Observação'
    } else if (y > 2.5 && y <= 5) {
      return 'Oportunidade'
    }
  } else if (x > 2.5) {
    if (y >= -5 && y < 0) {
      return 'Muito Crítico'
    } else if (y >= 0 && y <= 2.5) {
      return 'Reação'
    } else if (y > 2.5 && y <= 5) {
      return 'Ideal'
    }
  }
  return 'Fora do alcance'
}

interface RelevanceDisplayProps {
  endPoint: string
}

const RelevanceDisplay: React.FC<RelevanceDisplayProps> = ({ endPoint }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [relevanceName, setRelevanceName] = useState('')
  const [CENANumber, setCENANumber] = useState('')
  const { values }: any = useValues()
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (Object.keys(values).length === 0 || !values.idEmpresaIN || values.k) {
      return
    }

    setIsLoading(true)

    const cenaEndPoint: any = fillEndPoint(`${endPoint}numberCENA`, values)

    getFromApi(cenaEndPoint).then((cena) => {
      const cenaValue = cena?.CENA ?? 0
      setCENANumber(cenaValue === 0 ? 'N/A' : cenaValue.toFixed(2))
    })

    const endPointPWR = `${endPoint}eixosPWR`
    const getUrl: any = fillEndPoint(endPointPWR, values)
    getFromApi(getUrl).then((elements) => {
      if (elements.length === 0) {
        setRelevanceName('NoData')
        setIsLoading(false)
        return
      }
      const x = elements[0].centro_eixox
      const y = elements[0].centro_eixoy

      const name = classifyPoint(x, y)
      setRelevanceName(name)
      setIsLoading(false)
    })
  }, [values])

  const exportAsPng = () => {
    downloadPNG(ref, 'relevance-display')
  }

  if (isLoading) {
    return <div className="column-chart-container-spinner" style={{ width: '338px', height: '424px' }}></div>
  }

  if (relevanceName === 'NoData') {
    return (
      <div
        className="column-chart-container"
        style={{
          width: '338px',
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        Não há dados para análise no período
      </div>
    )
  }

  return (
    <div className="chart-container">
      <SquareContainer className="column-chart-container" ref={ref}>
        <Subtitle className="column-chart-subtitle">
          <TitleWrapper>
            <div>Relevância no Período</div>
            <button className="export-button" onClick={exportAsPng}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#C7C7C7">
                <path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z" />
              </svg>
            </button>
          </TitleWrapper>
        </Subtitle>
        <Content>
          <InnerContainer color={mapRelevanceColor[relevanceName]}>
            <Title className="column-chart-title">{relevanceName}</Title>
            <Value>
              Valor CENA: <strong>{CENANumber}</strong>
            </Value>
          </InnerContainer>
        </Content>
      </SquareContainer>
    </div>
  )
}

export default RelevanceDisplay
