import { keyframes } from 'styled-components'

export const onClose = keyframes`
from {
  transform: translateX(-100vw);
  width: 0;
}

to {
  margin-top: 10px;
  width: 38px;
  transform: translateX(0);
}
`

export const onOpen = keyframes`
from {
  transform: translateX(-100vw);
  width: 0;
}

to {
  width: 77px;
  transform: translateX(0);
}
`
