export enum UserResponseRole {
  Admin = 'ADMIN',
  Leadership = 'CHEFIA',
  Assessor = 'ASSESSOR',
}

export enum SpokespersonOperation {
  Keep = 'MANTER',
  Remove = 'REMOVER',
  Update = 'ATUALIZAR',
  Ponder = 'PONDERAR',
}

export enum UploadStatus {
  UPLOADING = 'Enviando planilha',
  UPLOADED = 'Planilha enviada',
  STARTED = 'Início da classificação (IA)',
  FINISHED = 'Processamento finalizado',
  ERRORED = 'Erro. Contacte suporte',
}

export enum UpdatedQueueStatus {
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  ERRORED = 'ERRORED',
}

export enum MentionRole {
  PROTAGONIST = 'Protagonista',
  SUPPORTING = 'Coadjuvante',
  CITATION = 'Citação',
}

export enum PolarityValue {
  UNFAVORABLE = 'DESFAVORAVEL',
  FAVORABLE = 'FAVORAVEL',
  NEGATIVE = 'NEGATIVO',
  NEUTRAL = 'NEUTRO',
  POSITIVE = 'POSITIVO',
}

export const PolarityID = new Map<PolarityValue, number>([
  [PolarityValue.FAVORABLE, 1],
  [PolarityValue.UNFAVORABLE, 2],
  [PolarityValue.POSITIVE, 3],
  [PolarityValue.NEGATIVE, 4],
  [PolarityValue.NEUTRAL, 5],
])
