/* eslint-disable react-refresh/only-export-components */
export * from './Selection.hooks'

import { ElementType, memo } from 'react'

import { DefaultSelector } from './Default/Default.component'
import { SearchableSelector } from './Searchable/SearchableSelector.component'
import { useSelectionBinder } from './Selection.hooks'
import { SelectorIconContainer, SelectorIconContainerProps } from './Selection.styles'

export const Selector = {
  Binder: useSelectionBinder,
  Default: memo(DefaultSelector),
  Searchable: memo(SearchableSelector),
  Icon: memo(IconComponent),
}

type IconProps = SelectorIconContainerProps & {
  value: ElementType
}

function IconComponent({ color, value: Icon }: IconProps) {
  return (
    <SelectorIconContainer color={color}>
      <Icon size={16} />
    </SelectorIconContainer>
  )
}
