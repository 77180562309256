import { Tokens } from '@/assets/tokens'
import styled from 'styled-components'

export const Component = styled.form`
  all: unset;
  border-radius: 20px;
  padding: 40px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 40px;

  background-color: ${Tokens.ColorWhite};
`
