import { styled } from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: ${Tokens.ColorBackground};
`

export const Header = styled.div`
  width: 100vw;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 2rem;
`

export const Logo = styled.img`
  width: 4.5rem;
`

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const BodyLogo = styled.img`
  width: 53rem;
`

export const BodyContent = styled.div`
  margin-left: -8rem;
  margin-top: -14.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const BodyTitle = styled.h1`
  margin-left: -0.5rem;
  font-size: 8rem;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: ${Tokens.ColorUIPrimary};
`

export const BodySubtitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: ${Tokens.ColorTextPrimary};
`

export const BodyText = styled.span`
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: left;
  color: ${Tokens.ColorTextTertiary};
`
