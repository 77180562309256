import { DeleteFive } from '@icon-park/react'
import { memo, useEffect } from 'react'

import { SearchResponse, ThemeResponse } from '@shared/models'

import { Tokens } from '@/assets/tokens'
import { useRouting } from '@/hooks'
import { Alert, Api, Confirmation } from '@/modules'

import { Button, Divider, Flex, SelectedList, TableImage } from '@/components'
import { ColumnType, Table } from '@/components/derived'

import { Item, useSelectedList } from '@/components/core/SelectedList/SelectedList.hooks'
import { getFromToElements } from '@/utils/pagination'
import { Str } from '@/utils/strings'
import { useCompanyContext } from '../CompanyDetails.context'
import { ThemeTableContainer } from './Themes.styles'

export const ThemesTable = memo(Component)

function Component(): JSX.Element | null {
  const { companyID, currentPage } = useRouting()
  const { isLoadingThemes, themes, reloadThemes } = useCompanyContext()

  const { sendAlert } = Alert.Hook()
  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    title: 'Você tem certeza?',
    text: 'Esta ação não tem como ser desfeita. Todos os valores associados à esse tema serão perdidos',
    confirmText: 'Sim, Apagar Tema!',
  })

  const { binder: brandsFilterBinder, selectedOptions: selectedBrandsForFilter } = useSelectedList(
    (param: string): void => {
      loadBrands({ name: param })
    },
  )
  const { binder: themeGroupsFilterBinder, selectedOptions: selectedThemeGroupsForFilter } = useSelectedList(
    (param: string): void => {
      loadThemeGroups({ name: param })
    },
  )

  const [isLoadingBrands, loadBrands, brands] = Api.Query<SearchResponse[]>(`brands/search?company=${companyID}`)
  const [isLoadingThemeGroups, loadThemeGroups, themeGroups] = Api.Query<SearchResponse[]>(
    `theme-groups/search?company=${companyID}`,
  )
  const { isRunning: isDeleting, run: deleteTheme } = Api.Command<void>(Api.Methods.DELETE, 'themes/:id')

  const selectedBrandsParsed = selectedBrandsForFilter.map((item: Item) => item.value).join(',')
  const selectedThemeGroupsParsed = selectedThemeGroupsForFilter.map((item: Item) => item.value).join(',')

  useEffect(() => {
    reloadThemes({
      page: currentPage,
      brands: selectedBrandsParsed,
      themeGroups: selectedThemeGroupsParsed,
    })
  }, [currentPage, selectedBrandsParsed, selectedThemeGroupsParsed])

  function onConfirmationAccepted(id: string): void {
    deleteTheme<void>({
      binds: [['id', id]],
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Exclusão do tema',
          message: 'Tema excluído com sucesso!',
        })
        reloadThemes({
          page: currentPage,
          brands: selectedBrandsParsed,
          themeGroups: selectedThemeGroupsParsed,
        })
      },
    })
  }

  const columns: ColumnType<ThemeResponse>[] = [
    {
      key: 'brandName',
      render: (_, theme) => <TableImage id={theme.brandLogo} />,
    },
    {
      key: 'value',
      title: 'Nome',
    },
    {
      key: 'options',
      render: (_, theme) => (
        <Flex $type="row" $justify="flex-start" $align="stretch" $gap={24}>
          <Button.Root
            $variant="link"
            style={{ color: Tokens.ColorUIDanger }}
            onClick={() => openConfirmation(() => onConfirmationAccepted(theme.id))}
            $loading={isDeleting}>
            Excluir
          </Button.Root>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Temas registrados" />
        <Divider.Spacer />
        <Divider.Subtitle
          value={`${getFromToElements(currentPage, themes?.elements.length ?? 0)}/${themes?.total ?? 0} Entrada(s)`}
        />
      </Divider.Root>

      <ThemeTableContainer>
        <SelectedList
          placeholder="Marcas"
          isLoading={isLoadingBrands}
          options={
            brands?.map((item: SearchResponse): Item => ({ text: Str.from(item.text).initCap, value: item.value })) ??
            []
          }
          {...brandsFilterBinder}
        />
        <SelectedList
          placeholder="Grupos"
          isLoading={isLoadingThemeGroups}
          options={
            themeGroups?.map(
              (item: SearchResponse): Item => ({ text: Str.from(item.text).initCap, value: item.value }),
            ) ?? []
          }
          {...themeGroupsFilterBinder}
        />
        <Table isLoading={isLoadingThemes} columns={columns} data={themes?.elements} totalPages={themes?.pages} />
      </ThemeTableContainer>
    </>
  )
}
