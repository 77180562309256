import { ReactElement } from 'react'

import { Flex } from '@/components'

import { SvgPath } from './Role.styles'

function Star({ children }: { children: ReactElement }) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  )
}

export function Role({ value }: { value: number }) {
  return (
    <Flex $type="row" $justify="center" $align="center" $gap={4}>
      <Star>
        <SvgPath
          d="M6.4338 3.03875C7.1662 1.47417 7.57183 0.671218 7.66197 0.600369C7.74084 0.547232 7.89296 0.5 8 0.5C8.10704 0.5 8.25915 0.547232 8.33803 0.600369C8.42817 0.671218 8.8338 1.47417 9.5662 3.03875C10.169 4.31993 10.6592 5.37675 10.6648 5.38266C10.6704 5.38856 11.2056 5.47712 11.8592 5.57749C12.5127 5.67196 13.6394 5.84908 14.3662 5.95535C15.6732 6.15609 15.6901 6.16199 15.8423 6.33911C15.9549 6.469 16 6.58708 16 6.74059C16 6.95904 15.9268 7.03579 12.3268 10.7435L12.5915 12.3376C12.7324 13.2173 12.9296 14.3745 13.0197 14.9236C13.1718 15.8328 13.1775 15.9273 13.0986 16.1162C13.0479 16.2402 12.9408 16.3583 12.8282 16.4173C12.7155 16.4764 12.5972 16.4941 12.507 16.4705C12.4282 16.4469 11.6451 16.0336 10.7606 15.5435C9.87606 15.0535 8.89577 14.5162 8 14.0203L7.05352 14.5399C6.53521 14.8292 5.79718 15.2365 5.40845 15.4491C5.01972 15.6616 4.4338 15.9863 4.09577 16.1694C3.76338 16.3524 3.45352 16.5 3.40845 16.5C3.36338 16.5 3.2507 16.4587 3.15493 16.4114C3.05915 16.3642 2.94648 16.2343 2.90141 16.1162C2.82254 15.9273 2.82817 15.8447 2.95211 15.1007C3.03099 14.652 3.22253 13.4889 3.67324 10.7435L1.83662 8.85424C0.0732394 7.03579 0 6.95904 0 6.74059C0 6.58708 0.0450704 6.469 0.309859 6.16199L2.22535 5.87269C3.27887 5.70738 4.40563 5.53616 4.73239 5.48303C5.05915 5.43579 5.32958 5.38856 5.33521 5.38266C5.34084 5.37675 5.83099 4.31993 6.4338 3.03875Z"
          $active={value >= 1}
        />
      </Star>
      <Star>
        <SvgPath
          d="M6.4338 3.03875C7.1662 1.47417 7.57183 0.671218 7.66197 0.600369C7.74084 0.547232 7.89296 0.5 8 0.5C8.10704 0.5 8.25915 0.547232 8.33803 0.600369C8.42817 0.671218 8.8338 1.47417 9.5662 3.03875C10.169 4.31993 10.6592 5.37675 10.6648 5.38266C10.6704 5.38856 11.2056 5.47712 11.8592 5.57749C12.5127 5.67196 13.6394 5.84908 14.3662 5.95535C15.6732 6.15609 15.6901 6.16199 15.8423 6.33911C15.9549 6.469 16 6.58708 16 6.74059C16 6.95904 15.9268 7.03579 12.3268 10.7435L12.5915 12.3376C12.7324 13.2173 12.9296 14.3745 13.0197 14.9236C13.1718 15.8328 13.1775 15.9273 13.0986 16.1162C13.0479 16.2402 12.9408 16.3583 12.8282 16.4173C12.7155 16.4764 12.5972 16.4941 12.507 16.4705C12.4282 16.4469 11.6451 16.0336 10.7606 15.5435C9.87606 15.0535 8.89577 14.5162 8 14.0203L7.05352 14.5399C6.53521 14.8292 5.79718 15.2365 5.40845 15.4491C5.01972 15.6616 4.4338 15.9863 4.09577 16.1694C3.76338 16.3524 3.45352 16.5 3.40845 16.5C3.36338 16.5 3.2507 16.4587 3.15493 16.4114C3.05915 16.3642 2.94648 16.2343 2.90141 16.1162C2.82254 15.9273 2.82817 15.8447 2.95211 15.1007C3.03099 14.652 3.22253 13.4889 3.67324 10.7435L1.83662 8.85424C0.0732394 7.03579 0 6.95904 0 6.74059C0 6.58708 0.0450704 6.469 0.309859 6.16199L2.22535 5.87269C3.27887 5.70738 4.40563 5.53616 4.73239 5.48303C5.05915 5.43579 5.32958 5.38856 5.33521 5.38266C5.34084 5.37675 5.83099 4.31993 6.4338 3.03875Z"
          $active={value >= 2}
        />
      </Star>
      <Star>
        <SvgPath
          d="M6.4338 3.03875C7.1662 1.47417 7.57183 0.671218 7.66197 0.600369C7.74084 0.547232 7.89296 0.5 8 0.5C8.10704 0.5 8.25915 0.547232 8.33803 0.600369C8.42817 0.671218 8.8338 1.47417 9.5662 3.03875C10.169 4.31993 10.6592 5.37675 10.6648 5.38266C10.6704 5.38856 11.2056 5.47712 11.8592 5.57749C12.5127 5.67196 13.6394 5.84908 14.3662 5.95535C15.6732 6.15609 15.6901 6.16199 15.8423 6.33911C15.9549 6.469 16 6.58708 16 6.74059C16 6.95904 15.9268 7.03579 12.3268 10.7435L12.5915 12.3376C12.7324 13.2173 12.9296 14.3745 13.0197 14.9236C13.1718 15.8328 13.1775 15.9273 13.0986 16.1162C13.0479 16.2402 12.9408 16.3583 12.8282 16.4173C12.7155 16.4764 12.5972 16.4941 12.507 16.4705C12.4282 16.4469 11.6451 16.0336 10.7606 15.5435C9.87606 15.0535 8.89577 14.5162 8 14.0203L7.05352 14.5399C6.53521 14.8292 5.79718 15.2365 5.40845 15.4491C5.01972 15.6616 4.4338 15.9863 4.09577 16.1694C3.76338 16.3524 3.45352 16.5 3.40845 16.5C3.36338 16.5 3.2507 16.4587 3.15493 16.4114C3.05915 16.3642 2.94648 16.2343 2.90141 16.1162C2.82254 15.9273 2.82817 15.8447 2.95211 15.1007C3.03099 14.652 3.22253 13.4889 3.67324 10.7435L1.83662 8.85424C0.0732394 7.03579 0 6.95904 0 6.74059C0 6.58708 0.0450704 6.469 0.309859 6.16199L2.22535 5.87269C3.27887 5.70738 4.40563 5.53616 4.73239 5.48303C5.05915 5.43579 5.32958 5.38856 5.33521 5.38266C5.34084 5.37675 5.83099 4.31993 6.4338 3.03875Z"
          $active={value === 3}
        />
      </Star>
    </Flex>
  )
}
