/* eslint-disable react-refresh/only-export-components */
import { ReactNode, createContext, memo, useState } from 'react'

interface Output {
  isClosed: boolean
  toggle: () => void
}

export const MenuContext = createContext<Output>({} as Output)

export const MenuContextProvider = memo(ContextProviderComponent)

function ContextProviderComponent({ children }: { children: ReactNode }) {
  const [isClosed, setIsClosed] = useState<boolean>(true)

  return (
    <MenuContext.Provider
      value={{
        isClosed,
        toggle: () => setIsClosed((prev) => !prev),
      }}>
      {children}
    </MenuContext.Provider>
  )
}
