import { useState } from 'react'

export type UseSwitchBinder = {
  isChecked: boolean
  toggle: () => void
  reset: () => void
}

export function useSwitch(defaultValue: boolean = false): UseSwitchBinder {
  const [isChecked, setIsChecked] = useState<boolean>(defaultValue)

  return {
    isChecked,
    toggle: (): void => setIsChecked((prev: boolean): boolean => !prev),
    reset: () => setIsChecked(defaultValue),
  }
}
