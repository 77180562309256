import { Tokens } from '@/assets/tokens'
import { css, styled } from 'styled-components'

export const ComponentContainer = styled.label`
  width: fit-content;
  height: ${Tokens.HeightInput};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;

  cursor: pointer;
`

export const Label = styled.span`
  color: ${Tokens.ColorTextPrimary};
`

export const Component = styled.div<{ checked: boolean }>`
  position: relative;

  flex: 1;

  width: 3.5rem;
  height: 2rem;
  border-radius: 20px;

  background-color: ${Tokens.ColorBorder};
  transition: all ease-in-out 0.25s;

  & > input {
    display: none;
  }

  &:after {
    position: absolute;
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 20px;
    top: 0.25rem;
    left: 0.25rem;
    background-color: ${Tokens.ColorWhite};
    transition: all ease-in-out 0.25s;
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${Tokens.ColorUIPrimary};

      &:after {
        transform: translateX(calc(100% + 0.55rem));
      }
    `}
`

export const Input = styled.input`
  width: 100%;
  height: 100%;
  display: none;
`
