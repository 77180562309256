import { useState } from 'react'

import {
  BookmarkOne,
  Calendar,
  DocumentFolder,
  Down,
  Earth,
  People,
  SmilingFace,
  SpeakerOne,
  Star,
  Tag,
  Time,
  Up,
} from '@icon-park/react'

import { Tokens } from '@/assets/tokens'

import { Button, Flex, Icon, Input, Selector } from '@/components'

import { useMentionsContext } from '../../Mentions.context'

import {
  FilterGroup,
  FilterGroupLine,
  InvisibleArea,
  MentionsFilterContainer,
  MentionsFilterHeader,
  MentionsFilterItemContainer,
} from './Filters.styles'

import { AccordionIcon } from '@/pages/Dashboard/components/Accordion/Accordion.styles'
import { Str } from '@/utils/strings'
import { Alert } from '@/modules'

export function Filters() {
  const {
    isLoadingMentions,

    applyFilters,
    resetFilters,
    startDateBinder,
    endDateBinder,
    startTimeBinder,
    endTimeBinder,
    brandBinder,
    polarityBinder,
    channelBinder,
    roleBinder,
    themeGroupBinder,
    themeBinder,
    publisherBinder,
    sourceBinder,
    contentBinder,

    isSearchingBrands,
    isSearchingChannels,
    isSearchingRoles,
    isSearchingThemeGroups,
    isSearchingThemes,
    isSearchingPublishers,
    brands,
    polarities,
    channels,
    roles,
    themeGroups,
    themes,
    publishers,
  } = useMentionsContext()

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  const [isOpen, setIsOpen] = useState(false)
  const { sendAlert } = Alert.Hook()

  const handleApplyFilters = () => {
    if (!startDateBinder.value || !endDateBinder.value) {
      sendAlert({
        type: 'error',
        title: 'Não foi possível filtrar',
        message: 'A data inicial e a data final são obrigatórias.',
      })
      return
    }
    applyFilters()
  }

  return (
    <FilterGroup>
      <MentionsFilterContainer>
        <MentionsFilterItemContainer>
          <MentionsFilterHeader>
            <div
              style={{
                width: '99.5%',
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '10px',
              }}>
              Filtros
              <div
                style={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}>
                <button
                  onClick={resetFilters}
                  style={{
                    color: '#C7C7C7',
                    cursor: 'pointer',
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    outline: 'none',
                  }}>
                  Limpar Filtros
                </button>
                <button
                  onClick={toggleAccordion}
                  style={{
                    display: 'flex',
                    gap: '2px',
                    cursor: 'pointer',
                    color: '#C7C7C7',
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    outline: 'none',
                  }}>
                  <span style={{ color: '#01c38d' }}>{isOpen ? 'Menos' : 'Mais'}</span>
                  <AccordionIcon style={{ fill: '#01c38d', marginTop: '5px' }}>
                    {isOpen ?
                      <Icon value={Up} color="#01c38d"></Icon>
                    : <Icon value={Down} color="#01c38d"></Icon>}
                  </AccordionIcon>
                </button>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
              }}>
              <div style={{ maxWidth: '130px' }} title="Início">
                <Input icon={Calendar} placeholder="Início" {...startDateBinder} />
              </div>
              <div style={{ maxWidth: '130px' }} title="Fim">
                <Input icon={Calendar} placeholder="Fim" {...endDateBinder} />
              </div>
              <Selector.Default
                {...brandBinder}
                placeholder="Marca"
                title="Marca"
                options={
                  brands?.map((item) => ({
                    text: Str.from(item.text).initCap,
                    value: item.value,
                  })) ?? []
                }
                // onSearch={(searchParam: string): void => {
                //   searchBrands({ name: searchParam })
                // }}
                loading={isSearchingBrands}>
                <Selector.Icon value={Star} color={Tokens.ColorUIPrimary} />
              </Selector.Default>
              <Selector.Default
                {...polarityBinder}
                placeholder="Polaridade"
                title="Polaridade"
                options={
                  polarities?.map((item) => ({
                    text: Str.from(item.text).initCap,
                    value: item.value,
                  })) ?? []
                }>
                <Selector.Icon value={SmilingFace} color={Tokens.ColorUIPrimary} />
              </Selector.Default>
              <Selector.Default
                {...channelBinder}
                placeholder="Canal"
                title="Canal"
                options={
                  channels?.map((item) => ({
                    text: Str.from(item.text).initCap,
                    value: item.value,
                  })) ?? []
                }
                // onSearch={(searchParam: string): void => {
                //   searchChannels({ name: searchParam })
                // }}
                loading={isSearchingChannels}>
                <Selector.Icon value={Earth} color={Tokens.ColorUIPrimary} />
              </Selector.Default>
              <Button.Root
                $variant="primary"
                onClick={handleApplyFilters}
                style={{ width: '180px' }}
                $loading={isLoadingMentions}>
                <Button.Text value="Filtrar" />
              </Button.Root>
            </div>
            <InvisibleArea $isOpen={isOpen} style={{ position: 'relative', zIndex: 2, flexWrap: 'wrap' }}>
              <FilterGroupLine
                style={{
                  display: 'flex',
                  paddingTop: '0.7rem',
                  gap: '10px',
                  width: '100%',
                }}>
                <Selector.Default
                  {...roleBinder}
                  placeholder="Papel"
                  title="Papel"
                  options={
                    roles?.map((item) => ({
                      text: Str.from(item.text).initCap,
                      value: item.value,
                    })) ?? []
                  }
                  // onSearch={(searchParam: string): void => {
                  //   searchRoles({ name: searchParam })
                  // }}
                  loading={isSearchingRoles}>
                  <Selector.Icon value={Star} color={Tokens.ColorUIPrimary} />
                </Selector.Default>
                <Selector.Default
                  {...themeGroupBinder}
                  placeholder="Grupo"
                  title="Grupo"
                  options={
                    themeGroups?.map((item) => ({
                      text: Str.from(item.text).initCap,
                      value: item.value,
                    })) ?? []
                  }
                  // onSearch={(searchParam: string): void => {
                  //   searchThemeGroups({ name: searchParam })
                  // }}
                  loading={isSearchingThemeGroups}>
                  <Selector.Icon value={BookmarkOne} color={Tokens.ColorUIPrimary} />
                </Selector.Default>
                <Selector.Default
                  {...themeBinder}
                  placeholder="Tema"
                  title="Tema"
                  options={
                    themes?.map((item) => ({
                      text: Str.from(item.text).initCap,
                      value: item.value,
                    })) ?? []
                  }
                  // onSearch={(searchParam: string): void => {
                  //   searchThemes({ name: searchParam })
                  // }}
                  loading={isSearchingThemes}>
                  <Selector.Icon value={Tag} color={Tokens.ColorUIPrimary} />
                </Selector.Default>
              </FilterGroupLine>
              <FilterGroupLine
                style={{
                  display: 'flex',
                  gap: '10px',
                  width: '100%',
                }}>
                <Selector.Default
                  {...publisherBinder}
                  placeholder="Publicador"
                  title="Publicador"
                  options={
                    publishers?.map((item) => ({
                      text: Str.from(item.text).initCap,
                      value: item.value,
                    })) ?? []
                  }
                  // onSearch={(searchParam: string): void => {
                  //   searchPublishers({ name: searchParam })
                  // }}
                  loading={isSearchingPublishers}>
                  <Selector.Icon value={People} color={Tokens.ColorUIPrimary} />
                </Selector.Default>

                <Selector.Default
                  {...sourceBinder}
                  placeholder="Fonte"
                  title="Fonte"
                  options={[
                    { text: 'Redes', value: 1 },
                    { text: 'Imprensa', value: 2 },
                  ]}>
                  <Selector.Icon value={SpeakerOne} color={Tokens.ColorUIPrimary} />
                </Selector.Default>
                <Flex $type="row" $justify="space-between" $align="center" $gap={8} $fit>
                  <Input icon={Time} placeholder="Início" {...startTimeBinder} />
                  <Input icon={Time} placeholder="Fim" {...endTimeBinder} />
                </Flex>
              </FilterGroupLine>
              <div style={{ minWidth: '100%' }} title="Conteúdo">
                <Input placeholder="Conteúdo" icon={DocumentFolder} {...contentBinder} />
              </div>
            </InvisibleArea>
          </MentionsFilterHeader>
        </MentionsFilterItemContainer>
      </MentionsFilterContainer>
    </FilterGroup>
  )
}
