import { memo, useEffect, useState } from 'react'

import { DeleteFive } from '@icon-park/react'

import { ChannelResponse } from '@shared/models/channel'

import { Tokens } from '@/assets/tokens'

import { Alert, Api, Confirmation } from '@/modules'

import { Button, Divider, Flex, TableImage, Tooltip } from '@/components'
import { ColumnType, ContentFilter, SkeletonLoader, Table } from '@/components/derived'

import { useRouting } from '@/hooks'
import { getFromToElements } from '@/utils/pagination'
import { ChannelFilters, useCompanyContext } from '../CompanyDetails.context'
import { useObserver } from '@/modules/observer'

export const ChannelsTable = memo(Component)

const COMPANY_CHANNEL_TABLE = 'company-channel-table'

function Component() {
  const { currentPage } = useRouting()
  const { isLoadingChannels, channels, loadChannels } = useCompanyContext()
  const [channelFilter, setChannelFilter] = useState<ChannelFilters>({
    page: currentPage,
  })
  const { sendMessage } = useObserver()

  const { sendAlert } = Alert.Hook()
  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    text: 'Esta ação não tem como ser desfeita. Todos os valores associados à esse canal serão perdidos',
    confirmText: 'Sim! Apagar canal',
  })

  const { isRunning: isDeleting, run: deleteChannel } = Api.Command<void>(Api.Methods.DELETE, 'channels/:channelID')

  useEffect(() => {
    loadChannels(channelFilter)
  }, [channelFilter])

  useEffect(() => {
    setChannelFilter({ name: channelFilter?.name, page: currentPage })
  }, [currentPage])

  function onConfirmationAccepted(channelID: string): void {
    deleteChannel<void>({
      binds: [['channelID', channelID]],
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Exclusão do canal',
          message: 'Canal excluído com sucesso',
        })
        loadChannels(channelFilter)
      },
    })
  }

  function changePage(page: string): void {
    sendMessage(COMPANY_CHANNEL_TABLE, page)
  }

  function onApplyFilters(channel?: string): void {
    const page = Number(currentPage) > 1 ? '1' : currentPage
    setChannelFilter({ name: channel, page: page })
    changePage(page)
  }

  const columns: ColumnType<ChannelResponse>[] = [
    {
      key: 'circledLogo',
      width: 100,
      render: (_, item) => <TableImage id={item.circledLogoID} />,
    },
    {
      key: 'name',
      title: 'Nome',
      render: (_, item) => <Tooltip value={item.name} $align="flex-start" length={24} />,
    },
    {
      key: 'weight',
      title: 'Peso',
      width: 70,
    },
    {
      key: 'vehicle',
      title: 'Veículo',
      width: 110,
      render: (_, item) => (!item.vehicle || item.vehicle === '' ? '--' : item.vehicle),
    },
    {
      key: 'options',
      width: 50,
      render: (_, item) => (
        <Flex $type="row" $justify="flex-start" $align="stretch" $gap={24}>
          <Button.Root
            $variant="link"
            style={{ color: Tokens.ColorUIDanger }}
            onClick={() => openConfirmation(() => onConfirmationAccepted(item.id))}
            $loading={isDeleting}>
            Excluir
          </Button.Root>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Canais registrados" />
        <Divider.Subtitle
          value={`${getFromToElements(currentPage, channels?.elements.length ?? 0)}/${channels?.total ?? 0} Entrada(s)`}
        />
      </Divider.Root>

      <Divider.Root>
        <ContentFilter
          isLoading={isLoadingChannels}
          onApply={onApplyFilters}
          onReset={onApplyFilters}
          placeholder={'Canal'}></ContentFilter>
      </Divider.Root>

      {isLoadingChannels ?
        <SkeletonLoader amount={10} />
      : <Table
          name={COMPANY_CHANNEL_TABLE}
          isLoading={isLoadingChannels}
          columns={columns}
          data={channels?.elements}
          totalPages={channels?.pages}
        />
      }
    </>
  )
}
