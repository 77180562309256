import { memo } from 'react'

import { Peoples } from '@icon-park/react'

import { CreateSpokespersonRequest } from '@shared/models'

import { Alert, Api } from '@/modules'

import { useInput, useRouting } from '@/hooks'

import { Button, Flex, Form, Input, Switch } from '@/components'
import { ManualCard, Modal } from '@/components/derived'

import { Str } from '@/utils/strings'
import { useCompanyContext } from '../CompanyDetails.context'
import { getDescription } from '../utils'
import { SpokespersonsTable } from './Spokespeople.table'

export const Spokespersons = memo(Component)

function Component() {
  const { companyID, changeQueryParam, currentPage } = useRouting()
  const { company, reloadCompany, reloadSpokesPeople } = useCompanyContext()

  const { sendAlert } = Alert.Hook()

  const { value: name, binder: nameBinder, reset: resetName } = useInput()
  const pjBinder = Switch.Hook(false)

  const { isRunning: isCreatingSpokesperson, run: createSpokesperson } = Api.Command<void>(
    Api.Methods.POST,
    `spokespersons`,
  )

  function onSubmit(): void {
    if (Str.from(name).isBlank) {
      return sendAlert({ type: 'error', title: 'Campo obrigatório', message: 'Nome é obrigatório' })
    }
    const input = {
      companyID,
      name,
      pj: pjBinder.isChecked,
    } as CreateSpokespersonRequest
    createSpokesperson<CreateSpokespersonRequest>({
      input,
      onSuccess,
    })
  }

  function onSuccess() {
    sendAlert({
      type: 'success',
      title: 'Sucesso',
      message: 'Porta-voz cadastrado com sucesso!',
    })
    resetName()
    pjBinder.reset()

    reloadSpokesPeople(currentPage)
  }

  if (!companyID || !company) return null

  return (
    <>
      <ManualCard
        icon={Peoples}
        healthCheck={true}
        title="Porta-vozes"
        description={getDescription(company.counts?.spokespeople)}
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'porta-vozes',
            replaceAll: true,
          })
        }
      />
      <Modal
        controlKey="porta-vozes"
        backToName={company.name}
        title="Porta-vozes"
        subtitle={
          <span>
            Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro do porta-voz.
          </span>
        }
        icon={Peoples}
        beforeClose={reloadCompany}>
        <Form onSubmit={onSubmit} controlParam={isCreatingSpokesperson}>
          <Input label="Nome" placeholder="Nome" {...nameBinder} required />
          <Flex $type="row" $justify="space-between" $align="stretch" $gap={20} $fit>
            <Switch.Root label="É pessoa jurídica*" {...pjBinder} />
            <Button.Root type="submit" $variant="primary" $size={228} $loading={isCreatingSpokesperson}>
              <Button.Text value="Adicionar" />
            </Button.Root>
          </Flex>
        </Form>
        <SpokespersonsTable />
      </Modal>
    </>
  )
}
