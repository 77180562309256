import { ChangeEvent } from 'react'

import { Upload } from '@icon-park/react'

import { Alert } from '@/modules'

import { generateRandomID, decodeFileToString } from '@/utils'

import { Component, ComponentContainer, IconContainer, Input, Label, Placeholder } from './Uploader.styles'

export type UploaderOutput = {
  mime: string
  name: string
  content: string
}

export type UploaderProps = {
  label?: string
  value?: UploaderOutput
  requiredExtensions?: string[]
  required?: boolean
  onUpload: (input: UploaderOutput) => void
}

export function Uploader({ label, value, requiredExtensions, required, onUpload }: UploaderProps) {
  const { sendAlert } = Alert.Hook()

  const id: string = generateRandomID()
  const MAX_FILE_SIZE_10_MB = 10 * 1024 * 1024

  function sendErrorMessage(msg?: string): void {
    sendAlert({
      type: 'error',
      title: 'Erro',
      message: msg ?? 'Ocorreu um erro ao fazer upload do arquivo',
    })
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files
    if (!files || files.length <= 0) return sendErrorMessage('Nenhum arquivo foi carregado')
    const file = files[0]

    if (!file.type.startsWith('image/')) {
      return sendErrorMessage('Somente arquivos de imagem são permitidos.')
    }

    if (file.size > MAX_FILE_SIZE_10_MB) {
      return sendErrorMessage('O arquivo é muito grande. O tamanho máximo permitido é 10MB.')
    }

    decodeFileToString(file)
      .then((decodedFile: string): void => {
        onUpload({
          content: decodedFile,
          name: file.name,
          mime: file.type,
        })
      })
      .catch(sendErrorMessage)
  }

  const extensions: string | undefined =
    requiredExtensions ? requiredExtensions.map((extension) => `.${extension}`).join(', ') : undefined

  return (
    <ComponentContainer>
      {label && <Label>{`${label}${required ? '*' : ''}`}</Label>}
      <Component htmlFor={id}>
        <Placeholder>{value?.name ?? 'nenhum arquivo'}</Placeholder>
        <IconContainer>
          <span>Upload</span>
          <Upload size={22} />
        </IconContainer>
      </Component>
      <Input id={id} type="file" accept={extensions} onChange={handleChange} />
    </ComponentContainer>
  )
}
