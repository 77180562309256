import { useEffect, useState } from 'react'

import {
  CompanyResponse,
  Paged,
  PaginatorRequest,
  UpdatedQueueStatus,
  UploadResponse,
  UploadStatus,
} from '@shared/models'

import { useRouting, useSQS } from '@/hooks'

import { Alert, Api } from '@/modules'
import { UploadProgress } from '@/modules/api/useCommand'

import { truncateString } from '@/utils/strings'

import { Flex } from '@/components'
import { SkeletonLoader } from '@/components/derived'
import { Layout } from '@/components/layout'

import { Routes } from '@/configs'
import { Env } from '@/configs/env'
import { LocalStorageHandler } from '../../utils/LocalStorageHandler'
import { Card, UploadItem } from './components/Card/Card.component'
import { Uploader } from './components/Uploader/Uploader.component'
import { CompanyDetailsContextProvider } from '../CompanyDetails/CompanyDetails.context'

type QueueUploadStatus = {
  uploadID: string
  status: UpdatedQueueStatus
}

const PAGE_SIZE = 15
const INITIAL_PAGE = 1
const MAX_NUMBER_OF_TEN_MESSAGES = 10
const POLLING_INTERVAL_OF_FIVE_SECONDS = 5000
const UPLOAD_RESPONSES_KEY = 'UPLOAD_RESPONSES_KEY'
const localStorageHandler = new LocalStorageHandler<UploadResponse>({
  key: UPLOAD_RESPONSES_KEY,
  existsFn: (itemA, itemB) => itemA && itemA?.id === itemB?.id,
})

const paginator: PaginatorRequest<{
  createdAt: string
}> = {
  size: PAGE_SIZE,
  page: INITIAL_PAGE,
  order: {
    createdAt: 'DESC',
  },
}

export function Uploads() {
  const { companyID, navigate } = useRouting()

  const [currentFile, setCurrentFile] = useState<File | undefined>()
  const [currentUploadStatus, setCurrentUploadStatus] = useState<UploadStatus>(UploadStatus.UPLOADING)
  const [uploadedItems, setUploadedItems] = useState<UploadItem[]>([])

  const { sendAlert } = Alert.Hook()

  const [isLoadingCompany, , company] = Api.Query<CompanyResponse>(`companies/${companyID}`)
  const [loadUploads, isLoadingUploads, loadedUploads] = Api.LazyQuery<Paged<UploadResponse>>(
    `companies/${companyID}/spreadsheets`,
  )
  const { run: uploadSpreadsheet, data: uploadResponse } = Api.Command<UploadResponse>(
    Api.Methods.POST,
    `companies/${companyID}/spreadsheets`,
  )

  const { fetchMessages, deleteMessage, messages } = useSQS<QueueUploadStatus>({
    region: process.env[Env.REACT_APP_AWS_REGION] || '',
    accessKeyId: process.env[Env.REACT_APP_AWS_ACCESS_KEY_ID] || '',
    secretAccessKey: process.env[Env.REACT_APP_AWS_SECRET_ACCESS_KEY] || '',
    queueUrl: process.env[Env.REACT_APP_SPREADSHEET_UPDATED_QUEUE] || '',
    maxNumberOfMessages: MAX_NUMBER_OF_TEN_MESSAGES,
  })

  function handleUpload(file: File, companyClipperID: string): void {
    if (!file) return

    setCurrentFile(file)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('companyClipperID', companyClipperID)

    uploadSpreadsheet<FormData>(
      {
        input: formData,
        onSuccess: (): void => {
          setCurrentFile(undefined)
          loadUploads(paginator)
        },
        onFail: (): void => {
          setCurrentFile(undefined)
          sendAlert(
            {
              type: 'error',
              title: 'Erro no upload!',
              message: 'Ocorreu um erro ao fazer upload da planilha!',
            },
            {
              ttl: 2000,
            },
          )
          removeTemporaryUploadedItem()
        },
      },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: handleUploadProgress,
      },
    )
  }

  function removeTemporaryUploadedItem() {
    if (!uploadedItems?.length) {
      return
    }

    const newUploadedItems = uploadedItems.filter((item) => !item?.id?.includes('TEMP'))
    setUploadedItems(newUploadedItems)
  }

  function handleUploadProgress({ loaded, total }: UploadProgress): void {
    const percent = Math.round((loaded * 100) / (total ?? 0))
    setCurrentUploadStatus(percent < 100 ? UploadStatus.UPLOADING : UploadStatus.UPLOADED)
  }

  function createTemporaryUploadItem() {
    if (!currentFile) {
      return
    }

    const tempUploadedItem: UploadItem = {
      id: 'TEMP-' + (Math.random() * 10000000000).toString(),
      originalFileName: truncateString(currentFile?.name, 25),
      percentage: convertStatusToPercentage(currentUploadStatus),
      status: currentUploadStatus,
    }

    if (!uploadedItems?.length) {
      setUploadedItems([tempUploadedItem])
    }

    const newUploadedItems = [...uploadedItems]
    newUploadedItems.unshift(tempUploadedItem)

    setUploadedItems(newUploadedItems)
  }

  function syncStatusOfUploadedItems(items: UploadItem[]) {
    if (!items?.length || !messages?.length) {
      return
    }

    items.forEach((uploadedItem) => {
      const filtererMessages = filterMessagesById(uploadedItem?.id)

      if (filtererMessages?.length) {
        filtererMessages.forEach((message) => syncUploadedItemStatus(uploadedItem, message?.body))
      }
    })
  }

  function filterMessagesById(id: string | undefined) {
    if (!id) {
      return
    }

    return messages?.filter((message) => message?.body?.uploadID === id)
  }

  function syncUploadedItemStatus(uploadItem: UploadItem, queueUploadStatus: QueueUploadStatus | undefined): void {
    if (!uploadItem || !queueUploadStatus) {
      return
    }

    const newUploadStatus = convertToUploadStatus(queueUploadStatus.status)
    const oldPercentage = convertStatusToPercentage(uploadItem.status)
    const newPercentage = convertStatusToPercentage(newUploadStatus)

    if (newPercentage > oldPercentage) {
      uploadItem.percentage = newPercentage
      uploadItem.status = newUploadStatus
    }
  }

  function convertToUploadStatus(status: UpdatedQueueStatus): UploadStatus {
    if (!status) {
      return UploadStatus.UPLOADED
    }

    const found = Object.entries(UploadStatus)
      .map(([key, value]) => ({
        key,
        value,
      }))
      .find((e) => e?.key?.toString() === String(status))

    return found?.value ? found.value : UploadStatus.UPLOADED
  }

  function convertStatusToPercentage(status: UploadStatus): number {
    if (!status) {
      return 0
    }

    switch (status) {
      case UploadStatus.UPLOADING:
        return 10
      case UploadStatus.UPLOADED:
        return 25
      case UploadStatus.STARTED:
        return 50
      default:
        return 100
    }
  }

  function handleFetchMessages() {
    const uploadPending = uploadedItems?.find((e) =>
      [UploadStatus.STARTED, UploadStatus.UPLOADING, UploadStatus.UPLOADED].includes(e.status),
    )

    if (uploadPending) {
      fetchMessages()
    } else {
      localStorageHandler.clearAll()
    }
  }

  function deleteMessagesOfUploadItens() {
    const uploadResponses = localStorageHandler.getAll()

    uploadResponses?.forEach((item) => {
      if (item?.id) {
        const filtererMessages = filterMessagesById(item?.id)

        if (filtererMessages?.length) {
          filtererMessages.forEach((filtererMessage) => deleteMessage(filtererMessage))
        }
      }
    })
  }

  useEffect(() => {
    if (!uploadResponse?.id) {
      return
    }

    localStorageHandler.save(uploadResponse)
  }, [uploadResponse])

  useEffect(() => {
    createTemporaryUploadItem()
  }, [currentFile])

  useEffect(() => {
    if (!uploadedItems?.length) {
      return
    }

    const newUploadedItems = [...uploadedItems]
    syncStatusOfUploadedItems(newUploadedItems)
    deleteMessagesOfUploadItens()
    deleteMessagesOfUploadItens()
    setUploadedItems(newUploadedItems)
  }, [messages])

  useEffect(() => {
    if (!uploadedItems?.length) {
      return
    }

    const poolingInterval = setInterval(handleFetchMessages, POLLING_INTERVAL_OF_FIVE_SECONDS)
    return () => clearInterval(poolingInterval)
  }, [uploadedItems])

  useEffect(() => {
    const newUploadedItems = loadedUploads?.elements?.map((upload: UploadResponse): UploadItem => {
      return {
        id: upload.id,
        originalFileName: truncateString(upload?.originalFileName, 25),
        percentage: convertStatusToPercentage(upload.status),
        status: upload.status,
        metadata: upload.metadata,
      }
    })

    setUploadedItems(newUploadedItems ? newUploadedItems : [])
  }, [loadedUploads])

  useEffect(() => {
    loadUploads(paginator)
  }, [])

  if (company && !company.healthCheck!.isHealthy) {
    sendAlert({
      type: 'error',
      title: 'Erro no manual',
      message: 'Empresa está com manual incompleto: Favor revisar o manual!',
    })
    navigate(Routes.CompanyDetails.path, companyID!)
  }

  return (
    <CompanyDetailsContextProvider>
      <Layout.Root>
        <Layout.Padder>
          <Flex $type="column" $justify="flex-start" $align="stretch" $gap={36}>
            <Uploader onUpload={handleUpload} requiredExtensions={['csv', 'xls', 'xlsx']} />
            {(isLoadingUploads || isLoadingCompany) && !loadedUploads?.elements.length ?
              <SkeletonLoader />
            : <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  gap: '1.625rem',
                }}>
                {uploadedItems.map((upload) => (
                  <Card key={upload.id} item={upload} />
                ))}
              </div>
            }
          </Flex>
        </Layout.Padder>
      </Layout.Root>
    </CompanyDetailsContextProvider>
  )
}
