import { useState } from 'react'

export interface InputBinder {
  value: string
  onChange: (value: string) => void
}

interface Output {
  value: string
  reset: (value?: string) => void
  binder: InputBinder
}

export function useInput(initial: string = ''): Output {
  const [value, setValue] = useState<string>(initial)

  const reset = (value?: string): void => setValue(value ?? initial)
  const onChange = (value: string): void => setValue(value)

  return {
    value,
    reset,
    binder: {
      value,
      onChange,
    },
  }
}

export function useTimeInput(initial: string = ''): Output {
  const [value, setValue] = useState<string>(parseStringToTime(initial))

  const reset = (value?: string): void => setValue(value ?? initial)

  const onChange = (input: string): void => {
    setValue(parseStringToTime(input))
  }

  return {
    value,
    reset,
    binder: {
      value,
      onChange,
    },
  }
}

function parseStringToTime(input: string): string {
  if (input.trim() === '') return input
  let value = input.replace(/\D/g, '')
  if (value.length >= 3) {
    const hours = parseInt(value.slice(0, 2), 10)
    const minutes = parseInt(value.slice(2, 4), 10)
    if (hours > 23) {
      value = '23' + value.slice(2) // Set to max 23 if exceeded
    }
    if (minutes > 59) {
      value = value.slice(0, 2) + '59' // Set to max 59 if exceeded
    }

    return value.slice(0, 2) + ':' + value.slice(2, 4)
  }
  return value
}
