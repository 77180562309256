import styled, { css } from 'styled-components'

export interface FlexContainerProps {
  $type: 'row' | 'column'
  $justify?: 'flex-start' | 'flex-end' | 'stretch' | 'center' | 'space-between' | 'space-around'
  $align?: 'flex-start' | 'flex-end' | 'stretch' | 'center' | 'space-between'
  $gap?: number
  $fit?: boolean
}

const FlexStyles = {
  row: css`
    flex-direction: row;
  `,

  column: css`
    flex-direction: column;
  `,
}

export const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  ${({ $fit: fit }) =>
    fit &&
    css`
      flex: 1;
    `}
  ${({ $type: type }) => FlexStyles[type]}
  ${({ $justify: justify }) => css`
    justify-content: ${justify ?? 'flex-start'};
  `};
  ${({ $align: align }) => css`
    align-items: ${align ?? 'flex-start'};
  `};
  ${({ $gap: gap }) =>
    gap &&
    css`
      gap: ${gap}px;
    `}
`
