import { Tokens } from '@/assets/tokens'
import styled, { css } from 'styled-components'
import { blinkingAnimation } from './ManualCard.animations'

export const Container = styled.div`
  width: 100%;
  min-height: 84px;
  height: fit-content;
  border-radius: 1.2rem;
  padding: 1.4rem 2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  background-color: ${Tokens.ColorWhite};
`

export const ContainerName = styled.div`
  width: 18rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`

export const LinksContainer = styled.div`
  width: 16rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const HealthCheckStatus = styled.div<{ $status: boolean }>`
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;

  justify-self: flex-start;

  border: 2px solid ${Tokens.ColorUIPrimaryDim};
  box-shadow: 0 0 10px ${Tokens.ColorUIPrimaryDim};
  background-color: ${Tokens.ColorUIPrimary};

  ${({ $status }) =>
    !$status &&
    css`
      border: 2px solid ${Tokens.ColorUIDangerDim};
      box-shadow: 0 0 10px ${Tokens.ColorUIDangerDim};
      background-color: ${Tokens.ColorUIDanger};
      animation: ${blinkingAnimation} 1s ease-in-out infinite;
    `}
`
