import styled, { css } from 'styled-components'

export type SelectorIconContainerProps = {
  color?: string
}

export const SelectorIconContainer = styled.div<SelectorIconContainerProps>`
  & > span {
    padding: unset;
    margin: unset;
  }

  ${({ color }) =>
    color &&
    css`
      & > span {
        color: ${color};
      }
    `}
`

export const CleanButtonContainer = styled.div`
  all: unset;
  width: 0.2rem;
  height: 0.2rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`
