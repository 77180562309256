import { createContext, useContext, useState, ReactNode } from 'react'

type ValuesContextType = {
  values: Record<string, any>
  updateValues: (newValues: any) => void
}

const ValuesContext = createContext<ValuesContextType | undefined>(undefined)

export const useValues = () => {
  const context = useContext(ValuesContext)
  if (!context) {
    throw new Error('useValues must be used within a ValuesProvider')
  }
  return context
}

export const ValuesProvider = ({ children }: { children: ReactNode }) => {
  const [values, setValues] = useState<Record<string, any>>({})

  const updateValues = (newValues: any) => {
    setValues((prevValues) => ({ ...prevValues, ...newValues }))
  }

  return <ValuesContext.Provider value={{ values, updateValues }}>{children}</ValuesContext.Provider>
}
