/* eslint-disable react-refresh/only-export-components */
import { ReactNode, createContext, memo, useCallback, useContext, useEffect, useState } from 'react'

import { SessionResponse, UserResponseRole } from '@shared/models'

import { CognitoIdentityProviderClient, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider'

import { toAsyncResult } from '@/utils/result'

import { Alert } from '../alert'

import { useAuthAPI } from './auth.api'

const client = new CognitoIdentityProviderClient({ region: process.env.REACT_APP_AWS_REGION ?? '' })

interface Output {
  isLoading: boolean
  isAuthorized: boolean
  session?: SessionResponse
  error?: Error
  hasAnyRole: (...roles: UserResponseRole[]) => boolean
  login: (username: string, password: string) => void
  logout: () => void
}

const Context = createContext<Output>({} as Output)

export function useAuth(): Output {
  const authContext = useContext(Context)
  if (!authContext) {
    throw new Error('useAuth deve ser chamado dentro do AuthContext!')
  }
  return authContext
}

export const ContextProvider = memo(ContextProviderComponent)

function ContextProviderComponent({ children }: { children: ReactNode }) {
  const callAuthAPI = useAuthAPI()

  const [isLoading, setLoading] = useState<boolean>(true)
  const [isAuthorized, setAuthorized] = useState<boolean>(false)
  const [session, setSession] = useState<SessionResponse | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)

  const { sendAlert } = Alert.Hook()

  const authorize = useCallback((session: SessionResponse) => {
    setAuthorized(true)
    setSession(session)
    setError(undefined)
    setLoading(false)
  }, [])

  const unAuthorize = useCallback((): void => {
    setAuthorized(false)
    setSession(undefined)
    setLoading(false)
  }, [])

  const loadUser = useCallback(async (): Promise<void> => {
    const [error, session] = await callAuthAPI<SessionResponse>({
      method: 'GET',
      url: '/auth',
    })
    if (error) return unAuthorize()
    return authorize(session)
  }, [])

  async function login(username: string, password: string): Promise<void> {
    setLoading(true)
    const authCommand = new InitiateAuthCommand({
      AuthFlow: 'USER_PASSWORD_AUTH',
      ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID ?? '',
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password,
      },
    })
    const [error, response] = await toAsyncResult(client.send(authCommand))
    if (error) {
      unAuthorize()
      return sendAlert({
        type: 'error',
        title: 'Erro na autenticação',
        message: 'Ocorreu um erro ao autenticar o usuário, favor verifique seu e-mail e senha e tente novamente',
      })
    }
    if (response.AuthenticationResult) {
      //const accessToken = response.AuthenticationResult.AccessToken
      const idToken = response.AuthenticationResult.IdToken
      //const refreshToken = response.AuthenticationResult.RefreshToken
      const [, session] = await callAuthAPI<SessionResponse>({
        method: 'POST',
        url: '/auth',
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      })
      if (session) return authorize(session)
    }
    return unAuthorize()
  }

  function logout(): void {
    setLoading(true)
    toAsyncResult(
      callAuthAPI<SessionResponse>({
        method: 'DELETE',
        url: '/auth',
      }),
    ).then(() => {
      unAuthorize()
    })
  }

  function hasAnyRole(...roles: UserResponseRole[]): boolean {
    if (!session) return false
    if (!roles.length) return false
    return roles.includes(session.userRole)
  }

  useEffect(() => {
    loadUser()
  }, [])

  return (
    <Context.Provider
      value={{
        isLoading,
        isAuthorized,
        session: session,
        error,
        hasAnyRole,
        login,
        logout,
      }}>
      {children}
    </Context.Provider>
  )
}
