import { Tokens } from '@/assets/tokens'
import { styled } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  column-gap: 20px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  row-gap: 4px;

  & > span {
    color: ${Tokens.ColorTextPrimary};
  }

  & > span:first-child {
    line-height: 11.2px;
    font-size: 14px;
    font-weight: 700;
  }

  & > span:last-child {
    line-height: 12px;
    font-size: 12px;
    font-weight: 400;
  }
`

export const ImageContainer = styled.div`
  width: ${Tokens.HeightInput};
  height: ${Tokens.HeightInput};
  border: 1px solid #3d528110;
  border-radius: 12px;
  cursor: default;
`

export const ProfilePic = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 12px;
`
