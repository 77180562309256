import { InputBinder } from '@/hooks'

export function getDescription(count?: number): string {
  if (!count) return 'Sem entradas'
  if (count === 1) return '1 entrada'
  return `${count} entradas`
}

export function handleWeightChange(bind: InputBinder, MIN_WEIGHT: number, MAX_WEIGHT: number, inputValue: string) {
  const value = Number(inputValue)
  if (inputValue && value < MIN_WEIGHT) {
    bind.onChange(MIN_WEIGHT.toString())
    return
  }
  if (inputValue && value > MAX_WEIGHT) {
    bind.onChange(MAX_WEIGHT.toString())
    return
  }
  bind.onChange(inputValue)
}
