import Capitalize from '@stdlib/string-capitalize'

import { Broadcast, Edit } from '@icon-park/react'

import { MentionResponse } from '@shared/models/mentions'

import { Tokens } from '@/assets/tokens'

import { formatNumber } from '@/utils/numbers'
import { truncateString } from '@/utils/strings'

import { Button, Canal, Divider, Flex, Tooltip } from '@/components'
import { ColumnType, SkeletonLoader, Table } from '@/components/derived'
import { Layout } from '@/components/layout'

import { Filters } from './components/Filters/Filters.component'
import { PolarityEmoji } from './components/Polarity/Polarity'
import { Role } from './components/Role/Role'
import { appendAtIfNeeded } from './utils'

import { useObserver } from '@/modules/observer'
import { useEffect, useState } from 'react'
import { MentionsContextProvider, useMentionsContext } from './Mentions.context'
import { MentionsContainer } from './Mentions.styles'
import { EditModal } from './components/EditModal/EditModal.component'
import { CompanyDetailsContextProvider } from '../CompanyDetails/CompanyDetails.context'

export function Mentions() {
  return (
    <CompanyDetailsContextProvider>
      <MentionsContextProvider>
        <MentionsComponent />
      </MentionsContextProvider>
    </CompanyDetailsContextProvider>
  )
}

function MentionsComponent() {
  const { isLoadingMentions, images, mentions, selectMention } = useMentionsContext()
  const { observe, removeObserver } = useObserver()

  const [currMentions, setCurrMentions] = useState<MentionResponse[]>(mentions?.elements ?? [])

  useEffect(() => {
    const observerID = observe('mention_updated', handleMentionUpdate)
    return () => removeObserver('mention_updated', observerID)
  }, [])

  useEffect(() => {
    if (mentions) {
      setCurrMentions(mentions.elements)
    }
  }, [mentions])

  function handleMentionUpdate({ idx, mention }: { idx: number; mention: MentionResponse }): void {
    setCurrMentions((prev) => {
      const newVals = [...prev]
      newVals[idx] = mention
      return newVals
    })
  }

  const columns: ColumnType<MentionResponse>[] = [
    {
      key: 'channel',
      title: 'Canal',
      width: 90,
      render: (_, item) => (
        <Canal
          brandImage={images?.get(item.brandLogo)}
          hyperlink={item.hyperlink}
          channelImage={images?.get(item.channelLogoID)}
        />
      ),
    },
    {
      key: 'publication',
      title: 'Publicação',
      render: (_, item) => (
        <Flex
          $type="column"
          $justify="center"
          $align="flex-start"
          $gap={8}
          style={{
            cursor: 'pointer',
            padding: '1rem 0',
            paddingRight: '0.5rem',
          }}>
          <h4>{appendAtIfNeeded(item.publisher)}</h4>
          <span>{truncateString(item.publication, 250)}</span>
        </Flex>
      ),
    },
    {
      key: 'theme',
      title: 'Tema',
      width: 140,
      align: 'center',
      render: (_, item) => (
        <div
          style={{
            padding: '1rem 0.5rem',
            border: `1px solid ${Tokens.ColorLight}`,
            borderRadius: '0.5rem',
            backgroundColor: Tokens.ColorWhite,
            textAlign: 'center',
          }}>
          <Tooltip value={Capitalize(item.theme)} length={32} />
        </div>
      ),
    },
    {
      key: 'potentialReach',
      title: 'Alcance',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <Flex $type="column" $justify="center" $align="center">
          <Tooltip value={Capitalize(item.publisherType)} length={10} />
          <div
            style={{
              width: '90px',
              height: '1px',
              backgroundColor: Tokens.ColorLight,
            }}
          />
          <Flex $type="row" $justify="center" $align="center" $gap={8}>
            <Broadcast size={16} />
            <span>{formatNumber(item.potentialReach * 0.1)}</span>
          </Flex>
        </Flex>
      ),
    },
    {
      key: 'polarity',
      title: 'Polaridade',
      width: 80,
      align: 'center',
      render: (_, item) => (
        <Flex $type="column" $justify="center" $align="center">
          <PolarityEmoji value={item.polarity} />
        </Flex>
      ),
    },
    {
      key: 'role',
      title: 'Papel',
      align: 'center',
      width: 120,
      render: (_, item) => (
        <Flex $type="column" $justify="center" $align="center">
          <Role value={item.stars} />
          <Tooltip value={Capitalize(item.role.toLowerCase())} length={10} $size={10} />
        </Flex>
      ),
    },
    {
      key: 'edit',
      width: 40,
      render: (_, item, idx) => (
        <Button.Root $variant="link" onClick={(): void => selectMention({ idx, val: item })}>
          <Button.Icon value={Edit} color={Tokens.ColorTextSecondary} />
        </Button.Root>
      ),
    },
  ]

  return (
    <Layout.Root>
      <MentionsContainer>
        <Filters />
        <Divider.Root>
          <Divider.Title value="Tabela de Menções" />
          <Divider.Spacer />
          <Divider.Subtitle value={`${mentions?.total ?? 0} Menções`} />
        </Divider.Root>
        {isLoadingMentions ?
          <SkeletonLoader />
        : <Table data={currMentions} columns={columns} totalPages={mentions?.pages} />}
        <EditModal />
      </MentionsContainer>
    </Layout.Root>
  )
}
