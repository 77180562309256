import { Tokens } from '@/assets/tokens'
import styled, { css, keyframes } from 'styled-components'

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  border-radius: ${Tokens.RadiusInput};
`

export const FilterContainer = styled.div`
  background-color: white;
  align-items: center;
  border-radius: ${Tokens.RadiusInput};
  width: 100%;
  padding: 1rem;
`

const hideTransition = keyframes`
  from {
    opacity: 1;
    visibility: visible;
    max-height: auto;
  }
  to {
    opacity: 0;
    visibility: hidden;
    max-height: 0;
  }
`

const showTransition = keyframes`
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: auto;
  }
`

export const InvisibleArea = styled.div<{ $isOpen: boolean }>`
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: stretch;
  gap: 0.7rem;
  animation: ${hideTransition} 0.2s ease-out forwards;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      display: flex;
      opacity: 1;
      animation: ${showTransition} 0.2s ease-in forwards;
    `}
`
