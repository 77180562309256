import React, { useState } from 'react'
import { Down, Up } from '@icon-park/react'
import { Icon } from '../../../../../src/components/core/Icon/Icon.component'
import {
  AccordionContainer,
  AccordionItemContainer,
  AccordionHeader,
  AccordionIcon,
  AccordionContent,
} from './Accordion.styles'

interface AccordionItemProps {
  title: string
  content: React.ReactNode
}

const AccordionItem: React.FC<AccordionItemProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <AccordionItemContainer>
      <AccordionHeader onClick={toggleAccordion} aria-expanded={isOpen} role="button">
        <h2>{title}</h2>
        <AccordionIcon>
          {isOpen ?
            <Icon value={Up}></Icon>
          : <Icon value={Down}></Icon>}
        </AccordionIcon>
      </AccordionHeader>
      <AccordionContent $isOpen={isOpen}>{content}</AccordionContent>
    </AccordionItemContainer>
  )
}

interface AccordionProps {
  items: AccordionItemProps[]
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  return (
    <AccordionContainer>
      {items.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </AccordionContainer>
  )
}

export default Accordion
