import styled from 'styled-components'

import ReportBG from '@/assets/images/report_card_icon.svg'
import { Tokens } from '@/assets/tokens'

export const ItemsContainer = styled.div`
  margin-top: 1rem;

  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;

  padding: 0 1rem;
`

export const ReportsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 1130px;
  margin: 0 auto;
  margin-top: 0;
  padding: 0 50px 50px;
`

export const NewReportButton = styled.button`
  all: unset;
  cursor: pointer;
  width: 20rem;
  min-height: 10.8rem;
  border-radius: 20px;
  background-color: ${Tokens.ColorUIPrimary};
  background-image: url(${ReportBG});
  background-position: 12.5rem 0.8rem;
  background-size: 112px 114px;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    background-color: ${Tokens.ColorUIPrimaryDim};
  }

  & > span {
    color: ${Tokens.ColorWhite};
    font-size: 16px;
    font-weight: 700;
  }
`
