import { memo } from 'react'

import { Scissors } from '@icon-park/react'

import { CreateCompanyClipperRequest, SearchResponse } from '@shared/models'

import { Alert, Api } from '@/modules'

import { useInput, useRouting } from '@/hooks'

import { Button, Flex, Form, Input, Selector, Switch } from '@/components'
import { ManualCard, Modal } from '@/components/derived'

import { Str } from '@/utils/strings'
import { useCompanyContext } from '../CompanyDetails.context'
import { getDescription } from '../utils'
import { CompanyClippersTable } from './CompanyClippers.table'

export const CompanyClippers = memo(Component)

function Component(): JSX.Element | null {
  const { companyID, changeQueryParam, currentPage } = useRouting()
  const { company, reloadCompany, reloadCompanyClippers } = useCompanyContext()

  const { sendAlert } = Alert.Hook()

  const { selectedOption: selectedClipper, binder: clipperBinder, reset: resetClipper } = Selector.Binder()
  const { value: acronym, binder: acronymBinder, reset: resetAcronym } = useInput()
  const { value: apiKey, binder: apiKeyBinder, reset: resetApiKey } = useInput()
  const { value: url, binder: urlBinder, reset: resetURL } = useInput()
  const monitorAssociatedThemesBinder = Switch.Hook(true)

  const [isSearchingClippers, , clippers] = Api.Query<SearchResponse[]>(`clippers`)
  const { isRunning: isCreatingCompanyClipper, run: createCompanyClipper } = Api.Command<void>(
    Api.Methods.POST,
    `companies/:companyID/clippers/:clipperID`,
  )

  function onSubmit(): void {
    const clipperStr = Str.from(selectedClipper?.value)
    if (clipperStr.isBlank) {
      return sendAlert({ type: 'error', title: 'Campo obrigatório', message: 'Clipper é obrigatório' })
    }

    const input = {
      acronym: Str.from(acronym).value,
      apiKey: Str.from(apiKey).value,
      url: Str.from(url).value,
      monitorAssociatedThemes: monitorAssociatedThemesBinder.isChecked,
    } as CreateCompanyClipperRequest
    createCompanyClipper<CreateCompanyClipperRequest>({
      input,
      binds: [
        ['companyID', companyID!],
        ['clipperID', clipperStr.val],
      ],
      onSuccess,
    })
  }

  function onSuccess() {
    sendAlert({
      type: 'success',
      title: 'Sucesso',
      message: 'Clipadora cadastrada com sucesso!',
    })
    resetClipper()
    resetAcronym()
    resetApiKey()
    resetURL()

    reloadCompanyClippers(currentPage)
  }

  if (!companyID || !company) return null

  return (
    <>
      <ManualCard
        icon={Scissors}
        healthCheck={company.healthCheck?.isClipperChecked}
        title="Clipadoras da Empresa"
        description={getDescription(company.counts?.clippers)}
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'clipadoras',
            replaceAll: true,
          })
        }
      />
      <Modal
        controlKey="clipadoras"
        backToName={company.name}
        title="Clipadoras da Empresa"
        subtitle={
          <span>
            Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro das clipadoras.
          </span>
        }
        icon={Scissors}
        beforeClose={reloadCompany}>
        <Form onSubmit={onSubmit} controlParam={isCreatingCompanyClipper}>
          <Flex $type="row" $justify="space-between" $align="center" $gap={24} $fit>
            <Selector.Default
              label="Clipadora"
              placeholder="Selecione a clipadora"
              options={clippers?.map((element) => ({ ...element })) ?? []}
              loading={isSearchingClippers}
              {...clipperBinder}
              required
            />
            <Input label="Acrônimo" placeholder="Acrônimo" {...acronymBinder} required />
          </Flex>
          <Flex $type="row" $justify="space-between" $align="center" $gap={24} $fit>
            <Input label="Chave da API" placeholder="Chave da API" {...apiKeyBinder} />
            <Input label="URL da API" placeholder="URL da API" {...urlBinder} />
          </Flex>
          <Flex $type="row" $justify="space-between" $align="stretch" $gap={20} $fit>
            <Switch.Root
              label={monitorAssociatedThemesBinder.isChecked ? 'Monitorar tudo' : 'Monitorar apenas marca(s)'}
              {...monitorAssociatedThemesBinder}
            />
            <Button.Root type="submit" $variant="primary" $size={310} $loading={isCreatingCompanyClipper}>
              <Button.Text value="Adicionar" />
            </Button.Root>
          </Flex>
        </Form>
        <CompanyClippersTable />
      </Modal>
    </>
  )
}
