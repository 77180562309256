import styled from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  padding: 1rem;
  background-color: ${Tokens.ColorWhite};
  border-radius: ${Tokens.RadiusInput};
`

export const InputContainer = styled.div`
  width: 12rem;
`

export const ListContainer = styled.div`
  padding: 0.4rem;

  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3rem;

  width: 100%;
  height: fit-content;
  min-height: ${Tokens.HeightInput};
  flex-wrap: wrap;

  background-color: ${Tokens.ColorBackground};
  border-radius: ${Tokens.RadiusInput};
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.2rem;

  padding: 0.5rem 1rem;
  border-radius: ${Tokens.RadiusInput};

  width: fit-content;
  height: fit-content;
  cursor: pointer;

  background-color: ${Tokens.ColorUIPrimary};
  position: relative;

  &:hover {
    background-color: ${Tokens.ColorUIDangerDim};

    & > div {
      background-color: ${Tokens.ColorUIDanger};
    }
  }
`

export const ListItemText = styled.span`
  font-weight: 600;
  color: ${Tokens.ColorWhite};
  margin-right: 0.5rem;
`

export const ItemButton = styled.div`
  width: 0.6rem;
  height: 0.6rem;
  padding-top: 1px;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${Tokens.ColorBorder}55;
  position: absolute;

  top: 0.5rem;
  right: 0.5rem;
`
