import { GlassesOne } from '@icon-park/react'

import React, { memo } from 'react'

import { useRouting } from '@/hooks'

import { Button } from '@/components'
import { ManualCard, Modal } from '@/components/derived'

import { Routes } from '@/configs'
import { useCompanyContext } from '../CompanyDetails.context'
import { CompanyCompetitorsTable } from './CompanyCompetitors.table'
import { getDescription } from '../utils'

const Component: React.FC = (): JSX.Element | null => {
  const { companyID, changeQueryParam, navigate } = useRouting()
  const { company } = useCompanyContext()

  function handleNavigation(): void {
    navigate(`${Routes.Companies.path}?mae=${companyID}`)
  }

  if (!companyID || !company) return null

  return (
    <>
      <ManualCard
        icon={GlassesOne}
        healthCheck={company.healthCheck?.isCompetitorsChecked}
        title="Concorrentes da Empresa"
        description={getDescription(company.counts?.competitors)}
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'concorrentes',
            replaceAll: true,
          })
        }
      />
      <Modal controlKey="concorrentes" backToName={company.name} title="Concorrentes da Empresa" icon={GlassesOne}>
        <Button.Root $variant="primary" onClick={handleNavigation}>
          <Button.Text value="Cadastrar Concorrente" />
        </Button.Root>
        <CompanyCompetitorsTable />
      </Modal>
    </>
  )
}

export const CompanyCompetitors = memo(Component)
