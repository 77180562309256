import { Tokens } from '@/assets/tokens'
import { styled } from 'styled-components'

export const ComponentContainer = styled.div`
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  row-gap: 8px;
  white-space: nowrap;
  overflow: hidden;
`

export const Label = styled.span`
  color: ${Tokens.ColorTextPrimary};
`

export const Component = styled.label`
  width: 100%;
  height: ${Tokens.HeightInput};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
  overflow: hidden;
`

export const Placeholder = styled.span`
  height: 100%;
  padding-left: 16px;
  border-radius: ${Tokens.RadiusInput} 0 0 ${Tokens.RadiusInput};

  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: ${Tokens.ColorTextTertiary};
  background-color: ${Tokens.ColorBackground};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const IconContainer = styled.div`
  width: 7rem;
  height: 100%;
  border-radius: 0 ${Tokens.RadiusInput} ${Tokens.RadiusInput} 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background-color: ${Tokens.ColorUIPrimary};

  & > span {
    font-size: 16px;
    font-weight: 600;
    color: ${Tokens.ColorWhite};
  }
`

export const Input = styled.input`
  all: unset;
  width: 100%;
  height: 100%;
  display: none;
`
