import styled from 'styled-components'

export const MentionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 50px 50px;

  @media (max-width: 1919px) {
    width: 1130px;
  }

  @media (min-width: 1920px) {
    width: 1600px;
  }

  margin: 0 auto;
`
