import React, { ReactNode, RefObject, useEffect, useState } from 'react'

import { Down, Up } from '@icon-park/react'

import loadingImgSrc from '@/assets/images/load.gif'
import { Tokens } from '@/assets/tokens'

import { Icon } from '@/components/core'

import { SelectionOption } from '../Selection.hooks'
import {
  ComponentContainer,
  IconWrapper,
  Input,
  Label,
  SelectionContainer,
  Selector,
  SelectorOptions,
} from './SearchableSelector.styles'
import { CleanButton } from '../Selection.subcomponents'

type Props = {
  label?: string
  placeholder?: string
  options: SelectionOption[]
  onSelect: (value?: SelectionOption) => void
  onSearch: (search: string) => void
  selectedOption?: SelectionOption
  loading?: boolean
  required?: boolean
  children?: ReactNode
  $inverted?: boolean
}

export function SearchableSelector({
  label,
  placeholder,
  options,
  onSelect,
  onSearch,
  selectedOption,
  loading = false,
  required = false,
  children,
  $inverted = false,
}: Props) {
  const ref: RefObject<HTMLDivElement> = React.createRef()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(selectedOption?.text ?? '')

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  useEffect(() => {
    setInputValue(selectedOption?.text ?? '')
  }, [selectedOption])

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>): void {
    const value: string = event.target.value
    onSearch(value)
    setInputValue(value)
  }

  function handleSelection(selectedOption?: SelectionOption): void {
    onSelect(selectedOption)
    setInputValue(selectedOption?.text ?? '')
  }

  const textToShow: string = selectedOption ? selectedOption.text : (placeholder ?? '')

  return (
    <ComponentContainer ref={ref}>
      {label && (
        <Label>
          {label}
          {required && '*'}
        </Label>
      )}
      <SelectionContainer onClick={() => setIsOpen((value) => !value)} $inverted={$inverted}>
        {children}
        <Input placeholder={textToShow} value={inputValue} onChange={handleSearch} />
        {selectedOption && <CleanButton onClick={(): void => handleSelection()} />}
        {loading ?
          <img alt="loading" src={loadingImgSrc} />
        : <IconWrapper>
            <Icon value={isOpen ? Up : Down} size={16} color={$inverted ? Tokens.ColorWhite : Tokens.ColorUIPrimary} />
          </IconWrapper>
        }
        {options.length > 0 && (
          <Selector $show={isOpen}>
            {options.map((option: SelectionOption) => (
              <SelectorOptions key={option.value} onClick={() => handleSelection(option)}>
                {option.text}
              </SelectorOptions>
            ))}
          </Selector>
        )}
      </SelectionContainer>
    </ComponentContainer>
  )
}
