import { Tokens } from '@/assets/tokens'
import styled, { css } from 'styled-components'

export type ContainerAlignment = {
  $align?: 'flex-start' | 'center' | 'flex-end'
}

export type ContentSize = {
  $size?: number
}

export const Container = styled.div<ContainerAlignment>`
  position: relative;
  display: flex;
  align-items: center;

  ${({ $align: align }) => css`
    justify-content: ${align};
  `}

  &:hover > span:nth-child(2) {
    visibility: visible;
  }
`

export const TruncatedContent = styled.span`
  color: ${Tokens.ColorTextPrimary};
  text-align: center;
`

export const Content = styled.span<ContentSize>`
  visibility: hidden;
  width: fit-content;
  min-width: 10rem;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 0.7rem;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  left: 105%;
  z-index: 10;

  ${({ $size: size }) =>
    !!size &&
    css`
      min-width: ${size}rem;
    `}
`
