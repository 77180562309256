import { ElementType } from 'react'

interface IconProps {
  value: ElementType
  size?: number
  color?: string
}

export function Icon({ value: Element, size, color }: IconProps) {
  return (
    <Element
      size={size}
      style={{
        color,
      }}
    />
  )
}
