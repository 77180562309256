export function exportDate(date: Date): string {
  const day: string = String(date.getDate()).padStart(2, '0')
  const month: string = String(date.getMonth() + 1).padStart(2, '0')
  const year: number = date.getFullYear()
  return `${day}/${month}/${year}`
}

export function getMonthDays(date: Date): [string, string, string] {
  const year: number = date.getFullYear()
  const month: number = date.getMonth()
  const day: number = date.getDate()

  const firstDay = new Date(year, month, 1)
  const firstDayStr: string = firstDay.toLocaleDateString('pt-BR')

  const lastDay = new Date(year, month + 1, 0) // dia 0 retorna o último dia do mês anterior
  const lastDayStr: string = lastDay.toLocaleDateString('pt-BR')

  const today = new Date(year, month, day)
  const todayStr: string = today.toLocaleDateString('pt-BR')

  return [firstDayStr, lastDayStr, todayStr]
}
