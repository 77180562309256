import { useMemo } from 'react'
import { NavigateFunction, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Route, RouteParams, Routes } from '@/configs'

type QueryParams = { [key: string]: any }

type ChangeQueryParamsInput = {
  param: string
  value?: string
  replaceAll?: boolean
}

interface Output {
  route: Route
  queryParams: QueryParams
  currentPage: string
  companyID?: string

  back: () => void
  changeQueryParam: (input: ChangeQueryParamsInput) => void
  navigate: (route: Route | string, ...variables: string[]) => void
}

export function useRouting(): Output {
  const navigator: NavigateFunction = useNavigate()
  const [params, setSearchParams] = useSearchParams()
  const { companyID } = useParams<RouteParams>()
  const { pathname } = useLocation()

  const currentPage = params.get('pagina') ?? '1'

  const queryParams: QueryParams = {}
  params.forEach((value, key) => {
    queryParams[key] = value
  })

  const route: Route | undefined = useMemo(
    (): Route | undefined =>
      Object.values(Routes).find((route: Route): boolean => {
        if (route.path === pathname) return true

        const pathParts = pathname.split('/')
        const routeParts = route.path.split('/')
        if (pathParts.length !== routeParts.length) return false
        for (let i = 0; i < routeParts.length; i++) {
          if (routeParts[i] === pathParts[i]) continue
          if (routeParts[i].startsWith(':')) continue
          if (routeParts[i] !== pathParts[i]) return false
        }
        return true
      }),
    [pathname],
  )

  if (!route) throw new Error('Não foi possível encontrar a rota')

  function back(): void {
    navigator(pathname)
  }

  function changeSearchParams(state: URLSearchParams, param: string, value?: string): URLSearchParams {
    if (value) state.set(param, value)
    else state.delete(param)
    return state
  }

  function changeQueryParam(input: ChangeQueryParamsInput): void {
    setSearchParams((currentState): URLSearchParams => {
      return changeSearchParams(input.replaceAll ? new URLSearchParams() : currentState, input.param, input.value)
    })
  }

  function navigate(route: Route | string, ...variables: string[]): void {
    const path = typeof route === 'string' ? route : route.path
    if (!variables) return navigator(path)
    let varIndex = 0
    const parsedPath = path.replace(/((?<=\/):[^/]*)/g, () => {
      return variables[varIndex++]
    })
    return navigator(parsedPath)
  }

  return {
    route,
    queryParams,
    currentPage,
    companyID,

    back,
    changeQueryParam,
    navigate,
  }
}
