type ExistsFn<T> = (itemA: T, itemB: T) => boolean

interface CreateLocalStorageHandler<T> {
  key: string | undefined
  existsFn: ExistsFn<T>
}

export class LocalStorageHandler<T> {
  private saving = false
  private encodeKey: string
  private existsFn: ExistsFn<T>

  constructor(params: CreateLocalStorageHandler<T>) {
    if (!params) {
      throw new Error('params must be provided')
    }

    if (!params.key) {
      throw new Error('key must be provided')
    }

    if (!params.existsFn) {
      throw new Error('ExistsFn function must be provided')
    }

    this.encodeKey = btoa(params.key)
    this.existsFn = params.existsFn
  }

  save(item: T): void {
    if (!item) {
      return
    }

    const list = this.getAll()

    if (!list.find((e) => this.existsFn(e, item))) {
      list.push(item)
      this.saveAll(list)
    }
  }

  getAll(): T[] {
    const value = localStorage.getItem(this.encodeKey)

    if (!value) {
      return []
    }

    try {
      return JSON.parse(atob(value))
    } catch {
      return []
    }
  }

  remove(item: T): void {
    if (!item || this.saving) {
      return
    }

    const list = this.getAll()
    const itemIndex = list?.findIndex((e) => this.existsFn(e, item))

    if (itemIndex >= 0) {
      list.splice(itemIndex, 1)
      this.saveAll(list)
    }
  }

  clearAll(): void {
    if (this.saving) {
      return
    }

    localStorage.removeItem(this.encodeKey)
  }

  saveAll(items: T[]): void {
    this.saving = true
    try {
      const value = btoa(JSON.stringify(items || []))
      localStorage.setItem(this.encodeKey, value)
    } finally {
      this.saving = false
    }
  }
}
