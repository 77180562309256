/* eslint-disable react-refresh/only-export-components */
import { Flex } from '@/components'
import { ReactNode } from 'react'
import { DividerSpacer } from './Divider.styles'

export const Divider = {
  Root: DividerRoot,
  Spacer: DividerSpacer,
  Title: DividerTitle,
  Subtitle: DividerSubtitle,
}

function DividerRoot({ children: Children }: { children?: ReactNode }) {
  return (
    <Flex $type="row" $justify="space-between" $align="center" $gap={16}>
      {Children}
    </Flex>
  )
}

function DividerTitle({ value }: { value: string }) {
  return <h2>{value}</h2>
}

function DividerSubtitle({ value }: { value: string }) {
  return <span>{value}</span>
}
