import styled, { css } from 'styled-components'

import { Tokens } from '@/assets/tokens'

interface PathProps {
  $active: boolean
}

export const SvgPath = styled.path<PathProps>`
  fill: #dee2ef;

  ${({ $active: active }) =>
    active &&
    css`
      fill: ${Tokens.ColorUIWarn};
    `}
`
