export function generateUniqueID(): string {
  const letters: string = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numbers: string = '0123456789'
  const chars: string = letters + numbers

  let uniqueString: string = ''

  uniqueString += letters[Math.floor(Math.random() * letters.length)]

  for (let i: number = 1; i < 8; i++) {
    const randomIndex: number = Math.floor(Math.random() * chars.length)
    uniqueString += chars[randomIndex]
  }

  return uniqueString
}
