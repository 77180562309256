import { PictureOne } from '@icon-park/react'

import { UploaderOutput } from '@/components'

import { Logo, LogoContainer } from './UploaderViewer.styles'

interface UploaderViewerProps {
  value?: UploaderOutput
}

export function UploaderViewer({ value }: UploaderViewerProps) {
  return (
    <LogoContainer>
      {value ?
        <Logo alt={value.name} src={value.content} />
      : <PictureOne theme="filled" size={38} />}
    </LogoContainer>
  )
}
