import { Navigate } from 'react-router-dom'

import eyeSrc from '@/assets/images/eye_icon.svg'
import eyeSlashSrc from '@/assets/images/eye_slash_icon.svg'
import { useAuth } from '@/modules/auth/auth.context'

import { Button, Flex, Form, Input, InputPassword } from '@/components'
import { Layout } from '@/components/layout'
import { Routes } from '@/configs'
import { useInput, useRouting } from '@/hooks'
import { useState } from 'react'

export function Login() {
  const { navigate } = useRouting()
  const { login, isLoading, isAuthorized } = useAuth()
  const { value: username, binder: bindUsername } = useInput(process.env.REACT_APP_DEFAULT_USER || '')
  const { value: password, binder: bindPassword } = useInput(process.env.REACT_APP_DEFAULT_PASSWORD || '')
  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = () => {
    if (!username || !password) {
      alert('Preencha os campos para fazer o login!')
      return
    }
    login(username, password)
  }

  if (isAuthorized) return <Navigate to={Routes.Home.path} />

  return (
    <Layout.Login>
      <Layout.Padder>
        <Flex $type="row" $justify="center" $align="center" $gap={24} $fit>
          <Form title="Acessar Conta" onSubmit={onSubmit}>
            <Flex $type="column" $gap={24} style={{ minWidth: '400px' }}>
              <Input label="Usuário" placeholder="Digite seu usuário" {...bindUsername} required />
              <InputPassword
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                placeholder="Digite sua senha"
                {...bindPassword}
                required
                iconPassword={showPassword ? eyeSlashSrc : eyeSrc}
                onIconClick={() => setShowPassword(!showPassword)}
              />
            </Flex>
            <Flex $type="row" $justify="space-between" style={{ marginTop: '16px' }}>
              <Button.Root $variant="outlined" $size={150} onClick={() => navigate(Routes.Welcome.path)}>
                <Button.Text value="Voltar" />
              </Button.Root>
              <Button.Root $variant="primary" $size={150} type="submit" $loading={isLoading && !isAuthorized}>
                <Button.Text value="Entrar" />
              </Button.Root>
            </Flex>
          </Form>
        </Flex>
      </Layout.Padder>
    </Layout.Login>
  )
}
