export async function decodeFileToString(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (): void => {
      if (typeof reader.result === 'string') resolve(reader.result)
      else reject(new Error('erro ao converter arquivo em texto'))
    }

    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}
