import { useRouting } from '@/hooks/useRouting'
import { DoubleLeft, DoubleRight, Left, Right } from '@icon-park/react'
import { Container, Navigator } from './Paginator.styles'
import { useObserver } from '@/modules/observer'
import { useEffect } from 'react'

interface PaginatorProps {
  totalPages?: number
  paramName?: string
  name?: string
}

/**
 * currentPageParamName = Nome da variável da URL que o paginator vai manipular durante a paginação.
 */
export function Paginator({ name, totalPages, paramName = 'pagina' }: PaginatorProps) {
  const { queryParams, changeQueryParam } = useRouting()
  const { observe, removeObserver } = useObserver()

  const setPage = (page: number) => {
    changeQueryParam({
      param: paramName,
      value: String(page),
    })
  }

  useEffect(() => {
    if (!name) return

    const id = observe(name, (page) => {
      const parsedPage = parseInt(page)
      if (!parsedPage) return
      setPage(parsedPage)
    })

    return () => removeObserver(name, id)
  }, [])

  if (!totalPages) return null
  if (totalPages < 2) return null

  const currentPage = Number(queryParams[paramName] ?? '1')
  let maxLeft =
    totalPages === 1 ? 1
    : totalPages === 2 ? 1
    : currentPage - Math.floor(3 / 2)
  let maxRight =
    totalPages === 1 ? 1
    : totalPages === 2 ? 2
    : currentPage + Math.floor(3 / 2)

  if (maxLeft < 1) {
    maxLeft = 1
    maxRight = 3
  }

  if (maxRight > totalPages) {
    maxLeft = totalPages - 2
    maxRight = totalPages
  }

  const length = maxRight - maxLeft + 1
  const pages: number[] = Array.from({ length }, (_, index) => index + maxLeft)

  return (
    <Container>
      <Navigator onClick={() => setPage(1)} $disabled={pages.includes(1)}>
        <DoubleLeft />
      </Navigator>
      <Navigator onClick={() => setPage(currentPage - 1)} $disabled={currentPage === 1}>
        <Left />
      </Navigator>
      <Container>
        {pages.map((page) => (
          <Navigator key={page} onClick={() => setPage(page)} $active={currentPage === page}>
            {page}
          </Navigator>
        ))}
      </Container>
      <Navigator onClick={() => setPage(currentPage + 1)} $disabled={currentPage === totalPages}>
        <Right />
      </Navigator>
      <Navigator onClick={() => setPage(totalPages)} $disabled={pages.includes(totalPages)}>
        <DoubleRight />
      </Navigator>
    </Container>
  )
}
