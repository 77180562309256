import styled, { css } from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const Component = styled.div`
  width: 20.5rem;
  height: 8rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 1rem;

  border-radius: calc(${Tokens.RadiusInput} * 2);
  background-color: ${Tokens.ColorWhite};
  padding: 1.5rem;
`

interface ProgressBarProps {
  $percentage: number
  $color: string
}

export const ProgressBar = styled.div<ProgressBarProps>`
  position: relative;
  width: 100%;
  height: 0.9rem;
  background-color: ${Tokens.ColorBorder};
  border-radius: 5px;

  ${({ $percentage: percentage, $color: color }) => css`
    &::after {
      position: absolute;
      content: '';
      background-color: ${color};
      width: ${percentage}%;
      height: 100%;
      border-radius: 5px;
      left: 0;
      transition: all 0.5s ease-in-out;
    }
  `}
`
