/* eslint-disable react-refresh/only-export-components */
import { ReactNode, createContext, memo, useContext, useState } from 'react'

import { generateRandomID } from '@/utils/idGenerator'

import { AlertType } from './alert.styles'

interface Message {
  type: AlertType
  title: string
  message: string
}

interface Options {
  ttl?: number
}

interface Output {
  alerts?: [string, Message][]
  sendAlert: (message: Message, options?: Options) => void
  closeAlert: (key: string) => void
}

const Context = createContext<Output>({} as Output)

export function useAlert(): Output {
  const alertContext = useContext(Context)
  if (!alertContext) throw new Error('useAlert deve ser chamado dentro do contexto correto!')
  return alertContext
}

export const ContextProvider = memo(ContextProviderComponent)

function ContextProviderComponent({ children }: { children: ReactNode }) {
  const [alerts, setAlerts] = useState<[string, Message][]>([])

  function send(alert: Message, options?: Options): void {
    const id = generateRandomID()
    setAlerts((alerts) => [...alerts, [id, alert]])

    if (!options) {
      setTimeout(() => {
        setAlerts((alerts) => {
          return alerts.filter(([alertKey]) => {
            return id !== alertKey
          })
        })
      }, 4500)
    }
    if (options?.ttl) {
      setTimeout(() => {
        setAlerts((alerts) => {
          return alerts.filter(([alertKey]) => {
            return id !== alertKey
          })
        })
      }, options.ttl)
    }
  }

  return (
    <Context.Provider
      value={{
        alerts,
        sendAlert: send,
        closeAlert: (key: string) =>
          setAlerts((alerts) => {
            return alerts.filter(([alertKey]) => {
              return key !== alertKey
            })
          }),
      }}>
      {children}
    </Context.Provider>
  )
}
