import { memo, useEffect } from 'react'

import { DeleteFive } from '@icon-park/react'

import { ThemeGroupResponse } from '@shared/models'

import { Tokens } from '@/assets/tokens'

import { Alert, Api, Confirmation } from '@/modules'

import { Button, Divider, Flex } from '@/components'
import { ColumnType, Table } from '@/components/derived'

import { useRouting } from '@/hooks'
import { getFromToElements } from '@/utils/pagination'
import { useCompanyContext } from '../CompanyDetails.context'

export const ThemeGroupsTable = memo(Component)

function Component() {
  const { currentPage } = useRouting()
  const { isLoadingThemeGroups, themeGroups, reloadThemeGroups } = useCompanyContext()

  const { sendAlert } = Alert.Hook()
  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    text: 'Esta ação não tem como ser desfeita. Todos os valores associados à esse grupo temático serão perdidos',
    confirmText: 'Sim! Apagar grupo temático',
  })

  const { isRunning: isDeleting, run: deleteThemeGroup } = Api.Command<void>(Api.Methods.DELETE, 'theme-groups/:id')

  useEffect(() => {
    reloadThemeGroups(currentPage)
  }, [currentPage])

  function onConfirmationAccepted(id: string) {
    deleteThemeGroup<void>({
      binds: [['id', id]],
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Exclusão do grupo temático',
          message: 'Grupo excluído com sucesso',
        })
        reloadThemeGroups(currentPage)
      },
    })
  }

  const columns: ColumnType<ThemeGroupResponse>[] = [
    {
      key: 'name',
      title: 'Nome',
    },
    {
      key: 'weight',
      title: 'Peso',
      width: 80,
      render: (_, item) => item.weight ?? '--',
    },
    {
      key: 'options',
      width: 137,
      render: (_, item) => (
        <Flex $type="row" $justify="flex-start" $align="stretch" $gap={24}>
          <Button.Root
            $variant="link"
            style={{ color: Tokens.ColorUIDanger }}
            onClick={() => openConfirmation(() => onConfirmationAccepted(item.id))}
            $loading={isDeleting}>
            Excluir
          </Button.Root>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Grupos temáticos registrados" />
        <Divider.Spacer />
        <Divider.Subtitle
          value={`${getFromToElements(currentPage, themeGroups?.elements.length ?? 0)}/${themeGroups?.total ?? 0} Entrada(s)`}
        />
      </Divider.Root>
      <Table
        isLoading={isLoadingThemeGroups}
        columns={columns}
        data={themeGroups?.elements}
        totalPages={themeGroups?.pages}
      />
    </>
  )
}
