import { Tokens } from '@/assets/tokens'
import styled, { css } from 'styled-components'

export const SlideImg = styled.img`
  width: 100%;
  border-radius: ${Tokens.RadiusInput};
`

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 2rem;
  border-radius: ${Tokens.RadiusInput};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  background-color: ${Tokens.ColorWhite};

  & > div:first-child {
    gap: unset;
  }
`

export const RemovableThemesContainer = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
`

export const ThemeItem = styled.span<{ $selected: boolean }>`
  width: fit-content;
  height: fit-content;
  padding: 0.8rem;
  border-radius: ${Tokens.RadiusInput};

  background-color: ${Tokens.ColorBackgroundSecondary};
  color: ${Tokens.ColorTextSecondaryForeground};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${Tokens.ColorUIPrimary};
      color: ${Tokens.ColorWhite};
    `}
`

export const RemovableThemesContainerFooter = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export const ButtonsGroup = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    & > button {
      width: 10rem;
    }
  }

  & > button {
    width: 10rem;
  }
`

export const SpokespeopleSelectorContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 2rem;
  border-radius: ${Tokens.RadiusInput};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;

  background-color: ${Tokens.ColorWhite};
`
