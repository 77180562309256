import React, { ButtonHTMLAttributes, ElementType } from 'react'
import { ButtonContainer, ButtonProps } from './Button.styles'

interface Props extends ButtonProps, ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ElementType
  text?: string
}

export const Button: React.FC<Props> = ({ icon: Icon, text, ...rest }) => {
  return (
    <ButtonContainer {...rest}>
      {Icon && <Icon />}
      {text && text}
    </ButtonContainer>
  )
}
