import { AccordionIcon } from '@/pages/Dashboard/components/Accordion/Accordion.styles'
import { useState } from 'react'
import { FilterContainer, FilterGroup, InvisibleArea } from './Filters.styles'
import { Button, Icon, Input, Selector } from '@/components'
import { Calendar, Down, Up } from '@icon-park/react'
import { FilterGroupLine } from '@/pages/Mentions/components/Filters/Filters.styles'
import { useReportsContext } from '../../Reports.context'
import { Str } from '@/utils/strings'

export function Filters() {
  const {
    loadingReports,
    applyFilters,
    resetFilters,
    startDateBinder,
    typeBinder,
    monitoringTypeBinder,
    types,
    monitoringTypes,
  } = useReportsContext()
  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  const [isOpen, setIsOpen] = useState(false)

  return (
    <FilterGroup>
      <FilterContainer>
        <div
          style={{
            width: '99.5%',
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '10px',
          }}>
          Filtros
          <div
            style={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
            }}>
            <button
              onClick={resetFilters}
              style={{
                color: '#C7C7C7',
                cursor: 'pointer',
                background: 'none',
                border: 'none',
                padding: 0,
                outline: 'none',
              }}>
              Limpar Filtros
            </button>
            <button
              onClick={toggleAccordion}
              style={{
                display: 'flex',
                gap: '2px',
                cursor: 'pointer',
                color: '#C7C7C7',
                background: 'none',
                border: 'none',
                padding: 0,
                outline: 'none',
              }}>
              <span style={{ color: '#01c38d' }}>{isOpen ? 'Menos' : 'Mais'}</span>
              <AccordionIcon style={{ fill: '#01c38d', marginTop: '5px' }}>
                {isOpen ?
                  <Icon value={Up} color="#01c38d"></Icon>
                : <Icon value={Down} color="#01c38d"></Icon>}
              </AccordionIcon>
            </button>
          </div>
        </div>
        <InvisibleArea $isOpen={isOpen}>
          <FilterGroupLine
            style={{
              display: 'flex',
              gap: '10px',
              width: '100%',
            }}>
            <Input icon={Calendar} placeholder="Inicio" {...startDateBinder} />
            <Selector.Default
              placeholder="Tipo"
              options={
                monitoringTypes?.map((item) => ({
                  text: Str.from(item.text).initCap,
                  value: item.value,
                })) ?? []
              }
              {...monitoringTypeBinder}
            />
            <Selector.Default
              placeholder="Fonte"
              options={
                types?.map((item) => ({
                  text: Str.from(item.text).initCap,
                  value: item.value,
                })) ?? []
              }
              {...typeBinder}
            />
            <Button.Root $variant="primary" onClick={applyFilters} style={{ width: '650px' }} $loading={loadingReports}>
              <Button.Text value="Filtrar" />
            </Button.Root>
          </FilterGroupLine>
        </InvisibleArea>
      </FilterContainer>
    </FilterGroup>
  )
}
