import { Tokens } from '@/assets/tokens'
import styled, { css, keyframes } from 'styled-components'

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 100%;
  border-radius: ${Tokens.RadiusInput};
`

export const MentionsFilterContainer = styled.div`
  background-color: white;
  align-items: center;
  border-radius: ${Tokens.RadiusInput};
  width: 100%;
`

export const MentionsFilterItemContainer = styled.div`
  border-bottom: 1px solid #ddd;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
  width: 100%;
`

export const MentionsFilterHeader = styled.div`
  border-radius: ${Tokens.RadiusInput};
  width: 99%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-direction: column;

  h2 {
    margin: 0;
    font-size: 1em;
    font-weight: normal;
  }
`

const hideTransition = keyframes`
  from {
    opacity: 1;
    display: flex;
    visibility: visible;
  }
  to {
    opacity: 0;
    display: none;
    visibility: collapse;
  }
`

const showTransition = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const InvisibleArea = styled.div<{ $isOpen: boolean }>`
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: stretch;
  gap: 0.7rem;
  animation: ${hideTransition} 0.2s ease-out forwards;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      display: flex;
      opacity: 1;
      animation: ${showTransition} 0.2s ease-in forwards;
    `}
`

export const FilterGroupLine = styled.div`
  display: flex;
  align-items: flex-start;

  flex-direction: row;
  justify-content: flex-start;

  width: 100%;
  box-sizing: border-box;
  flex-wrap: nowrap;

  & > * {
    flex: 1;
    min-width: 0;
    max-width: 100%;
    box-sizing: border-box;
  }
`
