import { Earth, Magic } from '@icon-park/react'

import { FormEvent, memo } from 'react'

import { CreateChannelRequest, InvokeWizard, SearchResponse } from '@shared/models'

import { Alert, Api } from '@/modules'

import { useFileUploader, useInput, useRouting } from '@/hooks'

import { Button, Flex, Form, Input, Selector, Uploader } from '@/components'
import { ManualCard, Modal, UploaderViewer } from '@/components/derived'

import { Nbr } from '@/utils/numbers'
import { Str } from '@/utils/strings'
import { useCompanyContext } from '../CompanyDetails.context'
import { LogoContainer } from '../CompanyDetails.styles'
import { ChannelsTable } from './Channels.table'
import { getDescription, handleWeightChange } from '../utils'

const MIN_WEIGHT = 1
const MAX_WEIGHT = 5
export const Channels = memo(Component)

function Component() {
  const { companyID, changeQueryParam, currentPage } = useRouting()
  const { company, reloadCompany, isLoadingChannels, loadChannels } = useCompanyContext()

  const { sendAlert } = Alert.Hook()

  const { value: name, binder: nameBinder, reset: resetName } = useInput()
  const { value: mediaLink, binder: mediaLinkBinder, reset: resetMediaLink } = useInput()
  const { value: weight, binder: weightBinder, reset: resetWeight } = useInput('2')
  const { selectedOption: selectedVehicle, binder: vehicleBinder, reset: resetVehicle } = Selector.Binder()
  const { value: tier, binder: tierBinder, reset: resetTier } = useInput()
  const { value: price, binder: priceBinder, reset: resetPrice } = useInput()
  const { value: country, binder: countryBinder, reset: resetCountry } = useInput()
  const { value: region, binder: regionBinder, reset: resetRegion } = useInput()
  const { value: state, binder: stateBinder, reset: resetState } = useInput()
  const { value: roundedLogo, bind: roundedLogoBinder, reset: resetRoundedLogo } = useFileUploader()
  const { value: thumbnail, bind: thumbnailBinder, reset: resetThumbnail } = useFileUploader()

  const [isSearchingVehicles, searchVehicle, searchedVehicles = []] = Api.Query<SearchResponse[]>(`vehicles/search`)

  const { isRunning: isCreatingChannel, run: createChannel } = Api.Command<void>(Api.Methods.POST, `channels`)
  const { isRunning: isInvokingWizard, run: invokeWizard } = Api.Command<void>(Api.Methods.POST, `channels/wizard`)

  function sendRequiredFieldError(field: string): void {
    sendAlert({ type: 'error', title: 'Campo obrigatório', message: `Campo '${field}' é obrigatório` })
  }

  function onSubmit(): void {
    if (!companyID) {
      return sendRequiredFieldError('ID da empresa')
    }

    const nameStr = Str.from(name)
    if (nameStr.isBlank) {
      return sendRequiredFieldError('Nome do canal')
    }

    const mediaLinkStr = Str.from(mediaLink)

    const weightNbr = Nbr.from(weight)
    if (weightNbr.isBlank) {
      return sendRequiredFieldError('Peso')
    }

    const vehicleStr = Str.from(selectedVehicle?.value)
    if (vehicleStr.isBlank) {
      return sendRequiredFieldError('Veículo')
    }

    if (!roundedLogo) {
      return sendRequiredFieldError('Logo circular')
    }

    if (!thumbnail) {
      return sendRequiredFieldError('Thumbnail')
    }

    const input = {
      companyID,
      name: nameStr.val,
      mediaLink: mediaLinkStr.val,
      weight: weightNbr.val,
      vehicle: vehicleStr.val,
      tier: Nbr.from(tier).value,
      price: Nbr.from(price).value,
      country: Str.from(country).value,
      region: Str.from(region).value,
      state: Str.from(state).value,
      roundedLogo,
      thumbnail,
    } as CreateChannelRequest

    createChannel<CreateChannelRequest>({
      input,
      onSuccess: () => onSuccess('Canal cadastrado com sucesso!'),
    })
  }

  function onSuccess(message: string) {
    sendAlert({
      type: 'success',
      title: 'Sucesso',
      message,
    })
    resetName()
    resetMediaLink()
    resetWeight()
    resetVehicle()
    resetTier()
    resetPrice()
    resetCountry()
    resetRegion()
    resetState()
    resetRoundedLogo()
    resetThumbnail()
    loadChannels({ page: currentPage })
  }
  function handleWizardClick(event: FormEvent): void {
    event.preventDefault()
    const input = {
      companyID,
    } as InvokeWizard
    invokeWizard<InvokeWizard>({
      input,
      onSuccess: () => onSuccess('Wizard executado com sucesso'),
    })
  }

  if (!companyID || !company) return null

  return (
    <>
      <ManualCard
        icon={Earth}
        healthCheck={company.healthCheck?.isChannelsChecked}
        title="Canais Monitorados"
        description={getDescription(company.counts?.channels)}
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'canais',
            replaceAll: true,
          })
        }
      />
      <Modal
        controlKey="canais"
        backToName={company.name}
        title="Canais Monitorados"
        subtitle={
          <span>
            Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro do canal.
          </span>
        }
        icon={Earth}
        beforeClose={reloadCompany}>
        <Form onSubmit={onSubmit} controlParam={isCreatingChannel}>
          <Input label="Nome do canal" placeholder="Nome" {...nameBinder} required />
          <Flex $type="row" $align="flex-start" $gap={24}>
            <Input label="Endereço web do canal" placeholder="Link do Canal" {...mediaLinkBinder} />
            <Input
              label="Peso"
              placeholder="Peso"
              type="number"
              maxLength={3}
              {...weightBinder}
              required
              onChange={(value: string) => handleWeightChange(weightBinder, MIN_WEIGHT, MAX_WEIGHT, value)}
            />
          </Flex>
          <Flex $type="row" $align="flex-start" $gap={24}>
            <Selector.Searchable
              {...vehicleBinder}
              label="Veículo"
              placeholder="Veículo"
              options={searchedVehicles.map((vehicle) => ({ ...vehicle }))}
              onSearch={(searchParam: string): void => {
                searchVehicle({ name: searchParam })
              }}
              loading={isSearchingVehicles}
              required
            />
            <Input
              label="Tier"
              placeholder="Tier"
              type="number"
              maxLength={3}
              min={1}
              max={3}
              {...tierBinder}
              required
            />
          </Flex>
          <Flex $type="row" $justify="center" $align="center" $gap={24} $fit>
            <LogoContainer>
              <Uploader
                label="Logo circular (.svg 40px/40px)"
                {...roundedLogoBinder}
                requiredExtensions={['svg']}
                required
              />
              <UploaderViewer value={roundedLogo} />
            </LogoContainer>
            <LogoContainer>
              <Uploader
                label="Logo por extenso (.svg 203px/50px)"
                {...thumbnailBinder}
                requiredExtensions={['svg']}
                required
              />
              <UploaderViewer value={thumbnail} />
            </LogoContainer>
          </Flex>
          <Flex $type="row" $justify="space-between" $align="flex-start" $gap={24}>
            <Input preMask="R$" label="Preço base" placeholder="Preço" type="number" maxLength={10} {...priceBinder} />
            <Input label="País" placeholder="País" {...countryBinder} />
          </Flex>
          <Flex $type="row" $justify="space-between" $align="flex-start" $gap={24}>
            <Input label="Região" placeholder="Região" {...regionBinder} />
            <Input label="Estado" placeholder="Estado" maxLength={2} {...stateBinder} />
          </Flex>
          <Flex $type="row" $justify="flex-end" $align="stretch" $gap={20} $fit>
            {!isLoadingChannels && (
              <Button.Root $variant="outlined" onClick={handleWizardClick} $loading={isInvokingWizard}>
                <Button.Icon value={Magic} />
                <Button.Text
                  value={!company?.counts?.channels ? 'Preencher Automaticamente' : 'Atualizar Automaticamente'}
                />
              </Button.Root>
            )}
            <Button.Root type="submit" $variant="primary" $loading={isCreatingChannel}>
              <Button.Text value="Adicionar" />
            </Button.Root>
          </Flex>
        </Form>
        <ChannelsTable />
      </Modal>
    </>
  )
}
