/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from 'd3'
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react'
import { fillEndPoint } from '../utils/fillEndPoint'
import { getFromApi } from '../utils/getFromApi'
import { generateUniqueID } from '../utils/strings'
import { useValues } from '../ValuesContext'
import './styles.css'
import { downloadPNG, isAnyDashLoading } from './utils'
import { Fonte } from '@/utils'

interface BarChartProps {
  title: string
  subtitle: string
  xValue: string
  yValue: string
  endPoint: string
}

const TICK_TEXT_CSS_CLASS = '.tick text'
const AXIS_BOTTOM_TICK_FONT_SIZE = '12px'

const BarChart: React.FC<BarChartProps> = ({ title, subtitle, xValue, yValue, endPoint }) => {
  const componentID = useMemo(() => generateUniqueID(), [])

  const [isLoading, setIsLoading] = useState(true)
  const { values, updateValues }: any = useValues()

  const printableRef = useRef<HTMLDivElement>({} as HTMLDivElement)

  function roundMaxValueUp(maxValue: number): number {
    if (maxValue === 0) return 0
    const log10BasedValue = Math.log10(maxValue)
    const flooredValue = Math.floor(log10BasedValue)
    const magnitude = Math.pow(5, flooredValue)
    const ceiledValue = Math.ceil(maxValue / magnitude)
    return ceiledValue * magnitude
  }

  function calculateCornerRadius(value: number, baseRadius: number, yLimit: number) {
    if (value >= 0.1 * yLimit) return baseRadius
    if (value <= 0.01 * yLimit) return 0.4 * baseRadius
    return 0.7 * baseRadius
  }

  function formatNumberWithDot(value: number) {
    return d3.format(',')(value).replace(/,/g, '.')
  }

  const exportAsPng = () => {
    downloadPNG(printableRef, subtitle)
  }

  useEffect(() => {
    if (Object.keys(values).length === 0 || !values.idEmpresaIN || values.k) {
      return
    }

    setIsLoading(true)

    const getUrl: any = fillEndPoint(endPoint, values)

    d3.select(printableRef.current).select(`.column-chart-container`).remove()
    d3.select(printableRef.current).select(`.no-data-message`).remove()

    async function updateCompetitors(currentElements: any) {
      let totalMencoes = 0
      const lastSlashIndex = endPoint.lastIndexOf('/')
      const baseUrl = endPoint.substring(0, lastSlashIndex)

      const nomeEmpresa = await getFromApi(`${baseUrl}/companyName?idEmpresaIN=${values.idEmpresaOLD}`)
      const valuesAux = { ...values, idEmpresaIN: values.idEmpresaOLD }
      const mencoes = await getFromApi(fillEndPoint(`${baseUrl}/piePolarity`, valuesAux))

      totalMencoes = mencoes.map((i: any) => i.quantity).reduce((acc: any, curr: any) => acc + curr, 0)

      return [
        ...currentElements,
        {
          idempresa: values.idEmpresaOLD,
          nomeempresa: nomeEmpresa[0].nome,
          mencoes: totalMencoes,
        },
      ]
    }

    getFromApi(getUrl)
      .then(async (elements) => {
        if (endPoint.includes('Competitors')) {
          if (values.idEmpresaOLD) {
            elements = await updateCompetitors(elements)
          }
        }

        if (!printableRef.current) return

        if (!elements?.length) {
          d3.select(printableRef.current)
            .append('div')
            .attr('class', 'no-data-message')
            .text('Não há dados para análise no período')
            .style('display', 'inline-block')
            .style('margin', '0')
          setIsLoading(false)

          if (endPoint.includes('Competitors') && values.idEmpresaOLD != '' && values.idEmpresaOLD != undefined) {
            d3.select(printableRef.current)
              .append('button')
              .attr('class', 'close-button')
              .style('background-color', 'red')
              .style('color', 'white')
              .style('border', 'none')
              .style('border-radius', '50%')
              .style('width', '25px')
              .style('height', '25px')
              .style('position', 'absolute')
              .style('right', '25%')
              .html(
                '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z"/></svg>',
              )
              .on('click', () => {
                if (isAnyDashLoading()) {
                  return
                }
                updateValues({ idEmpresaIN: values.idEmpresaOLD })
                updateValues({ idEmpresaOLD: '' })
              })
          }
          return
        }

        if (endPoint.includes('Competitors')) {
          d3.select(printableRef.current).selectAll('button').remove()
        }

        const barSettings = {
          width: 50,
          padding: 10,
          radius: 15,
        }

        const containerMargin = {
          top: 20,
          right: 15,
          bottom: 30,
          left: 55,
        }

        const chartWidth = 1036
        const chartHeight = 300

        const containerHeight = chartHeight * 0.8 - containerMargin.top - containerMargin.bottom
        const width = chartWidth - containerMargin.left - containerMargin.right

        const font = Fonte.getFontFromSelector(TICK_TEXT_CSS_CLASS)
        const changedFont = Fonte.replaceFontSize(font, AXIS_BOTTOM_TICK_FONT_SIZE)
        const maxTextWidth = Math.ceil(width / elements.length)

        const yValues = elements.map((i: any) => i[yValue])
        const xValues = elements.map((i: any) => {
          i[xValue] = Fonte.cropText(i[xValue], maxTextWidth, changedFont)
          return i[xValue]
        })

        const printableElement = d3.select(printableRef.current)

        const tooltip = printableElement.append('div').attr('class', 'tooltip')

        const chartContainer = printableElement
          .append('div')
          .attr('class', 'column-chart-container')
          .style('width', chartWidth + 'px')
          .style('border', 'none')
          .style('border-radius', '0rem')
          .style('box-shadow', 'none')

        // chartContainer
        //   .insert("h3")
        //   .attr("class", "column-chart-subtitle")
        // .text(subtitle);

        const headerContainer = chartContainer
          .insert('div')
          .attr('class', 'chart-header')
          .style('display', 'flex')
          .style('position', 'relative')
          .style('align-items', 'center')
          .style('justify-content', 'space-between')
          .style('margin-bottom', '20px')
          .style('margin-top', '-30px')
          .style('margin-left', `${chartWidth / 12}px`)

        headerContainer.insert('h3').attr('class', 'column-chart-subtitle')
        // .text(subtitle);

        headerContainer
          .append('button')
          .attr('class', 'export-button')
          .html(
            `
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#C7C7C7">
            <path d="M480-260q75 0 127.5-52.5T660-440q0-75-52.5-127.5T480-620q-75 0-127.5 52.5T300-440q0 75 52.5 127.5T480-260Zm0-80q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM160-120q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h126l74-80h240l74 80h126q33 0 56.5 23.5T880-680v480q0 33-23.5 56.5T800-120H160Zm0-80h640v-480H638l-73-80H395l-73 80H160v480Zm320-240Z"/>
          </svg>
        `,
          )
          .on('click', exportAsPng)

        const chart = chartContainer
          .append('svg')
          .attr('width', chartWidth)
          .attr('height', chartHeight)
          .append('g')
          .attr('transform', `translate(${containerMargin.left},${containerMargin.top + 40})`)

        let padding
        if (xValues.length <= 3) {
          padding = 0.8
        } else {
          padding = 0.63
        }

        const xScale: any = d3.scaleBand().domain(xValues).range([0, width]).padding(padding)

        const highestValue = roundMaxValueUp(Math.max(...yValues))
        const yScale = d3.scaleLinear().domain([0, highestValue]).range([containerHeight, 0])

        function formatTick(d: any) {
          if (d === 0) return '0'
          const format = d3.format('.3s')
          let formatted = format(d).replace(/G$/, 'B')
          formatted = formatted.replace(/,/g, '.')

          if (formatted.match(/\.\d+M$/) || formatted.match(/\.\d+k$/) || formatted.match(/\.\d+$/)) {
            formatted = formatted.replace(/\.\d+/, '')
          }

          return formatted
        }

        chart
          .append('g')
          .call(d3.axisLeft(yScale).ticks(4).tickFormat(formatTick))
          .call((g) => g.select('.domain').remove())
          .call((g) => g.selectAll('.tick line').remove())
          .call((g) => g.selectAll(TICK_TEXT_CSS_CLASS).style('font-size', '14px'))

        chart
          .append('g')
          .attr('transform', `translate(0, ${containerHeight + containerMargin.bottom})`)
          .call(d3.axisBottom(xScale).tickSize(0))
          .call((g) => g.select('.domain').remove())
          .call(
            (g) =>
              g.selectAll(TICK_TEXT_CSS_CLASS).style('font-size', AXIS_BOTTOM_TICK_FONT_SIZE).style('fill', '#000'),
            // .attr("transform", "rotate(-90)")
            // .style("text-anchor", "end")
          )

        const chartReferencesLines = yScale.ticks(4)

        chartReferencesLines.forEach((tick) => {
          chart
            .append('line')
            .attr('x1', 0)
            .attr('x2', width)
            .attr('y1', yScale(tick))
            .attr('y2', yScale(tick))
            .attr('class', 'column-chart-reference-line')
        })

        const onOver = function (event: MouseEvent, input: any) {
          chart.selectAll('rect').style('opacity', 0.2)
          chart.selectAll('.value').style('opacity', 0.2)
          d3.select(event.currentTarget).style('opacity', 1)
          chart
            .selectAll('.value')
            .filter((b) => b === input[yValue])
            .style('opacity', 1)
          tooltip.style('display', 'block').text(`${title}: ${input[yValue]}`)
        }

        const onLeave = function () {
          chart.selectAll('rect').style('opacity', 1)
          chart.selectAll('.value').style('opacity', 1)
          tooltip.style('display', 'none')
        }

        const onMove = function (event: MouseEvent, input: any) {
          tooltip
            .html(`${subtitle}: ${formatNumberWithDot(input[yValue])}`)
            .style('left', `${event.pageX + 15}px`)
            .style('top', `${event.pageY - 15}px`)
        }

        const onClick = function (_event: MouseEvent, input: any) {
          if (isAnyDashLoading()) {
            return
          }
          tooltip.style('display', 'none')
          if (endPoint.includes('SubBrand')) {
            if (values.idMarcaIN) {
              updateValues({ idMarcaIN: '' })
            } else {
              updateValues({ idMarcaIN: input.idmarca })
            }
          } else if (endPoint.includes('GroupTheme')) {
            if (values.grupoTemaIN) {
              updateValues({ grupoTemaIN: '' })
            } else {
              updateValues({ grupoTemaIN: input.idgrupotema })
              if (!values.idMarcaIN) {
                updateValues({ idMarcaIN: 1 })
              }
            }
          } else if (endPoint.includes('Theme')) {
            if (values.idTemaIN) {
              updateValues({ idTemaIN: '' })
            } else {
              updateValues({ idTemaIN: input.idtema })
            }
          } else if (endPoint.includes('Competitors')) {
            if (values.idEmpresaOLD) {
              updateValues({ idEmpresaIN: values.idEmpresaOLD })
              updateValues({ idEmpresaOLD: '' })
            } else {
              updateValues({ idEmpresaIN: input.idempresa })
              updateValues({ idEmpresaOLD: values.idEmpresaIN })
            }
          } else {
            if (values.idConstraintIN) {
              updateValues({ idConstraintIN: '' })
            } else {
              updateValues({ idConstraintIN: input.idconstraint })
            }
          }
        }

        chart
          .selectAll('.bar')
          .data(elements)
          .enter()
          .append('rect')
          .attr('class', 'bar')
          .attr('x', (d: any) => xScale(d[xValue].toString()))
          .attr('y', () => yScale(0))
          .attr('width', xScale.bandwidth())
          .attr('height', 0)
          .attr('rx', (d: any) => calculateCornerRadius(d[yValue], barSettings.radius, highestValue))
          .attr('ry', (d: any) => calculateCornerRadius(d[yValue], barSettings.radius, highestValue))
          .on('mouseover', onOver)
          .on('mouseleave', onLeave)
          .on('mousemove', onMove)
          .on('click', onClick)
          .transition()
          .duration(500)
          .attr('y', (d: any) => yScale(d[yValue]))
          .attr('height', (d: any) => containerHeight - yScale(d[yValue]))

        chart
          .selectAll('.value')
          .data(yValues)
          .enter()
          .append('text')
          .attr('class', 'value')
          .attr('x', (_d, i) => xScale(xValues[i].toString()) + xScale.bandwidth() / 2)
          .attr('y', (d: any) => yScale(d) - 22)
          .text((d: any) => formatNumberWithDot(d))

        const titleContainer = chartContainer.insert('div').attr('class', 'column-chart-title-container')

        titleContainer.insert('div').attr('class', 'column-chart-title-icon')
        titleContainer.append('span').attr('class', 'column-chart-title').text(title)

        setIsLoading(false)
      })
      .catch((error) => {
        console.log('Error fetching data in barChart:', error)
      })
  }, [values, endPoint])

  return (
    <div id={componentID} ref={printableRef}>
      {isLoading ?
        <div
          className="column-chart-container-spinner"
          style={{
            width: '1036px',
            height: '404px',
            border: 'none',
            borderRadius: '0rem',
            boxShadow: 'none',
          }}></div>
      : <div className="chart-container"></div>}
    </div>
  )
}

export default BarChart
