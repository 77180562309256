import { Tokens } from '@/assets/tokens'
import { Close } from '@icon-park/react'
import { useState } from 'react'
import { Icon } from '../Icon/Icon.component'
import { SelectionOption, Selector } from '../Selector'
import { Item, SearchFunc } from './SelectedList.hooks'
import { Container, InputContainer, ItemButton, ListContainer, ListItem, ListItemText } from './SelectedList.styles'

type Props = {
  placeholder: string
  isLoading: boolean
  options: Item[]
  onSearch: SearchFunc
  onChange: (changes: SelectionOption[]) => void
}

export function SelectedList({ placeholder, isLoading, options, onSearch, onChange }: Props): JSX.Element | null {
  const [selectedValues, setSelectedValues] = useState<SelectionOption[]>([])

  function handleSelection(item?: SelectionOption): void {
    if (item) {
      const changes = [...selectedValues, item]
      setSelectedValues(changes)
      onChange(changes)
    }
  }

  function handleClickFromSelectedList(index: number): void {
    const changes = selectedValues.filter((_, idx) => idx !== index)
    setSelectedValues(changes)
    onChange(changes)
  }

  const availableOptions = options.filter((item: Item): boolean => {
    return !selectedValues.map((value) => value.value).includes(item.value)
  })

  return (
    <Container>
      <InputContainer>
        <Selector.Searchable
          placeholder={placeholder}
          options={availableOptions}
          onSearch={onSearch}
          onSelect={handleSelection}
          loading={isLoading}
          required
        />
      </InputContainer>
      <ListContainer>
        {selectedValues.map((item: SelectionOption, index: number) => (
          <ListItem
            key={item.value}
            onClick={() => {
              handleClickFromSelectedList(index)
            }}>
            <ListItemText>{item.text}</ListItemText>
            <ItemButton>
              <Icon value={Close} size={6} color={Tokens.ColorWhite} />
            </ItemButton>
          </ListItem>
        ))}
      </ListContainer>
    </Container>
  )
}
