export class Fonte {
  public static cropText(text: string, maxWidth: number, font: string, ellipsis = '...'): string {
    if (!text || !maxWidth) return text

    const context = document.createElement('canvas').getContext('2d')
    if (!context) return text

    context.font = font ? font : '12px Arial'

    if (context.measureText(text).width <= maxWidth) return text

    return (
      text.split('').reduce((cropped, char) => {
        const testString = cropped + char + ellipsis
        return context.measureText(testString).width > maxWidth ? cropped : cropped + char
      }, '') + ellipsis
    )
  }

  public static getFontFromSelector(selector: string): string {
    if (!selector) return ''

    const element = document.querySelector(selector)
    if (!element) return ''

    const style = window.getComputedStyle(element)
    const font = style.getPropertyValue('font')
    if (font) return font.trim()

    const selectFontSize = style.getPropertyValue('font-size')
    const selectorFontFamily = style.getPropertyValue('font-family')
    return `${selectFontSize} ${selectorFontFamily}`.trim()
  }

  public static replaceFontSize(font: string, size: string): string {
    if (!font || !size) return font
    return font.replace(/\b\d+px\b/g, `${size}`)
  }
}
