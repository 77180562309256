import { useState } from 'react'

import { Layout } from '@/components/layout'
import { Button } from './components/atoms'
import { ButtonGroup } from './components/atoms/Button/Button.styles'
import { CreateDashboard } from './components/CreateDashboard.component'
import { CreateTopKMentions } from './components/CreateTopKMention.component'
import { DashboardContainer } from './components/Dashboards.styles'
import { Filters } from './components/Filters/Filters.component'
import { ValuesProvider } from './components/ValuesContext'
import { isAnyDashLoading } from './components/dashes/utils'
import { CompanyDetailsContextProvider } from '../CompanyDetails/CompanyDetails.context'

export function Dashboard() {
  const [currentPage, setPage] = useState(1)

  return (
    <CompanyDetailsContextProvider>
      <Layout.Root>
        <ValuesProvider>
          <DashboardContainer>
            {/* <Nav /> */}
            <Filters />
            {currentPage === 1 && <CreateDashboard />}
            {currentPage === 2 && <CreateTopKMentions />}

            <ButtonGroup>
              <Button
                $variant={currentPage === 1 ? 'primary' : 'secondary'}
                text="Painel de Indicadores"
                onClick={() => {
                  if (isAnyDashLoading()) {
                    return
                  }
                  setPage(1)
                }}
              />
              <Button
                $variant={currentPage === 2 ? 'primary' : 'secondary'}
                text="Principais Menções"
                onClick={() => {
                  if (isAnyDashLoading()) {
                    return
                  }
                  setPage(2)
                }}
              />
            </ButtonGroup>
          </DashboardContainer>
        </ValuesProvider>
      </Layout.Root>
    </CompanyDetailsContextProvider>
  )
}
