import dogSrc from '@/assets/images/dog.svg'
import logoSrc from '@/assets/images/logo.svg'

import {
  Background,
  Body,
  BodyContent,
  BodyLogo,
  BodySubtitle,
  BodyText,
  BodyTitle,
  Header,
  Logo,
} from './ServerError.styles'

export const ServerError = () => {
  return (
    <Background>
      <Header>
        <Logo src={logoSrc} alt="logo" />
      </Header>
      <Body>
        <BodyLogo src={dogSrc} alt="error-svg" />
        <BodyContent>
          <BodyTitle>50X</BodyTitle>
          <BodySubtitle>Erro interno do servidor.</BodySubtitle>
          <BodyText>
            Ocorreu algum problema com o servidor. <br />
            Já estamos trabalhando na solução. <br />
            <br />
            Tente novamente mais tarde
          </BodyText>
          <BodyText>Ir para o início</BodyText>
        </BodyContent>
      </Body>
    </Background>
  )
}
