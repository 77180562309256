import { Right } from '@icon-park/react'

import { ElementType } from 'react'

import { Flex } from '@/components'

import { Container, ContainerName, HealthCheckStatus, LinksContainer } from './ManualCard.styles'

interface ManualCardProps {
  icon: ElementType
  title: string
  healthCheck?: boolean
  description?: string
  linkName?: string
  linkAction?: () => void
}

export function ManualCard({ icon: Icon, title, healthCheck, description, linkName, linkAction }: ManualCardProps) {
  return (
    <Container>
      <ContainerName>
        <Icon size={28} />
        <h4>{title}</h4>
      </ContainerName>
      <LinksContainer>
        {healthCheck !== undefined && <HealthCheckStatus $status={healthCheck} />}
        <Flex $type="row" $justify="flex-end" $align="center" $gap={32} $fit>
          {description && <h5 className="tertiary-text">{description}</h5>}
          {linkName && (
            <Flex $type="row" $align="center" $gap={8} onClick={linkAction}>
              <h4 className="link-text">{linkName}</h4>
              <Right className="link-text" size={16} />
            </Flex>
          )}
        </Flex>
      </LinksContainer>
    </Container>
  )
}
