import { Tokens } from '@/assets/tokens'
import styled, { css } from 'styled-components'

import { onClose, onOpen } from './Layout.keyframes'

export const LayoutContainer = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`

export const MenuContainer = styled.div<{ $isClosed: boolean }>`
  position: relative;
  width: 17.5rem;
  height: 100%;
  padding: 24px 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 4rem;

  background-color: #fbfaff;
  transition: all ease-in-out 0.25s;

  ${({ $isClosed: isClosed }) =>
    isClosed &&
    css`
      width: 96px;
    `}
`

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  background-color: ${Tokens.ColorHover};
`

export const HeaderContainer = styled.nav`
  width: 100%;
  height: ${Tokens.HeaderHeight};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 17px 48px;

  background-color: ${Tokens.ColorWhite};
  border-bottom: 0.1rem solid ${Tokens.ColorBorder};
  border-left: 0.1rem solid ${Tokens.ColorBorder};
`

export const Content = styled.div`
  width: 100%;
  min-width: 1184px;
  max-width: 1920px;
  height: fit-content;

  padding: 4.5rem 1rem;
  align-self: center;

  display: flex;
  flex-direction: column;
`

export const Padder = styled.div`
  width: ${Tokens.RootWithPaddingWidth};
  height: fit-content;

  align-self: center;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

export const MenuToggler = styled.div`
  all: unset;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 24px;
  position: absolute;
  right: -12px;
  top: 28px;

  cursor: pointer;
  background-color: white;
  border: 1px solid ${Tokens.ColorBorder};

  display: flex;
  justify-content: center;
  align-items: center;

  & > span > svg {
    color: ${Tokens.ColorUIPrimaryDim};
  }
`

export const MenuLogo = styled.img<{ $isMenuClosed: boolean }>`
  align-self: center;
  justify-self: center;
  transition: all ease-in-out 0.25s;
  animation: ${onOpen} 0.5s forwards;

  ${({ $isMenuClosed: isMenuClosed }) =>
    isMenuClosed &&
    css`
      animation: ${onClose} 0.5s forwards;
    `}
`

export const MenuItems = styled.div`
  position: relative;

  height: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

export interface MenuItemStyledProps {
  $active?: boolean
  $menuClosed: boolean
}

export const MenuItemContainer = styled.div<MenuItemStyledProps>`
  width: 100%;
  height: 56px;
  padding: 0 1.5rem;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;

  border: unset;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  &:last-child {
    position: absolute;
    bottom: 0;

    font-weight: 400;
    background-color: ${Tokens.ColorHover};

    &:hover {
      background-color: ${Tokens.ColorBorder};
    }

    & > span {
      color: ${Tokens.ColorTextPrimary};
    }
  }

  &:hover {
    background-color: ${Tokens.ColorHover};

    & > span {
      color: ${Tokens.ColorTextPrimary};
    }
  }

  & > span {
    color: ${Tokens.ColorTextSecondary};
    transition: all ease-in-out 0.25s;
  }

  ${({ $active }) =>
    $active &&
    css`
      pointer-events: none;
      background-color: ${Tokens.ColorUIPrimary};
      box-shadow: 0px 8px 20px 0px rgba(64, 203, 133, 0.3);

      & > span {
        color: ${Tokens.ColorWhite};
        font-weight: 600;
      }
    `}

  ${({ $menuClosed }) =>
    $menuClosed &&
    css`
      padding: 0 1rem;
      width: 56px;

      & > span:last-child {
        transform: translateX(-100vw);
      }
    `}
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 40px;
`

export const Logo = styled.img`
  width: 68px;
`

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 40px;
`
