export class Str {
  private constructor(public readonly value?: string) {}

  /** @description will convert the number to string if a number is provided */
  static from(input?: string | number): Str {
    if (!input) return new Str()

    const parsedValue = String(input)
    if (!parsedValue.length || parsedValue.trim() === '') return new Str()
    return new Str(parsedValue)
  }

  get isBlank(): boolean {
    if (!this.value) return true
    if (!this.value.length) return true
    if (this.value.trim() === '') return true
    return false
  }

  get initCap(): string {
    return (this.value ?? '')
      .toLowerCase()
      .split(' ')
      .map((word: string): string => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  /** @description Returns an empty string if value is undefined */
  get val(): string {
    return this.value ?? ''
  }
}

export function truncateString(str: string, length?: number): string {
  if (!str) return ''
  const len = length ?? 15
  if (str.length <= len) return str
  return str.substring(0, len) + ' ...'
}
