import { Plus, Search } from '@icon-park/react'

import { CompanyResponse, Paged, UserResponseRole } from '@shared/models'

import { Routes } from '@/configs'

import loadImg from '@/assets/images/load.gif'
import logoImg from '@/assets/images/logo.svg'
import { Tokens } from '@/assets/tokens'

import { Api } from '@/modules/api'

import { useRouting } from '@/hooks/useRouting'

import { Button, Divider, Flex, Input } from '@/components'
import { ServerError, SkeletonLoader, UserInfo } from '@/components/derived'

import { Auth } from '@/modules'
import { useEffect, useState } from 'react'
import {
  Card,
  CardImage,
  CompanyName,
  Container,
  Content,
  ContentContainer,
  Header,
  HeaderLeft,
  HeaderRight,
  Logo,
} from './Home.styles'

interface CompanyItemProps {
  name: string
  logoID?: string
  onClick: () => void
}

export function CompanyItem({ name, logoID, onClick }: CompanyItemProps) {
  const [isLoadingLogo, , logo] = Api.Query<string>(`files/${logoID}`)
  const url = !isLoadingLogo && logo ? logo : loadImg

  return (
    <Card onClick={onClick}>
      <CompanyName>{name}</CompanyName>
      <CardImage alt={name} src={url} />
    </Card>
  )
}

export function Home() {
  const { navigate } = useRouting()

  const [fetchCompanies, isFetchingCompanies, companies, error] = Api.LazyQuery<Paged<CompanyResponse>>('companies')
  const [filteredCompanies, setFilteredCompanies] = useState<CompanyResponse[]>()
  const [searchTerm, setSearchTerm] = useState<string>('')

  function filterCompany() {
    return companies?.elements.filter((company) => company.name.toLowerCase().includes(searchTerm.toLowerCase()))
  }

  useEffect(() => {
    fetchCompanies({
      page: 1,
      size: 50,
      orderBy: 'name:ASC',
      loadRegular: true,
      loadCompetitors: false,
    })
  }, [])

  useEffect(() => {
    if (searchTerm) {
      setFilteredCompanies(filterCompany())
      return
    }
    setFilteredCompanies(companies?.elements)
  }, [searchTerm])

  useEffect(() => {
    if (companies) {
      setFilteredCompanies(companies?.elements)
    }
  }, [companies])

  if (error) return <ServerError />

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <Logo alt="logo" src={logoImg} />
          <Input
            icon={Search}
            placeholder="Pesquise por uma empresa..."
            onChange={(value: string) => setSearchTerm(value)}
          />
        </HeaderLeft>
        <HeaderRight>
          <Flex $type="row" $justify="flex-end" $align="stretch" $gap={8}>
            <Auth.ProtectedComponent requiredRoles={[UserResponseRole.Admin, UserResponseRole.Leadership]}>
              <Button.Root onClick={() => navigate(Routes.Companies)} $variant="outlined">
                <Button.Icon value={Plus} color={Tokens.ColorUIPrimary} />
                <Button.Text value="Registrar Empresa" />
              </Button.Root>
              <Button.Root onClick={() => navigate(Routes.Assessors)} $variant="outlined">
                <Button.Text value="Gerenciar Usuários" />
              </Button.Root>
            </Auth.ProtectedComponent>
          </Flex>
          <UserInfo />
        </HeaderRight>
      </Header>
      <ContentContainer>
        <Divider.Root>
          <Divider.Title value="Selecione uma empresa" />
          <Divider.Spacer />
          <Divider.Subtitle value={`${filteredCompanies?.length ?? 0} registro(s)`} />
        </Divider.Root>
        <Content>
          {isFetchingCompanies ?
            <SkeletonLoader />
          : filteredCompanies?.map((company: CompanyResponse) => {
              return (
                <CompanyItem
                  onClick={() => navigate(Routes.CompanyDetails, company.id)}
                  key={company.id}
                  name={company.name}
                  logoID={company.systemLogoID}
                />
              )
            })
          }
        </Content>
      </ContentContainer>
    </Container>
  )
}
