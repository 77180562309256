import { Tokens } from '@/assets/tokens'
import { ReportStatus } from '@shared/models'
import styled, { css } from 'styled-components'

export type ButtonProps = {
  icon: string
}

export const ReportButton = styled.div`
  width: 20rem;
  min-height: 7.5rem;
  height: fit-content;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 1.5rem;
  gap: 0.5rem;

  background-color: ${Tokens.ColorWhite};
`
export const ReportIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
`

export const ReportName = styled.h4`
  width: 10rem;
  color: ${Tokens.ColorTextPrimary};
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

type ReportProps = {
  $status: ReportStatus
  $percentage: number
}

const ReportStatusCSS = {
  [ReportStatus.UPLOADED]: css`
    background-color: ${Tokens.ColorUIWarn};
  `,
  [ReportStatus.STARTED]: css`
    background-color: ${Tokens.ColorUIWarn};
  `,
  [ReportStatus.FINISHED]: css`
    background-color: ${Tokens.ColorUIPrimary};
  `,
  [ReportStatus.ERRORED]: css`
    background-color: ${Tokens.ColorUIDanger};
  `,
}

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.25rem;
`

export const StatusText = styled.span`
  font-size: 0.8rem;
  line-height: 1;
`

// export const ReportStatusLine = styled.div<ReportProps>`
//   width: 100%;
//   height: 0.5rem;
//   border-radius: 0.3rem;

//   ${({ $status: status }) => ReportStatusCSS[status]}
// `

export const ReportStatusLine = styled.div<ReportProps>`
  position: relative;
  width: 100%;
  height: 0.5rem;
  border-radius: 0.3rem;
  background-color: ${Tokens.ColorBorder};

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: ${({ $percentage }) => $percentage}%;
    border-radius: 0.3rem;
    ${({ $status }) => ReportStatusCSS[$status]}
    transition: width 0.5s ease-in-out;
  }
`

export const DownloadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const DownloadButton = styled.a`
  cursor: pointer;
`
