/* eslint-disable react-refresh/only-export-components */
import { ElementType, ReactNode, useContext } from 'react'

import { ChartHistogramTwo, Comments, DocDetail, Left, Right, Setting, Switch, UploadWeb } from '@icon-park/react'

import { Route, Routes } from '@/configs'

import LogoSrc from '@/assets/images/logo.svg'

import { useRouting } from '@/hooks'

import { UserInfo } from '@/components/derived'

import { Alert } from '@/modules'
import { useCompanyContext } from '@/pages/CompanyDetails/CompanyDetails.context'
import {
  Content,
  ContentContainer,
  HeaderContainer,
  LayoutContainer,
  LeftSide,
  Logo,
  MenuContainer,
  MenuItemContainer,
  MenuItems,
  MenuLogo,
  MenuToggler,
  Padder,
  RightSide,
} from './Layout.styles'
import { MenuContext } from './menu.context'

export const Layout = {
  Root: RootLayout,
  Padder: Padder,
  Login: LoginLayout,
}

interface RootLayoutProps extends HeaderProps {
  children: ReactNode
}

function RootLayout({ title, noMenu = false, children }: RootLayoutProps) {
  return (
    <LayoutContainer>
      <Menu />
      <ContentContainer>
        <Header title={title} noMenu={noMenu} />
        <Content>{children}</Content>
      </ContentContainer>
    </LayoutContainer>
  )
}

function LoginLayout({ children }: RootLayoutProps) {
  return (
    <LayoutContainer>
      <ContentContainer>
        <Content>{children}</Content>
      </ContentContainer>
    </LayoutContainer>
  )
}

interface MenuItemProps {
  text: string
  icon: ElementType
  canNavigate: boolean
  mappedRoute: Route
  pathVariables?: string[]
}

function MenuItem({ text, icon: Icon, canNavigate, mappedRoute, pathVariables = [] }: MenuItemProps) {
  const { isClosed } = useContext(MenuContext)
  const { route, navigate } = useRouting()
  const active = mappedRoute === route
  const { sendAlert } = Alert.Hook()

  const handleClick = () => {
    if (canNavigate) {
      navigate(mappedRoute, ...pathVariables)
    } else {
      sendAlert({
        type: 'error',
        title: 'Erro',
        message: 'Empresa está com manual incompleto: Favor revisar o manual!',
      })
    }
  }

  return (
    <MenuItemContainer
      onClick={handleClick}
      $active={active}
      $menuClosed={isClosed}
      title={text}
      style={{ cursor: canNavigate ? 'pointer' : 'not-allowed', opacity: canNavigate ? 1 : 0.5 }}>
      <Icon size={22} />
      <span>{text}</span>
    </MenuItemContainer>
  )
}

export function Menu() {
  const { isClosed, toggle } = useContext(MenuContext)
  const { company } = useCompanyContext()
  const { route } = useRouting()

  if (!route.showMenu) return null

  return (
    <MenuContainer $isClosed={isClosed}>
      <MenuToggler onClick={toggle}>
        {isClosed ?
          <Right />
        : <Left />}
      </MenuToggler>
      <MenuLogo alt="Logo" src={LogoSrc} $isMenuClosed={isClosed} />
      <MenuItems>
        <MenuItem
          icon={Setting}
          text="Config. Manual"
          canNavigate={true}
          mappedRoute={Routes.CompanyDetails}
          pathVariables={[company?.id ?? '']}
        />
        <MenuItem
          icon={UploadWeb}
          text="Uploads"
          canNavigate={company?.healthCheck?.isHealthy ?? false}
          mappedRoute={Routes.Uploads}
          pathVariables={[company?.id ?? '']}
        />
        <MenuItem
          icon={Comments}
          text="Menções"
          canNavigate={company?.healthCheck?.isHealthy ?? false}
          mappedRoute={Routes.Mentions}
          pathVariables={[company?.id ?? '']}
        />
        <MenuItem
          icon={ChartHistogramTwo}
          text="Dashboards"
          canNavigate={company?.healthCheck?.isHealthy ?? false}
          mappedRoute={Routes.Dashboard}
          pathVariables={[company?.id ?? '']}
        />
        <MenuItem
          icon={DocDetail}
          text="Relatórios"
          canNavigate={company?.healthCheck?.isHealthy ?? false}
          mappedRoute={Routes.Reports}
          pathVariables={[company?.id ?? '']}
        />
        <MenuItem icon={Switch} text="Trocar empresa" canNavigate={true} mappedRoute={Routes.Home} />
      </MenuItems>
    </MenuContainer>
  )
}

interface HeaderProps {
  title?: string
  noMenu?: boolean
}

export function Header({ title, noMenu = false }: HeaderProps) {
  return (
    <HeaderContainer>
      {title && <h1>{title}</h1>}
      <LeftSide>{noMenu && <Logo alt="Logo" src={LogoSrc} />}</LeftSide>
      <RightSide>
        <UserInfo />
      </RightSide>
    </HeaderContainer>
  )
}
