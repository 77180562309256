/* eslint-disable react-refresh/only-export-components */
import React, { Context, ReactNode, createContext, useCallback, useContext, useState } from 'react'

import { generateRandomID } from '@/utils/idGenerator'

type Observer<T = any> = (message?: T) => void
type Observers = { [topic: string]: { id: string; func: Observer }[] }

interface Output {
  observe: (topic: string, observer: Observer) => string
  sendMessage: <T>(topic: string, message?: T) => void
  removeObserver: (topic: string, id: string) => void
}

const ObserverContext: Context<Output> = createContext<Output>({} as Output)

export const useObserver = (): Output => useContext(ObserverContext)

export const ObserverContextProvider: React.FC<{ children: ReactNode }> = ({ children }): JSX.Element => {
  const [observers, setObservers] = useState<Observers>({})

  const observe = useCallback((topic: string, func: Observer): string => {
    const id = generateRandomID()
    setObservers((prev) => ({
      ...prev,
      [topic]: [...(prev[topic] || []), { id, func }],
    }))
    return id
  }, [])

  const send = useCallback(
    (topic: string, message?: any): void => {
      const observerFromTopic = observers[topic]
      if (observerFromTopic) {
        observerFromTopic.forEach(({ func }) => func(message))
      }
    },
    [observers],
  )

  const remove = useCallback((topic: string, id: string): void => {
    setObservers((prev) => ({
      ...prev,
      [topic]: prev[topic]?.filter((observer) => observer.id !== id) || [],
    }))
  }, [])

  return (
    <ObserverContext.Provider value={{ observe, sendMessage: send, removeObserver: remove }}>
      {children}
    </ObserverContext.Provider>
  )
}
