import { useContext } from 'react'

import { Star } from '@icon-park/react'

import { useFileUploader, useInput, useRouting } from '@/hooks'

import { Button, Flex, Form, Input, Uploader } from '@/components'
import { ManualCard, Modal, UploaderViewer } from '@/components/derived'

import { BrandsTable } from './BrandsTable'

import { AddBrandInput, CreateCompanyContext } from '../CreateCompany.context'
import { LogoContainer } from '../CreateCompany.styles'

export function BrandsModal() {
  const { changeQueryParam } = useRouting()
  const { addBrand, brands } = useContext(CreateCompanyContext)

  const { value: name, binder: nameBinder, reset: resetName } = useInput()
  const { value: systemLogo, bind: systemLogoBinder, reset: resetSystemLogo } = useFileUploader()
  const { value: reportLogo, bind: reportLogoBinder, reset: resetReportLogo } = useFileUploader()

  function onSubmit(): void {
    addBrand({
      name,
      systemLogo,
      reportLogo,
    } as AddBrandInput)
    resetName()
    resetSystemLogo()
    resetReportLogo()
  }

  return (
    <>
      <ManualCard
        icon={Star}
        title="Marcas"
        description={`${brands.length} entrada(s)`}
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'marcas',
          })
        }
      />
      <Modal
        controlKey="marcas"
        backToName="Cadastro de Empresa"
        title="Marcas"
        subtitle={
          <span>
            Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro da marca.
          </span>
        }
        icon={Star}>
        <Form onSubmit={onSubmit}>
          <Flex $type="column" $align="stretch" $gap={24} $fit>
            <Input label="Nome da Marca" placeholder="Marca" {...nameBinder} required />
            <Flex $type="row" $justify="center" $align="center" $gap={24} $fit>
              <LogoContainer>
                <Uploader
                  label="Logo em cores (.svg 417px/128px)"
                  {...systemLogoBinder}
                  requiredExtensions={['svg']}
                  required
                />
                <UploaderViewer value={systemLogo} />
              </LogoContainer>
              <LogoContainer>
                <Uploader
                  label="Logo em cinza (.svg 417px/128px)"
                  {...reportLogoBinder}
                  requiredExtensions={['svg']}
                  required
                />
                <UploaderViewer value={reportLogo} />
              </LogoContainer>
            </Flex>
            <Flex $type="row" $justify="flex-end" $align="stretch" $gap={20} $fit>
              <Button.Root type="submit" $variant="primary">
                <Button.Text value="Adicionar" />
              </Button.Root>
            </Flex>
          </Flex>
        </Form>
        <BrandsTable />
      </Modal>
    </>
  )
}
