import { CommandMethods, useCommand } from './useCommand'
import { useLazyQuery } from './useLazyQuery'
import { usePromiseQuery } from './usePromiseQuery'
import { useQuery } from './useQuery'

type PrimitiveTypes = string | number | boolean

export type ApiFuncParam = NodeJS.Dict<PrimitiveTypes | NodeJS.Dict<PrimitiveTypes>>

export type ApiPromiseFuncOutput<T> = Promise<[T | undefined, Error | undefined, () => void | undefined]>

export type ApiRunPromiseFunc<T> = (
  urlParams?: ApiFuncParam | string,
  queryParams?: ApiFuncParam,
) => Promise<ApiPromiseFuncOutput<T>>

export type ApiRunFunc = (params?: ApiFuncParam) => void

export const Api = {
  Command: useCommand,
  LazyQuery: useLazyQuery,
  Query: useQuery,
  PromiseQuery: usePromiseQuery,
  Methods: CommandMethods,
}
