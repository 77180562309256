import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import { Routes } from '@/configs'

import { FullPageLoader } from '@/components/derived'

import { useAuth } from './auth.context'
import { useRouting } from '@/hooks'

interface Props {
  children: ReactNode
}

export function AuthGuard({ children }: Props) {
  const { session, isLoading, isAuthorized } = useAuth()
  const { route } = useRouting()

  if (isLoading) {
    return <FullPageLoader />
  }

  if (!isAuthorized) {
    return <Navigate to={Routes.Welcome.path} />
  }

  if (route.requiredRoles && !route.requiredRoles.includes(session!.userRole)) {
    return <Navigate to={Routes.Home.path} />
  }

  return children
}
