import { Canal, Divider, Flex, Tooltip } from '@/components'
import { ColumnType, SkeletonLoader, Table } from '@/components/derived'
import Capitalize from '@stdlib/string-capitalize'
import { useEffect, useState } from 'react'
import { fillEndPoint } from './utils/fillEndPoint'
import { getFromApi } from './utils/getFromApi'
import { useValues } from './ValuesContext'
import { Api } from '@/modules'
import { MentionResponse } from './TopKMentions/topKmentions'
import { truncateString } from '@/utils/strings'
import { Tokens } from '@/assets/tokens'
import Broadcast from '@icon-park/react/lib/icons/Broadcast'
import { formatNumber } from '@/utils/numbers'
import { PolarityEmoji } from '@/pages/Mentions/components/Polarity/Polarity'

export type Image = {
  id: string
  mime: string
  content: string
}

export function CreateTopKMentions() {
  const { values, updateValues }: any = useValues()
  const [totalMentions, setTotalMentions] = useState(0)

  const [elements, setElements]: any = useState(null)
  const apiHost = process.env.REACT_APP_DASH_API_HOST
  const [ids, setIds] = useState<string[]>([])
  const [images, setImages] = useState<Map<string, string>>(new Map())

  const [reloadImages, , responseImages] = Api.LazyQuery<Image[]>(`files/batch`)

  useEffect(() => {
    if (ids && ids.length > 0) {
      reloadImages({
        ids: ids.join(','),
      })
    }
  }, [ids])

  useEffect(() => {
    const imageMap = new Map<string, string>()
    responseImages?.forEach((img) => {
      if (img.id) {
        imageMap.set(img.id, atob(img.content))
      }
    })
    setImages(imageMap)
  }, [responseImages])

  useEffect(() => {
    setElements(null)
    if (!values.k) {
      updateValues({ k: 10 })
    } else {
      const getUrl: any = fillEndPoint(`${apiHost}topKMentions`, values)
      getFromApi(getUrl).then(async (elements) => {
        setTotalMentions(elements.length)
        setElements(elements)
      })
    }
  }, [values])

  useEffect(() => {
    if (elements) {
      const aux = elements.map((el: any) => [el.canallogo, el.empresalogo]).flat()
      setIds(aux)
    }
  }, [elements])

  const topKcolumns: ColumnType<MentionResponse>[] = [
    {
      key: 'canal',
      title: 'Canal',
      width: 115,
      align: 'left',
      render: (_, item) => (
        <Canal
          brandImage={images.get(item.empresalogo)!}
          hyperlink={item.link}
          channelImage={images.get(item.canallogo)!}
        />
      ),
    },
    {
      key: 'conteudo',
      title: 'Publicação',
      width: 800,
      render: (_, item) => (
        <Flex
          $type="column"
          $justify="center"
          $align="flex-start"
          $gap={8}
          style={{
            cursor: 'pointer',
            padding: '1rem 0',
            paddingRight: '1.5rem',
          }}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <h4>@{item.autor.toLowerCase()}</h4>
            <span>{truncateString(item.conteudo, 250)}</span>
          </a>
        </Flex>
      ),
    },
    {
      key: 'alcance',
      title: 'Alcance',
      width: 158,
      align: 'center',
      render: (_, item) => (
        <Flex $type="column" $justify="center" $align="center">
          <Tooltip value={Capitalize(item.publicador)} length={20} />
          <div style={{ width: '110px', height: '1px', backgroundColor: Tokens.ColorLight }} />
          <Flex $type="row" $justify="center" $align="center" $gap={8}>
            <Broadcast size={16} />
            <span>{isNaN(Number(item.alcance)) ? item.alcance : formatNumber(Number(item.alcance))}</span>
          </Flex>
        </Flex>
      ),
    },
    {
      key: 'polaridade',
      title: 'Polaridade',
      width: 53,
      align: 'center',
      render: (_, item) => (
        <Flex $type="column" $justify="center" $align="center">
          <PolarityEmoji value={item.polaridade} />
        </Flex>
      ),
    },
    {
      key: 'reacoes',
      title: 'Reações',
      align: 'center',
      width: 93,
      render: (_, item) => (
        <Flex $type="column" $justify="center" $align="center">
          <Flex $type="column" $justify="center" $align="center" $gap={8}>
            <span>{Number(item.reacoes)}</span>
          </Flex>
        </Flex>
      ),
    },
    {
      key: 'icena',
      title: 'Icena',
      align: 'center',
      width: 43,
      render: (_, item) => (
        <Flex $type="column" $justify="center" $align="center">
          <Flex $type="column" $justify="center" $align="center" $gap={8}>
            <span>{Number(item.icena)}</span>
          </Flex>
        </Flex>
      ),
    },
  ]

  return (
    <Flex $type="column" $justify="flex-start" $align="stretch" $gap={36} $fit>
      <Divider.Root>
        <Divider.Title value="Tabela de Menções" />
        <Divider.Spacer />
        <Divider.Subtitle value={`${totalMentions} Menções`} />
      </Divider.Root>

      {!elements ?
        <div className="SkeletonLoader">
          <SkeletonLoader />
        </div>
      : <Table data={elements} columns={topKcolumns} />}
    </Flex>
  )
}
