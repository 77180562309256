import { memo } from 'react'

import { BookmarkOne } from '@icon-park/react'

import { CreateThemeGroupRequest } from '@shared/models'

import { Alert, Api } from '@/modules'

import { useInput, useRouting } from '@/hooks'

import { Button, Flex, Form, Input } from '@/components'
import { ManualCard, Modal } from '@/components/derived'

import { useCompanyContext } from '../CompanyDetails.context'
import { ThemeGroupsTable } from './ThemeGroups.table'
import { getDescription, handleWeightChange } from '../utils'

const MIN_WEIGHT = 1
const MAX_WEIGHT = 5
export const ThemeGroups = memo(Component)

function Component(): JSX.Element | null {
  const { companyID, changeQueryParam, currentPage } = useRouting()
  const { company, reloadCompany, reloadThemeGroups } = useCompanyContext()

  const { sendAlert } = Alert.Hook()

  const { value: name, binder: nameBinder, reset: resetName } = useInput()
  const { value: weight, binder: weightBinder, reset: resetWeight } = useInput()

  const { isRunning: isCreatingThemeGroup, run: createThemeGroup } = Api.Command<void>(Api.Methods.POST, `theme-groups`)

  function onSubmit(): void {
    const input = {
      companyID,
      name,
      weight: Number(weight),
    } as CreateThemeGroupRequest
    createThemeGroup<CreateThemeGroupRequest>({
      input,
      onSuccess,
    })
  }

  function onSuccess() {
    sendAlert({
      type: 'success',
      title: 'Sucesso',
      message: 'Grupo Tema cadastrado com sucesso!',
    })
    resetName()
    resetWeight()
    reloadThemeGroups(currentPage)
  }

  if (!companyID || !company) return null

  return (
    <>
      <ManualCard
        icon={BookmarkOne}
        healthCheck={company.healthCheck?.isGroupsChecked}
        title="Grupos de Temas Monitorados"
        description={getDescription(company.counts?.themeGroups)}
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'grupo-tematico',
            replaceAll: true,
          })
        }
      />
      <Modal
        controlKey="grupo-tematico"
        backToName={company.name}
        title="Grupos de Temas Monitorados"
        subtitle={
          <span>
            Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro do grupo temático.
          </span>
        }
        icon={BookmarkOne}
        beforeClose={reloadCompany}>
        <Form onSubmit={onSubmit} controlParam={isCreatingThemeGroup}>
          <Flex $type="column" $align="stretch" $gap={24} $fit>
            <Input label="Nome do grupo" placeholder="Nome" {...nameBinder} required />
            <Input
              label="Peso"
              type="number"
              placeholder="Peso"
              {...weightBinder}
              required
              onChange={(value: string) => handleWeightChange(weightBinder, MIN_WEIGHT, MAX_WEIGHT, value)}
            />
            <Flex $type="row" $justify="flex-end" $align="stretch" $gap={20} $fit>
              <Button.Root type="submit" $variant="primary" $size={228} $loading={isCreatingThemeGroup}>
                <Button.Text value="Adicionar" />
              </Button.Root>
            </Flex>
          </Flex>
        </Form>
        <ThemeGroupsTable />
      </Modal>
    </>
  )
}
