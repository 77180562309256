import { createGlobalStyle } from 'styled-components'

import SegoeUIRegular from '@/assets/fonts/segoe-ui.ttf'
import SegoeUISemiBold from '@/assets/fonts/segoe-ui-semibold.ttf'
import SegoeUIBold from '@/assets/fonts/segoe-ui-bold.ttf'

import { Tokens } from './tokens'

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Segoe UI';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url(${SegoeUIRegular}) format('truetype');
}

@font-face {
  font-family: 'Segoe UI';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url(${SegoeUISemiBold}) format('truetype');
}

@font-face {
  font-family: 'Segoe UI';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url(${SegoeUIBold}) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: 'Segoe UI', sans-serif;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

// Icon
span.i-icon {
  display: flex;
  justify-self: center;
  align-items: center;
}

// Textual Hierarchy
span {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${Tokens.ColorTextSecondary}
}

h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: ${Tokens.ColorTextPrimary}
}

h5 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: ${Tokens.ColorTextPrimary}
}

h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${Tokens.ColorTextPrimary}
}

h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
  color: ${Tokens.ColorTextPrimary}
}

h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: ${Tokens.ColorTextPrimary}
}

h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: ${Tokens.ColorTextPrimary};
}

.tertiary-text {
  color: ${Tokens.ColorTextTertiary}
}

.link-text {
  color: ${Tokens.ColorUIPrimary};
  cursor: pointer;
}
`
