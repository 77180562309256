import { Container, LoaderItem } from './SkeletonLoader.styles'

type Props = {
  amount?: number
}

export function SkeletonLoader({ amount = 5 }: Props): JSX.Element {
  const items = new Array(amount).fill(0)

  return (
    <Container>
      {items.map(
        (_: any, key: number): JSX.Element => (
          <LoaderItem key={key} />
        ),
      )}
    </Container>
  )
}
