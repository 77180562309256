import { memo, useEffect } from 'react'

import { CompanyResponse } from '@shared/models'

import { Button, Divider, TableImage } from '@/components'
import { ColumnType, Table } from '@/components/derived'

import { Routes } from '@/configs'
import { useRouting } from '@/hooks'
import { getFromToElements } from '@/utils/pagination'
import { Str } from '@/utils/strings'
import { SettingConfig } from '@icon-park/react'
import { useCompanyContext } from '../CompanyDetails.context'

export const CompanyCompetitorsTable = memo(Component)

function Component(): JSX.Element | null {
  const { navigate, currentPage } = useRouting()
  const { isFetchingCompanyCompetitors, companyCompetitors, fetchCompanyCompetitors } = useCompanyContext()

  useEffect(() => {
    fetchCompanyCompetitors(currentPage)
  }, [currentPage])

  function handleNavigationToCompetitor(id: string): void {
    navigate(Routes.Home.path)
    setTimeout(() => {
      navigate(Routes.CompanyDetails.path, id)
    }, 0)
  }

  const columns: ColumnType<CompanyResponse>[] = [
    {
      key: 'logo',
      width: 120,
      render: (_, item) => <TableImage id={item.systemLogoID} />,
    },
    {
      key: 'name',
      title: 'Nome',
      render: (_, item) => Str.from(item.name).initCap,
    },
    {
      key: 'options',
      width: 100,
      render: (_, item) => (
        <Button.Root $variant="link" onClick={() => handleNavigationToCompetitor(item.id)}>
          <Button.Icon value={SettingConfig} />
          <Button.Text value="Editar" />
        </Button.Root>
      ),
    },
  ]

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Concorrentes registrados" />
        <Divider.Spacer />
        <Divider.Subtitle
          value={`${getFromToElements(currentPage, companyCompetitors?.elements.length ?? 0)}/${companyCompetitors?.total ?? 0} Entrada(s)`}
        />
      </Divider.Root>
      <Table
        columns={columns}
        data={companyCompetitors?.elements}
        totalPages={companyCompetitors?.pages}
        isLoading={isFetchingCompanyCompetitors}
      />
    </>
  )
}
