import { ReactNode } from 'react'

import { Paginator, SkeletonLoader } from '@/components/derived'

import { TableHeader } from './Table.Header'
import { TableRows } from './Table.Rows'
import { Divider, TableComponent, TableWrapper } from './Table.styles'

export interface ColumnType<T> {
  key: string
  title?: string
  width?: number
  align?: 'left' | 'center' | 'right'
  render?: (column: ColumnType<T>, item: T, idx: number) => ReactNode
}

export interface TableProps<T> {
  data?: T[]
  columns: ColumnType<T>[]
  totalPages?: number
  isLoading?: boolean
  name?: string
}

export function Table<T>({ name, columns, data, totalPages = 1, isLoading = false }: TableProps<T>) {
  if (!isLoading && (!data || data.length < 1)) return null

  return (
    <TableWrapper>
      {isLoading ?
        <SkeletonLoader amount={3} />
      : <>
          <TableComponent>
            <thead>
              <TableHeader columns={columns} />
            </thead>
            <tbody>
              <TableRows data={data} columns={columns} />
            </tbody>
          </TableComponent>
          <Divider />
          <Paginator totalPages={totalPages} name={name} />
        </>
      }
    </TableWrapper>
  )
}
