import { Tokens } from '@/assets/tokens'
import styled, { css } from 'styled-components'

interface EmojiStyledProps {
  $active: boolean
}

const BaseEmoji = styled.path<EmojiStyledProps>`
  fill: #dee2ef;
`

export const PositiveEmoji = styled(BaseEmoji)`
  ${({ $active: active }) =>
    active &&
    css`
      fill: ${Tokens.ColorUIPrimary};
    `}
`

export const FavorableEmoji = styled(BaseEmoji)`
  ${({ $active: active }) =>
    active &&
    css`
      fill: ${Tokens.ColorTextTertiary};
    `}
`

export const UnfavorableEmoji = styled(BaseEmoji)`
  ${({ $active: active }) =>
    active &&
    css`
      fill: ${Tokens.ColorUIWarn};
    `}
`

export const NegativeEmoji = styled(BaseEmoji)`
  ${({ $active: active }) =>
    active &&
    css`
      fill: ${Tokens.ColorUIDanger};
    `}
`

export const NeutralEmoji = styled(BaseEmoji)`
  ${({ $active: active }) =>
    active &&
    css`
      fill: ${Tokens.ColorTextTertiary};
    `}
`
