import styled, { css } from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const ComponentContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 8px;
`

export const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${Tokens.ColorTextPrimary};
`

export interface StyledProps {
  $variant?: boolean
}

export const ComponentWrapper = styled.div<StyledProps>`
  position: relative;

  width: 100%;
  height: ${Tokens.HeightInput};
  border-radius: ${Tokens.RadiusInput};
  padding: 0 16px;
  flex-wrap: wrap;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  font-weight: 400;

  color: ${Tokens.ColorTextPrimary};
  background-color: ${Tokens.ColorBackground};

  cursor: pointer;

  &:hover,
  &:focus-within {
    background-color: ${Tokens.ColorHover};
  }

  & > img {
    width: 1rem;
    height: 1rem;
  }

  ${({ $variant: variant }) =>
    !!variant &&
    css`
      background-color: transparent;
      border: 1px solid ${Tokens.ColorLight};
    `}
`

export const Input = styled.input`
  all: unset;

  flex: 1;
  height: 100%;
  width: 70px;

  &::placeholder {
    color: ${Tokens.ColorTextTertiary};
    opacity: 1; /* Firefox */
  }
`

export const SelectedItem = styled.span`
  flex: 1;
  color: ${Tokens.ColorTextPrimary};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 100%;
`

export const Selector = styled.ul<{ $show?: boolean }>`
  all: unset;

  position: absolute;
  top: ${Tokens.HeightInput};
  left: 0;

  border: ${Tokens.ColorWhite} solid 0.3rem;
  border-radius: 1rem;
  width: 100%;
  height: fit-content;
  padding: 0.5rem;

  display: none;

  font-size: 14px;
  font-weight: 400;
  background-color: ${Tokens.ColorBackground};

  cursor: pointer;

  z-index: 10;
  transition: display ease-in-out 0.25s;

  max-height: calc(${Tokens.HeightInput} * 10);
  overflow-y: auto;

  ${({ $show: show }) =>
    show &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      gap: 0.2rem;
    `}
`

export const SelectorOptions = styled.li`
  all: unset;

  color: ${Tokens.ColorTextPrimary};
  text-align: left;
  padding: 0.5rem;
  border-radius: 0.5rem;

  cursor: pointer;

  &:hover {
    color: ${Tokens.ColorWhite};
    background-color: ${Tokens.ColorUIPrimary};
  }
`

export interface ComboBoxIconContainerProps {
  color?: string
}
export const ComboBoxIconContainer = styled.div<ComboBoxIconContainerProps>`
  & > span {
    padding: unset;
    margin: unset;
  }

  ${({ color }) =>
    color &&
    css`
      & > span {
        color: ${color};
      }
    `}
`
