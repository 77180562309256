export type CreateMakerRequest = {
  companyID: string
  periodicity: string[]
  dataSourceID: number[]
  tierType: string
  gender: string
}

export enum MakerStatus {
  OK = 'ok',
  PROCESSING = 'progress',
  ERROR = 'failed',
}

export type MakerResponse = {
  collectionID: string
  tier: string
  monitoringType: string
  dataSourceType: string
}
