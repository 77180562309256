import { Tokens } from '@/assets/tokens'
import styled from 'styled-components'

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: ${Tokens.RootWithPaddingWidth};
  margin: 0 auto;
  margin-top: 0;
  padding-bottom: 50px;
`

export const DashboardLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: scratch;
  width: 100%;
  gap: 10px;
  max-width: 1036px;
  margin: 0 auto;
  flex-basis: 200px;
`
