import { keyframes } from 'styled-components'

export const blinkingAnimation = keyframes`
0% {
  opacity: 1;
}
25% {
  opacity: 0.5;
}
50% {
  opacity: 0;
}
75% {
  opacity: 0.5;
}
100% {
  opacity: 1;
}
`
