import { memo } from 'react'

import { Magic, Radar } from '@icon-park/react'

import { CreatePublisherRequest, InvokeWizard } from '@shared/models'

import { Alert, Api } from '@/modules'

import { useInput, useRouting } from '@/hooks'

import { Button, Flex, Form, Input } from '@/components'
import { ManualCard, Modal } from '@/components/derived'

import { useCompanyContext } from '../CompanyDetails.context'
import { PublishersTable } from './Publishers.table'
import { getDescription } from '../utils'
import { Str } from '@/utils/strings'
import { Nbr } from '@/utils/numbers'

export const Publishers = memo(Component)

function Component(): JSX.Element | null {
  const { companyID, changeQueryParam, currentPage } = useRouting()
  const { company, reloadCompany, isLoadingPublishers, reloadPublishers } = useCompanyContext()

  const { sendAlert } = Alert.Hook()

  const { value: name, binder: nameBinder, reset: resetName } = useInput()
  const { value: weight, binder: weightBinder, reset: resetWeight } = useInput()
  const { value: minFollowers, binder: minFollowersBinder, reset: resetMinFollowers } = useInput()
  const { value: maxFollowers, binder: maxFollowersBinder, reset: resetMaxFollowers } = useInput()

  const { isRunning: isCreatingPublisher, run: createPublisher } = Api.Command<void>(
    Api.Methods.POST,
    `companies/:companyID/publishers`,
  )
  const { isRunning: isInvokingWizard, run: invokeWizard } = Api.Command<void>(
    Api.Methods.POST,
    `companies/:companyID/publishers/wizard`,
  )

  function sendRequiredFieldError(field: string): void {
    sendAlert({ type: 'error', title: 'Campo obrigatório', message: `Campo '${field}' é obrigatório` })
  }

  function onSubmit(): void {
    const nameStr = Str.from(name)
    if (nameStr.isBlank) {
      return sendRequiredFieldError('Nome')
    }

    const weightNbr = Nbr.from(weight)
    if (weightNbr.isBlank) {
      return sendRequiredFieldError('Peso')
    }

    const input = {
      name: nameStr.val,
      weight: weightNbr.val,
      minFollowers: Nbr.from(minFollowers).value,
      maxFollowers: Nbr.from(maxFollowers).value,
    } as CreatePublisherRequest
    createPublisher<CreatePublisherRequest>({
      input,
      binds: [['companyID', companyID!]],
      onSuccess,
    })
  }

  function onSuccess() {
    sendAlert({
      type: 'success',
      title: 'Sucesso',
      message: 'Publicador cadastrado com sucesso!',
    })

    resetName()
    resetWeight()
    resetMinFollowers()
    resetMaxFollowers()

    reloadPublishers(currentPage)
  }

  function handleWizardClick(): void {
    const input = {
      companyID,
    } as InvokeWizard
    invokeWizard<InvokeWizard>({
      input,
      binds: [['companyID', companyID!]],
      onSuccess,
    })
  }

  if (!companyID || !company) return null

  return (
    <>
      <ManualCard
        icon={Radar}
        healthCheck={company.healthCheck?.isPublisherChecked}
        title="Tipos de Publicadores"
        description={getDescription(company.counts?.publishers)}
        linkName="Editar"
        linkAction={() =>
          changeQueryParam({
            param: 'modal',
            value: 'publicadores',
            replaceAll: true,
          })
        }
      />
      <Modal
        controlKey="publicadores"
        backToName={company.name}
        title="Tipos de Publicadores"
        subtitle={
          <span>
            Preencha todos os campos <strong>obrigatórios (*)</strong> para concluir o cadastro do publicador.
          </span>
        }
        icon={Radar}
        beforeClose={reloadCompany}>
        {/* TODO: Forçar UpperCase e remover acento em todos os campos string */}
        <Form onSubmit={onSubmit} controlParam={isCreatingPublisher}>
          <Flex $type="column" $align="stretch" $gap={24} $fit>
            <Input label="Nome" placeholder="Nome" {...nameBinder} required />
            <Flex $type="row" $justify="space-between" $align="center" $gap={24} $fit>
              <Input label="Peso" type="number" min={1} max={5} placeholder="Peso" {...weightBinder} required />
              <Input
                label="Min. #seguidores"
                type="number"
                maxLength={5}
                min={0}
                placeholder="Min. #seguidores"
                {...minFollowersBinder}
              />
              <Input
                label="Max. #seguidores"
                type="number"
                maxLength={5}
                min={0}
                placeholder="Max. #seguidores"
                {...maxFollowersBinder}
              />
            </Flex>
            <Flex $type="row" $justify="flex-end" $align="stretch" $gap={20} $fit>
              {!isLoadingPublishers && (
                <Button.Root $variant="outlined" onClick={handleWizardClick} $loading={isInvokingWizard}>
                  <Button.Icon value={Magic} />
                  <Button.Text
                    value={!company?.counts?.channels ? 'Preencher Automaticamente' : 'Atualizar Automaticamente'}
                  />
                </Button.Root>
              )}

              <Button.Root type="submit" $variant="primary" $loading={isCreatingPublisher}>
                <Button.Text value="Adicionar" />
              </Button.Root>
            </Flex>
          </Flex>
        </Form>
        <PublishersTable />
      </Modal>
    </>
  )
}
