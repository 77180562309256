import { memo, useContext } from 'react'

import { Tokens } from '@/assets/tokens'

import { Button, Divider, Flex } from '@/components'
import { ColumnType, Table } from '@/components/derived'

import { CreateBrandType, CreateCompanyContext } from '../CreateCompany.context'

export const BrandsTable = memo(Component)

function Component() {
  const { deleteBrand, brands } = useContext(CreateCompanyContext)

  if (!brands.length) return null

  const columns: ColumnType<CreateBrandType>[] = [
    {
      key: 'logo',
      title: 'Logo',
      width: 140,
      render: (_, brand) => <img alt={brand.systemLogo.mime} src={brand.systemLogo.content} />,
    },
    {
      key: 'name',
      title: 'Nome',
    },
    {
      key: 'options',
      width: 137,
      render: (_, brand) => (
        <Flex $type="row" $justify="flex-start" $align="stretch" $gap={24}>
          <Button.Root $variant="link" style={{ color: Tokens.ColorUIDanger }} onClick={() => deleteBrand(brand.id)}>
            Excluir
          </Button.Root>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Marcas registradas" />
        <Divider.Subtitle value={`${brands.length} Entrada(s)`} />
      </Divider.Root>
      <Table data={brands} columns={columns} />
    </>
  )
}
