import { DeleteMessageCommand, ReceiveMessageCommand, SQSClient } from '@aws-sdk/client-sqs'

import { useCallback, useMemo, useState } from 'react'

import { isDebugging } from '@/utils'

export type SQSConfigType = {
  region: string
  accessKeyId: string
  secretAccessKey: string
  queueUrl: string
  maxNumberOfMessages?: number
  waitTimeSeconds?: number
}

export type SQSMessageType<T> = {
  messageId?: string
  receiptHandle?: string
  body?: T
}

export type MessageArray<T> = SQSMessageType<T>[] | []

export type UseSQSType<T> = {
  messages: MessageArray<T>
  fetchMessages: () => Promise<void>
  deleteMessage: (message: SQSMessageType<T>) => Promise<void>
  loading: boolean
  error: Error | undefined
}

export const useSQS = <T>(config: SQSConfigType): UseSQSType<T> => {
  const sqs = useMemo(
    () =>
      new SQSClient({
        region: config.region,
        credentials: {
          accessKeyId: config.accessKeyId,
          secretAccessKey: config.secretAccessKey,
        },
      }),
    [config],
  )
  const [messages, setMessages] = useState<MessageArray<T>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  const fetchMessages = useCallback(async () => {
    setError(undefined)
    setLoading(true)
    try {
      const receiveMessageCommand = new ReceiveMessageCommand({
        QueueUrl: config.queueUrl,
        MaxNumberOfMessages: config.maxNumberOfMessages || 10,
        WaitTimeSeconds: config.waitTimeSeconds || 5,
      })

      const data = await sqs.send(receiveMessageCommand)

      if (!data?.Messages || !data?.Messages?.length) {
        if (isDebugging()) {
          console.log('Nenhuma mensagem recebida')
        }

        setMessages([])
      } else {
        if (isDebugging()) {
          console.log('Mensagens recebidas', data.Messages)
        }

        const sqsMessages = data.Messages.map((message) => {
          return {
            messageId: message?.MessageId,
            receiptHandle: message?.ReceiptHandle,
            body: message?.Body ? JSON.parse(message.Body) : undefined,
          } as SQSMessageType<T>
        })

        setMessages(sqsMessages)
      }
    } catch (err) {
      console.error('Erro ao receber mensagens:', err)
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [config, sqs])

  const deleteMessage = useCallback(
    async (message: SQSMessageType<T>) => {
      if (!message || !message.receiptHandle) return

      try {
        const deleteCommand = new DeleteMessageCommand({
          QueueUrl: config.queueUrl,
          ReceiptHandle: message.receiptHandle,
        })

        const data = await sqs.send(deleteCommand)

        if (isDebugging()) {
          console.log('Mensagem deletada com sucesso:', data)
        }
      } catch (err) {
        console.error('Erro ao deletar mensagem:', err)
        setError(err as Error)
      }
    },
    [config, sqs],
  )

  return { messages, fetchMessages, deleteMessage, loading, error }
}
