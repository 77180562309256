import axios, { AxiosInstance } from 'axios'

import { Env } from '@/configs/env'

export type BaseAPIOutput = {
  instance: AxiosInstance
  canceler: AbortController
}

export function useBaseAPI(): BaseAPIOutput {
  const baseURL = process.env[Env.REACT_APP_API_URL] ?? 'http://localhost:8080'
  const canceler = new AbortController()
  const instance = axios.create({ baseURL, withCredentials: true, signal: canceler.signal })
  return {
    instance,
    canceler,
  }
}
