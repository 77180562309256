import { Tokens } from '@/assets/tokens'
import styled from 'styled-components'

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  border-radius: ${Tokens.RadiusInput};
  max-width: 100%;
`
