import { Button, Flex } from '@/components'
import { useRouting } from '@/hooks/useRouting'
import { ElementType, ReactNode, memo } from 'react'
import { createPortal } from 'react-dom'
import { Content, ContentContainer, ParentTitle } from './Modal.styles'

const $modal = document.getElementById('modal') as HTMLDivElement

export const Modal = memo(Component)

interface ModalProps {
  controlKey: string
  backToName?: string
  title: string
  subtitle?: JSX.Element | string
  icon: ElementType
  children: ReactNode
  beforeClose?: () => void
}

function Component({ controlKey, backToName, title, subtitle, icon: Icon, children, beforeClose }: ModalProps) {
  const { queryParams, changeQueryParam } = useRouting()
  const modalName = queryParams['modal']

  if (!modalName) return null
  if (modalName !== controlKey) return null

  const back = () => {
    changeQueryParam({
      param: 'pagina',
    })
    changeQueryParam({
      param: 'modal',
    })
    if (beforeClose) beforeClose()
  }

  function renderSubtitle(): JSX.Element | null {
    if (!subtitle) return null
    if (typeof subtitle === 'string') {
      return <span>{subtitle}</span>
    }
    return subtitle
  }

  return createPortal(
    <ContentContainer>
      <Content>
        <Flex $type="row" $justify="space-between" $align="center" $gap={12}>
          <Flex $type="row" $justify="flex-start" $align="center" $gap={12}>
            <Icon size={45} />
            <Flex $type="column" $justify="center" $align="flex-start">
              <Flex $type="row" $justify="flex-start" $align="flex-end" $gap={6}>
                <h1>{title}</h1>
                {backToName && <ParentTitle onClick={back}>/{backToName}</ParentTitle>}
              </Flex>
              {renderSubtitle()}
            </Flex>
          </Flex>
          <Button.Root onClick={back}>Voltar</Button.Root>
        </Flex>
        {children}
      </Content>
    </ContentContainer>,
    $modal,
  )
}
