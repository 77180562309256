import { css } from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const variants = {
  primary: css`
    background-color: ${Tokens.ColorUIPrimary};

    &:hover {
      background-color: ${Tokens.ColorUIPrimaryDim};
    }

    & > span {
      font-weight: 600;
      color: ${Tokens.ColorWhite};
    }
  `,

  secondary: css`
    background-color: ${Tokens.ColorBackground};

    &:hover {
      background-color: ${Tokens.ColorBorder};
    }
  `,

  danger: css`
    background-color: ${Tokens.ColorUIDanger};

    & > span {
      font-weight: 600;
      color: ${Tokens.ColorWhite};
    }

    &:hover {
      background-color: ${Tokens.ColorUIDangerDim};
    }
  `,

  outlined: css`
    background-color: transparent;
    border: 1px solid ${Tokens.ColorBorder};

    &:hover {
      text-decoration: underline;
      text-decoration-color: inherit;
      background-color: ${Tokens.ColorHover};
    }
  `,

  link: css`
    padding: unset;
    border: unset;
    font-size: 16;
    font-weight: 600;

    background-color: transparent;

    &:hover {
      background-color: transparent;
      text-decoration: underline;
      text-decoration-color: inherit;
    }
  `,

  warn: css`
    background-color: ${Tokens.ColorUIWarn};

    & > span {
      font-weight: 600;
      color: ${Tokens.ColorWhite};
    }

    pointer-events: none;
  `,
}
