import { CSSProperties, ReactNode } from 'react'
import { FlexContainer, FlexContainerProps } from './Flex.styles'

interface FlexProps extends FlexContainerProps {
  children: ReactNode
  onClick?: () => void
  style?: CSSProperties
}

export function Flex({
  $type: type,
  $justify: justify,
  $align: align,
  $gap: gap,
  $fit: fit = false,
  onClick,
  children,
  ...rest
}: FlexProps) {
  return (
    <FlexContainer $type={type} $justify={justify} $align={align} $gap={gap} $fit={fit} onClick={onClick} {...rest}>
      {children}
    </FlexContainer>
  )
}
