import { MakerStatus } from '@shared/models'

import { SelectionOption } from '@/components'

export const tiers: SelectionOption[] = [
  { text: 'Tier 1', value: 'tier-1' },
  { text: 'Tier 2', value: 'tier-2' },
  { text: 'Tier 3', value: 'tier-3' },
  { text: 'Tier 4', value: 'tier-4' },
  { text: 'Tier S', value: 'tier-s' },
]

export const genderOptions: SelectionOption[] = [
  { text: 'Masculino', value: 'M' },
  { text: 'Feminino', value: 'F' },
]

export const periodicityOptions: SelectionOption[] = [
  { text: 'Mensal', value: 'monthly' },
  { text: 'Diário', value: 'daily' },
]

export function getCurrentStatus(
  isRunningMaker: boolean,
  isLoadingMakerStatus: boolean,
  fetchedMakerStatus?: MakerStatus,
): MakerStatus {
  if (isRunningMaker) return MakerStatus.PROCESSING
  if (isLoadingMakerStatus) return MakerStatus.PROCESSING
  if (!fetchedMakerStatus) return MakerStatus.PROCESSING
  return fetchedMakerStatus
}
