import { styled } from 'styled-components'

import { Tokens } from '@/assets/tokens'

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 21px 32px;
  width: 253px;
  height: 116px;
  border-radius: 20px;
  background-color: ${Tokens.ColorWhite};
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`

export const CompanyName = styled.h4`
  font-size: 1rem;
  line-height: 1.2em;
  max-height: 2.4em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const CardImage = styled.img`
  height: 32px;
`

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${Tokens.ColorBackground};
  overflow-x: auto;
  padding-bottom: 2rem;
`

export const Header = styled.nav`
  width: 100%;
  height: ${Tokens.HeaderHeight};
  padding: 17px 48px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${Tokens.ColorWhite};
`

export const HeaderLeft = styled.div`
  width: 500px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 40px;

  & > :not(img) {
    flex: 1;
  }
`

export const Logo = styled.img`
  width: 68px;
`

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 40px;
`

export const ContentContainer = styled.section`
  width: 1060px;
  height: fit-content;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 40px;
  row-gap: 40px;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px;
`
