import axios, { AxiosError, AxiosResponse } from 'axios'

// Definindo e exportando a função async
export async function getFromApi(url: any): Promise<any> {
  try {
    const response: AxiosResponse<any, any> = await axios(url)
    return response.data
  } catch (error) {
    if (error instanceof AxiosError) {
      console.error('Erro ao realizar a requisição:', error.message)
    }
    throw error // Lança o erro para ser tratado por quem chama a função
  }
}
