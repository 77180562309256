import { Api } from '@/modules/api'

import LoadingImg from '@/assets/images/load.gif'

import { Flex } from '@/components'
import { SkeletonLoader } from '@/components/derived'
import { Layout } from '@/components/layout'

import { Brands } from './brands/Brands.component'
import { Channels } from './channels/Channels.component'
import { CompanyClippers } from './company-clippers/CompanyClippers.component'
import { Maker } from './maker/Maker.component'
import { Publishers } from './publishers/Publishers.component'
import { Spokespersons } from './spokespeople/Spokespeople.component'
import { ThemeGroups } from './theme-groups/ThemeGroups.component'
import { Themes } from './themes/Themes.component'

import { CompanyDetailsContextProvider, useCompanyContext } from './CompanyDetails.context'
import {
  CompetitorFlag,
  DetailsContainer,
  GovernmentalFlag,
  Keyword,
  KeywordsContainer,
  LogoImage,
} from './CompanyDetails.styles'
import { CompanyCompetitors } from './competitors/CompanyCompetitors.component'
import { useRouting } from '@/hooks'
import { Routes } from '@/configs'
import { useParams } from 'react-router-dom'
import { CompanyResponse } from '@shared/models'
import { useEffect } from 'react'

export function CompanyDetails() {
  return (
    <CompanyDetailsContextProvider>
      <Component />
    </CompanyDetailsContextProvider>
  )
}

function CompanyLogo({ id }: { id?: string }): JSX.Element | null {
  const [, , logo] = Api.Query<string>(`files/${id}`)
  return <LogoImage alt="logo" src={logo ?? LoadingImg} />
}

function Component(): JSX.Element | null {
  const { companyID, concurrentID } = useParams<{ companyID: string; concurrentID: string }>()
  const { isLoadingCompany, company } = useCompanyContext()
  const [loadConcurrent, isLoadingConcurrent, concurrent] = Api.LazyQuery<CompanyResponse>(
    `companies/${companyID}/${concurrentID}`,
  )
  const { navigate } = useRouting()

  const entity = concurrentID && concurrent ? concurrent : company
  const isLoading = concurrentID ? isLoadingConcurrent : isLoadingCompany

  function handleNavigationToMotherCompany(): void {
    navigate(Routes.Home.path)
    // Workaround, once we're using hash navigation
    setTimeout(() => {
      navigate(Routes.CompanyDetails.path, company!.motherID!)
    }, 0)
  }

  useEffect(() => {
    if (concurrentID) {
      loadConcurrent()
    }
  }, [concurrentID])

  return (
    <>
      <Layout.Root>
        <Layout.Padder>
          {isLoading ?
            <SkeletonLoader amount={10} />
          : entity ?
            <Flex $type="column" $justify="flex-start" $align="stretch" $gap={16}>
              <DetailsContainer>
                <Flex $type="column" $justify="flex-start" $align="flex-start" $gap={24} $fit>
                  <h1>{entity.name}</h1>
                  <KeywordsContainer>
                    {entity.keywords
                      ?.split(',')
                      .slice(0, 3)
                      .map((keyword) => <Keyword key={keyword}>#{keyword.trim()}</Keyword>)}
                  </KeywordsContainer>
                  <Flex $type="row" $justify="flex-start" $align="center" $gap={16} $fit>
                    {entity.isGovernmental && <GovernmentalFlag />}
                    {entity.motherID && <CompetitorFlag onClick={handleNavigationToMotherCompany} />}
                  </Flex>
                </Flex>
                <CompanyLogo id={entity.systemLogoID} />
              </DetailsContainer>

              <Brands />
              <ThemeGroups />
              <Themes />
              <Channels />
              <Spokespersons />
              <Publishers />
              {!entity.motherID && <CompanyClippers />}
              {!entity.motherID && <Maker />}
              {entity.hasCompetitors && <CompanyCompetitors />}
            </Flex>
          : null}
        </Layout.Padder>
      </Layout.Root>
    </>
  )
}
