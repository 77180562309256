import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { useCallback, useState } from 'react'

import { Alert, ApiFuncParam, ApiRunFunc } from '@/modules'

import { Routes } from '@/configs'

import { useRouting } from '@/hooks'

import { useBaseAPI } from './baseApi.hook'

export type LazyOutput<T> = [ApiRunFunc, boolean, T | undefined, Error | undefined, () => void]

export function useLazyQuery<T>(url: string): LazyOutput<T> {
  const { instance, canceler } = useBaseAPI()
  const { navigate } = useRouting()

  const [isPending, setPending] = useState<boolean>(false)
  const [data, setData] = useState<T | undefined>(undefined)
  const [error, setError] = useState<Error | undefined>(undefined)

  const { sendAlert } = Alert.Hook()

  const run = useCallback((params?: ApiFuncParam) => {
    const config: AxiosRequestConfig = {}

    if (params) {
      config.params = params
    }

    setPending(true)

    instance
      .get(url, config)
      .then(({ data }: AxiosResponse<T>) => {
        setData(data)
        setError(undefined)
      })
      .catch((error: Error) => {
        setError(error)
        setData(undefined)
        if (axios.isAxiosError(error)) {
          const response = error.response!
          if (response.status === 400) {
            return sendAlert({
              type: 'error',
              title: 'Erro de requisição',
              message: 'A requisição enviada é inválida, verifique-a e tente novamente!',
            })
          }
          if (response.status === 401) {
            sendAlert({
              type: 'error',
              title: 'Erro de autenticação',
              message: 'Vocês não está autenticado, efetue o login e tente novamente!',
            })
            return navigate(Routes.Login.path)
          }
          if (response.status === 403) {
            sendAlert({
              type: 'error',
              title: 'Erro de autorização',
              message: 'Você não possui permissões para acessar esse recurso!',
            })
            return navigate(Routes.Home.path)
          }
          if (response.status === 404) {
            sendAlert({
              type: 'error',
              title: 'Recurso não encontrado',
              message: 'Ocorreu um erro ao buscar o recurso solicitado, verifique a requisição e tente novamente',
            })
            return navigate(Routes.Home.path)
          }
          sendAlert({
            type: 'error',
            title: 'Erro inesperado',
            message:
              'Ocorreu um erro inesperado ao efetuar a requisição, tente novamente mais tarde ou contate o suporte!',
          })
        }
      })
      .finally(() => {
        setPending(false)
      })
  }, [])

  return [run, isPending, data, error, canceler.abort]
}
