import { css, styled } from 'styled-components'

export interface InputProps {
  size?: number
}

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  width: fit-content;
  height: 44px;
  padding: 13px 16px;
  border-radius: 12px;
  background-color: #f3f4f8;

  &:focus-within {
    background-color: #eef0f5;
  }

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #01a779;
  }

  & > span > svg {
    width: 1rem;
    height: 1rem;
  }
`

export const InputIn = styled.input`
  all: unset;
  min-width: 100px;
  width: auto;
  height: 100%;
  font-size: 14px;
  font-weight: 400;

  &:hover {
    border: unset;
  }

  &::placeholder {
    color: #292c3e;
    opacity: 1; /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #292c3e;
  }

  ${({ size }) =>
    size &&
    css`
      width: ${size}px;
    `}
`
