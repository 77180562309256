import { truncateString } from '@/utils/strings'
import { Container, Content, ContainerAlignment, TruncatedContent, ContentSize } from './Tooltip.styles'

export type TooltipProps = ContainerAlignment &
  ContentSize & {
    value: string
    length?: number
  }

export function Tooltip({ value, length, $size: size, $align: align = 'center' }: TooltipProps): JSX.Element {
  const truncatedString = truncateString(value, length)

  return (
    <Container $align={align}>
      <TruncatedContent>{truncatedString}</TruncatedContent>
      {truncatedString.length !== value.length && <Content $size={size}>{value}</Content>}
    </Container>
  )
}
