import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { CompanyResponse, MentionResponse, Paged, PolarityValue, SearchResponse } from '@shared/models'

import { SelectionBinder, Selector } from '@/components'
import { Routes } from '@/configs'
import { InputBinder, useInput, useRouting, useTimeInput } from '@/hooks'
import { Alert, Api, ApiRunFunc } from '@/modules'
import { Str } from '@/utils/strings'
import { getMonthDays } from '@/utils/date'

export type DefaultFilters = {
  startDate: string
  endDate: string
  brand: SearchResponse
  themeGroup: SearchResponse
}

export type Image = {
  id: string
  mime: string
  content: string
}

type CurrMention = {
  idx: number
  val: MentionResponse
}

interface MentionsContextOutput {
  defaultFilters?: DefaultFilters
  currentMention?: CurrMention
  selectMention: (mention?: CurrMention) => void

  applyFilters: () => void
  resetFilters: () => void

  startDateBinder: InputBinder
  endDateBinder: InputBinder
  startTimeBinder: InputBinder
  endTimeBinder: InputBinder
  brandBinder: SelectionBinder
  polarityBinder: SelectionBinder
  channelBinder: SelectionBinder
  roleBinder: SelectionBinder
  themeGroupBinder: SelectionBinder
  themeBinder: SelectionBinder
  publisherBinder: SelectionBinder
  sourceBinder: SelectionBinder
  contentBinder: InputBinder

  isSearchingBrands: boolean
  isSearchingPolarities: boolean
  isSearchingChannels: boolean
  isSearchingRoles: boolean
  isSearchingThemeGroups: boolean
  isSearchingThemes: boolean
  isSearchingPublishers: boolean
  isLoadingMentions: boolean

  brands?: SearchResponse[]
  polarities?: SearchResponse[]
  channels?: SearchResponse[]
  roles?: SearchResponse[]
  themeGroups?: SearchResponse[]
  themes?: SearchResponse[]
  publishers?: SearchResponse[]
  mentions?: Paged<MentionResponse>
  updatedMentions?: Paged<MentionResponse>
  images: Map<string, string>

  searchBrands: ApiRunFunc
  searchChannels: ApiRunFunc
  searchRoles: ApiRunFunc
  searchThemeGroups: ApiRunFunc
  searchThemes: ApiRunFunc
  searchPublishers: ApiRunFunc
  loadMentions: ApiRunFunc
}

const MentionsContext = createContext<MentionsContextOutput>({} as MentionsContextOutput)

export function MentionsContextProvider({ children }: { children: ReactNode }): JSX.Element {
  const { companyID, queryParams, navigate } = useRouting()

  const [defaultFilters, setDefaultFilters] = useState<DefaultFilters | undefined>()
  const [currentMention, setCurrentMention] = useState<CurrMention | undefined>()
  const [images, setImages] = useState<Map<string, string>>(new Map())

  const { sendAlert } = Alert.Hook()

  const currentPage: number = Number(queryParams.pagina ?? '1')
  const [, , today] = getMonthDays(new Date())

  const polarities = Object.values(PolarityValue)
    .map(
      (value: PolarityValue): SearchResponse => ({
        text: value,
        value: value as string,
      }),
    )
    .sort((a, b) => a.text.localeCompare(b.text))

  const { binder: startDateBinder, value: startDate, reset: changeStartDate } = useInput()
  const { binder: endDateBinder, value: endDate, reset: changeEndDate } = useInput()
  const { binder: startTimeBinder, value: startTime, reset: changeStartTime } = useTimeInput()
  const { binder: endTimeBinder, value: endTime, reset: changeEndTime } = useTimeInput()

  const { binder: brandBinder, selectedOption: brand, reset: changeBrand } = Selector.Binder()
  const { binder: polarityBinder, selectedOption: polarity, reset: changePolarity } = Selector.Binder()
  const { binder: channelBinder, selectedOption: channel, reset: changeChannel } = Selector.Binder()
  const { binder: roleBinder, selectedOption: role, reset: changeRole } = Selector.Binder()
  const { binder: themeGroupBinder, selectedOption: themeGroup, reset: changeThemeGroup } = Selector.Binder()
  const { binder: themeBinder, selectedOption: theme, reset: changeTheme } = Selector.Binder()
  const { binder: publisherBinder, selectedOption: publisher, reset: changePublisher } = Selector.Binder()
  const { binder: sourceBinder, selectedOption: source, reset: changeSource } = Selector.Binder()
  const { binder: contentBinder, value: content, reset: changeContent } = useInput()

  const [, , company] = Api.Query<CompanyResponse>(`companies/${companyID}`)
  const [searchBrands, isSearchingBrands, brands] = Api.LazyQuery<SearchResponse[]>(`brands/search?size=10000`)
  const [searchChannels, isSearchingChannels, channels] = Api.LazyQuery<SearchResponse[]>(`channels/search?size=10000`)
  const [searchRoles, isSearchingRoles, roles] = Api.LazyQuery<SearchResponse[]>(`common/roles?size=10000`)
  const [searchThemeGroups, isSearchingThemeGroups, themeGroups] =
    Api.LazyQuery<SearchResponse[]>(`theme-groups/search?size=10000`)
  const [searchThemes, isSearchingThemes, themes] = Api.LazyQuery<SearchResponse[]>(`themes/search?size=10000`)
  const [searchPublishers, isSearchingPublishers, publishers] = Api.LazyQuery<SearchResponse[]>(
    `companies/${companyID}/publishers/search?size=10000`,
  )
  const [loadMentions, isLoadingMentions, mentions] = Api.LazyQuery<Paged<MentionResponse>>(
    `companies/${companyID}/mentions`,
  )
  const [updatedMentions, setUpdatedMentions] = useState<Paged<MentionResponse>>()
  const [reloadImages, , responseImages] = Api.LazyQuery<Image[]>(`files/batch`)

  useEffect(() => {
    if (!company) return
    if (!company?.healthCheck?.isHealthy) {
      sendAlert({
        type: 'error',
        title: 'Erro no manual',
        message: 'Empresa está com manual incompleto: Favor revisar o manual!',
      })
      navigate(Routes.CompanyDetails.path, companyID!)
    }
  }, [company])

  function resetFilters(): void {
    if (defaultFilters) {
      changeStartDate(defaultFilters.startDate)
      changeEndDate(defaultFilters.endDate)
      changeBrand(defaultFilters.brand)
      changeThemeGroup(defaultFilters.themeGroup)
    }
    changePolarity(undefined)
    changeChannel(undefined)
    changeRole(undefined)
    changeTheme(undefined)
    changePublisher(undefined)
    changeSource(undefined)
    changeContent(undefined)
    changeStartTime(undefined)
    changeEndTime(undefined)
  }

  function applyFilters(): void {
    const filters: NodeJS.Dict<string | number> = {
      startDate,
      endDate,
      brandInternalID: brand?.value,
      themeGroupInternalID: themeGroup?.value,
      page: currentPage,
      size: 10,
    }
    if (polarity) filters.polarity = polarity.value
    if (channel) filters.channelInternalID = channel.value
    if (role) filters.roleInternalID = role.value
    if (theme) filters.themeInternalID = theme.value
    if (publisher) filters.publisherInternalID = publisher.value
    if (source) filters.sourceInternalID = source.value
    if (content) filters.content = content
    if (startTime && endTime) {
      filters.startTime = startTime
      filters.endTime = endTime
    }
    loadMentions(filters)
    setUpdatedMentions(mentions)
  }

  useEffect((): void => {
    setUpdatedMentions(mentions)
  }, [])

  useEffect((): void => {
    if (!responseImages) return

    const newImages = new Map<string, string>()
    responseImages.forEach((img) => {
      if (img.id) {
        newImages.set(img.id, atob(img.content))
      }
    })
    setImages(newImages)
  }, [responseImages])

  useEffect((): void => {
    if (!mentions || !mentions.elements.length) return

    const ids = mentions?.elements?.map((el) => [el.brandLogo, el.channelLogoID]).flat()
    const filteredIds = ids.filter(Boolean)
    if (filteredIds.length > 0) {
      reloadImages({
        ids: filteredIds.join(','),
      })
    }
    setUpdatedMentions(mentions)
  }, [mentions])

  useEffect((): void => {
    if (!companyID) return

    searchBrands({
      company: companyID,
      orderBy: 'internalID:ASC',
    })
    searchChannels({
      company: companyID,
    })
    searchRoles()
    searchThemeGroups({
      company: companyID,
      orderBy: 'internalID:ASC',
    })
    searchPublishers({
      company: companyID,
    })
  }, [])

  useEffect(() => {
    if (defaultFilters) {
      applyFilters()
    }
  }, [currentPage])

  useEffect(() => {
    if (defaultFilters) {
      changeStartDate(defaultFilters.startDate)
      changeEndDate(defaultFilters.endDate)
      changeBrand(defaultFilters.brand)
      changeThemeGroup(defaultFilters.themeGroup)
      loadMentions({
        size: 10,
        page: currentPage,
        startDate: defaultFilters.startDate,
        endDate: defaultFilters.endDate,
        brandInternalID: defaultFilters.brand.value as number,
        themeGroupInternalID: defaultFilters.themeGroup.value as number,
      })
      setUpdatedMentions(mentions)
    }
  }, [defaultFilters])

  useEffect((): void => {
    if (!brands?.length) return
    if (!themeGroups?.length) return
    if (defaultFilters) return

    const brand: SearchResponse = {
      text: Str.from(brands[0].text).initCap,
      value: brands[0].value,
    }
    const themeGroup: SearchResponse = {
      text: Str.from(themeGroups[0].text).initCap,
      value: themeGroups[0].value,
    }

    setDefaultFilters({
      startDate: today,
      endDate: today,
      brand,
      themeGroup,
    })
  }, [brands, themeGroups])

  useEffect(() => {
    if (brand && themeGroup) {
      searchThemes({
        company: companyID,
        marca: brand.value,
        grupoTematico: themeGroup.value,
        fathersOnly: true,
        orderBy: 'value:ASC',
      })
    }
  }, [brand, themeGroup, searchThemes])

  return (
    <MentionsContext.Provider
      value={{
        defaultFilters,
        currentMention,
        selectMention: setCurrentMention,

        applyFilters,
        resetFilters,

        startDateBinder,
        endDateBinder,
        startTimeBinder,
        endTimeBinder,
        brandBinder,
        polarityBinder,
        channelBinder,
        roleBinder,
        themeGroupBinder,
        themeBinder,
        publisherBinder,
        sourceBinder,
        contentBinder,

        isSearchingBrands,
        isSearchingPolarities: false,
        isSearchingChannels,
        isSearchingRoles,
        isSearchingThemeGroups,
        isSearchingThemes,
        isSearchingPublishers,
        isLoadingMentions,
        brands,
        polarities,
        channels,
        roles,
        themeGroups,
        themes,
        publishers,
        mentions,
        updatedMentions,
        images,
        searchBrands,
        searchChannels,
        searchRoles,
        searchThemeGroups,
        searchThemes,
        searchPublishers,
        loadMentions,
      }}>
      {children}
    </MentionsContext.Provider>
  )
}

export function useMentionsContext(): MentionsContextOutput {
  const context = useContext(MentionsContext)
  if (!context) throw new Error('useMentionsContext deve ser chamado dentro do contexto correto')
  return context
}
