import { memo, useEffect } from 'react'

import { DeleteFive } from '@icon-park/react'

import { PublisherResponse } from '@shared/models'

import { Tokens } from '@/assets/tokens'

import { Alert, Api, Confirmation } from '@/modules'

import { Button, Divider, Flex } from '@/components'
import { ColumnType, Table } from '@/components/derived'

import { useRouting } from '@/hooks'
import { getFromToElements } from '@/utils/pagination'
import { useCompanyContext } from '../CompanyDetails.context'

export const PublishersTable = memo(Component)

function Component(): JSX.Element | null {
  const { currentPage, companyID } = useRouting()
  const { isLoadingPublishers, publishers, reloadPublishers } = useCompanyContext()

  const { sendAlert } = Alert.Hook()
  const { open: openConfirmation } = Confirmation.Hook({
    icon: DeleteFive,
    text: 'Esta ação não tem como ser desfeita. Todos os valores associados à esse publicador serão perdidos',
    confirmText: 'Sim! Apagar publicador',
  })

  const { isRunning: isDeleting, run: deletePublisher } = Api.Command<void>(
    Api.Methods.DELETE,
    'companies/:companyID/publishers/:publisherID',
  )

  useEffect(() => {
    reloadPublishers(currentPage)
  }, [currentPage])

  function onConfirmationAccepted(id: string) {
    deletePublisher<void>({
      binds: [
        ['companyID', companyID!],
        ['publisherID', id],
      ],
      onSuccess: () => {
        sendAlert({
          type: 'success',
          title: 'Exclusão do publicador',
          message: 'Publicador excluído com sucesso',
        })
        reloadPublishers(currentPage)
      },
    })
  }

  const columns: ColumnType<PublisherResponse>[] = [
    {
      key: 'name',
      title: 'Nome',
    },
    {
      key: 'weight',
      title: 'Peso',
      width: 80,
      render: (_, item) => item.weight ?? '--',
    },
    {
      key: 'options',
      width: 137,
      render: (_, item) => (
        <Flex $type="row" $justify="flex-start" $align="stretch" $gap={24}>
          <Button.Root
            $variant="link"
            style={{ color: Tokens.ColorUIDanger }}
            onClick={() => openConfirmation(() => onConfirmationAccepted(item.id))}
            $loading={isDeleting}>
            Excluir
          </Button.Root>
        </Flex>
      ),
    },
  ]

  return (
    <>
      <Divider.Root>
        <Divider.Title value="Publicadores registrados" />
        <Divider.Spacer />
        <Divider.Subtitle
          value={`${getFromToElements(currentPage, publishers?.elements.length ?? 0)}/${publishers?.total ?? 0} Entrada(s)`}
        />
      </Divider.Root>
      <Table
        isLoading={isLoadingPublishers}
        columns={columns}
        data={publishers?.elements}
        totalPages={publishers?.pages}
      />
    </>
  )
}
