import { DocumentFolder } from '@icon-park/react'

import { Button, Input } from '@/components'

import {
  ContentFilterContainer,
  ContentFilterResetButton,
  ContentFilterItemContainer,
  ContentFilterHeader,
} from './ContentFilter.styles'

import { useState } from 'react'

type ContentFilterProps = {
  title?: string
  placeholder?: string
  buttonTitle?: string
  initialContent?: string
  isLoading?: boolean
  filterOnEnter?: boolean
  minFilterLength?: number
  onApply?: (content?: string) => void
  onReset?: () => void
}

export function ContentFilter({
  title,
  placeholder,
  buttonTitle,
  initialContent,
  isLoading,
  filterOnEnter = true,
  minFilterLength,
  onApply,
  onReset,
}: ContentFilterProps) {
  const [content, setContent] = useState<string>(initialContent ?? '')

  function handleApply(value: string): void {
    if (isLoading || !onApply) return
    onApply(value)
  }

  function handleReset(): void {
    if (!content) return
    setContent('')
    if (isLoading || !onReset) return
    onReset()
  }

  function handleKeyDown(key: string): void {
    if (filterOnEnter && key === 'Enter') {
      handleApply(content)
    }
  }

  function handleChange(value: string): void {
    setContent(value)

    if (minFilterLength && value?.length >= minFilterLength) {
      handleApply(value)
    }
  }

  return (
    <ContentFilterContainer>
      <ContentFilterHeader>
        {title ?? 'Filtro'}
        <ContentFilterResetButton onClick={handleReset} disabled={isLoading}>
          Limpar Filtro
        </ContentFilterResetButton>
      </ContentFilterHeader>

      <ContentFilterItemContainer>
        <Input
          placeholder={placeholder ?? 'Conteúdo'}
          value={content}
          icon={DocumentFolder}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          disabled={isLoading}
          readOnly={isLoading}
        />
        <Button.Root $variant="primary" onClick={() => handleApply(content)} $loading={isLoading}>
          <Button.Text value={buttonTitle ?? 'Filtrar'} />
        </Button.Root>
      </ContentFilterItemContainer>
    </ContentFilterContainer>
  )
}
